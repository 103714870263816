import { Button } from 'antd';
import React from 'react';

/**
 * 링크 버튼: 링크(<a>) 모양을 한 버튼으로 주로 클릭시 다른 컴포넌트를 불러올 때 사용한다.
 * @param {string} children 링크 버튼의 이름
 * @param {*} res 모두 버튼의 props로 설정된다.
 * @returns ReactNode
 */
function LinkButton({ children, ...res }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button type="link" size="small" className="linkButton" {...res}>
      {children}
    </Button>
  );
}

export default LinkButton;

import { Table } from 'antd';
import React from 'react';
import Panel from './Panel';

function OwnDisk({ title, rowSelection }) {
  return (
    <Panel title={title}>
      <Table
        size="small"
        rowKey="key"
        pagination={false}
        dataSource={[
          {
            key: '1',
            name: 'LGDKRA.EA팀',
            type: '업무',
            department: 'LGDKRA.EA팀',
            leader: '류민재',
            volume: '100',
            WhenCreate: '2020-01-01',
          },
          {
            key: '2',
            name: 'LGDKRA.EA팀',
            type: '업무',
            department: 'LGDKRA.EA팀',
            leader: '박서연',
            volume: '200',
            WhenCreate: '2021-08-12',
          },
        ]}
        columns={[
          {
            title: 'Disk 이름',
            dataIndex: 'name',
          },
          {
            title: '용도',
            dataIndex: 'type',
          },
          {
            title: '조직',
            dataIndex: 'department',
          },
          {
            title: 'PM/리더',
            dataIndex: 'leader',
          },
          {
            title: '용량(GB)',
            dataIndex: 'volume',
            align: 'right',
          },
          {
            title: '생성 일시',
            dataIndex: 'WhenCreate',
          },
        ]}
        rowSelection={rowSelection}
      />
    </Panel>
  );
}

export default OwnDisk;

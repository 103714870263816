import { request } from './util';

export async function getDocuments(params, sorter = { createDate: 'descend' }, filter) {
  return request({
    scope: 'portal',
    name: 'notice/get-documents',
    data: {
      paging: { params, sorter, filter },
    },
  });
}

export async function getDocumentDetail(guid) {
  return request({
    scope: 'portal',
    name: 'notice/get-document-detail',
    data: {
      guid,
    },
  });
}

export async function getCategories(params) {
  return request({
    scope: 'portal',
    name: 'notice/get-categories',
    data: {
      ...params,
    },
  });
}

import {
  Button,
  Col,
  DatePicker,
  Form,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
} from 'antd';
import { Icon } from 'components';
import LocaleContext from 'locales';
import { forEach, uniq } from 'lodash';
import moment from 'moment';
import { getValidationMsgs } from 'pages/utils';
import React, { useContext, useEffect } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { getDocumentDetail, requestApproval, updateApproval } from 'services/websocket/session';
import useFlavorInfo from 'hooks/useFlavorInfo';
import {
  // MailCC,
  OwnDesktops,
  RequestInfo,
  TimePreset,
  UserSearchInput,
} from './components';

function DesktopCreate({ readOnly, form, id, onClose }) {
  const { locale } = useContext(LocaleContext);
  const [visibleOwnDesktops, setVisibleOwnDesktops] = React.useState(false);
  const [user, setUser] = React.useState('');
  const [userName, setUserName] = React.useState('');
  const type = 'approval.types.create-vd';

  const [{ flavorOptions }, getSelectedFlavor] = useFlavorInfo();

  function getErrorFields(items) {
    const errObjs = {};

    forEach(items, (errItem) => {
      const namePath = errItem.name;
      const nameString = namePath.join('/');
      if (!errObjs[nameString]) {
        errObjs[nameString] = [];
      }

      errObjs[nameString].push(errItem.reason);
    });

    return Object.entries(errObjs).map(([k, v]) => ({ name: k.split('/'), errors: uniq(v) }));
  }

  useEffect(() => {
    if (!id) {
      const applicant = localStorage.getItem('userId');
      form.setFieldsValue({
        applicant,
        type: 'approval.types.create-vd',
        contents: [
          {
            id: Date.now() + 0,
            disk: 80,
            locale: 'ko',
          },
        ],
      });

      return;
    }

    getDocumentDetail(id).then((res) => {
      const data = res?.data; // #178732#note-16
      let approveDate = data?.approveDate;

      if (approveDate !== null) {
        approveDate = moment(approveDate);
      }

      form.setFieldsValue({
        ...data,
        approveDate,
        applyDate: moment(data.applyDate).format('YYYY-MM-DD HH:mm:ss'),
      });
      setUserName(data?.userName);
      setUser(data?.user);
    });
  }, [id]);

  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 19 }}
      onFinish={async (values) => {
        const { contents, approveDate, ...fields } = values;
        const [content] = contents;
        const flavorInfo = getSelectedFlavor(content.flavorName);
        const payload = {
          ...fields,
          contents: [{ ...content, ownDesktop: '', ...flavorInfo }],
          approveDate: approveDate?.format('YYYY-MM-DD HH:mm:ss') || approveDate, // #193685
        };
        try {
          const result = id ? await updateApproval(id, payload) : await requestApproval(payload);
          if (result.status === 'error') {
            const { invalidParams } = result;

            const errors = getErrorFields(getValidationMsgs(invalidParams));
            form.setFields(errors);
            const generalErrors = errors.find((el) => el.name[0] === 'generalValidationError'); // generalValidationError는 무조건 하나만 받고 errors를 배열로 여러 개 받는다.
            if (generalErrors) {
              Modal.error({
                title: locale.resource['txt.failed-to-save'],
                content: generalErrors.errors.map((el) => <div>{el}</div>),
              });
            }
            return;
          }
          message.success(locale.resource['txt.succeeded-to-save']);
          form.resetFields();
          onClose();
        } catch (e) {
          message.error(e);
        }
      }}
    >
      <RequestInfo readOnly={readOnly} />
      <Form.Item label={locale.resource['txt.approval-type']} name="type" required>
        {locale.resource['txt.vd-creation']}
      </Form.Item>
      <Form.Item label={locale.resource['vd-log.sheet.col.user-id']} required>
        <Row gutter={[0, 4]}>
          <Col span={24}>
            <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Form.Item
                name="user"
                rules={[{ required: true, message: locale.resource['txt.required-field'] }]}
                noStyle
              >
                <UserSearchInput
                  readOnly1={readOnly}
                  onSuccess={async (uid) => {
                    // 처리
                    setUser(uid);
                  }}
                  userName={userName}
                />
              </Form.Item>
              {/* TODO: 선택한 사용자가 있을 때 아래 버튼 보이기 */}
              <Button
                type="text"
                className="ant-dropdown-link"
                onClick={() => {
                  setUser(form.getFieldValue('user'));
                  setVisibleOwnDesktops(!visibleOwnDesktops);
                }}
              >
                {locale.resource['txt.assign-vd']}
                <Icon name={<AiOutlineCaretDown className="anticon anticon-down" />} />
              </Button>
            </Space>
          </Col>
          {visibleOwnDesktops && (
            // TODO: show/hide 에니메이션 추가 https://motion.ant.design/components/animate#components-animate-demo-simple
            <Col span={24}>
              <OwnDesktops
                title={false}
                readOnly
                bodyStyle={{ padding: 0 }}
                user={user}
                apprType={type}
              />
            </Col>
          )}
        </Row>
      </Form.Item>
      <Form.Item
        label={locale.resource['txt.desktop-info']}
        name="contents"
        valuePropName="dataSource"
        required
      >
        <Table
          size="small"
          rowKey="id"
          pagination={false}
          columns={[
            {
              title: locale.resource['txt.flavor'],
              dataIndex: 'flavorName',
              width: 300,
              render: (text, record, index) => {
                const flavorInfo = getSelectedFlavor(text);
                const isFlavorUnavailable = text && !flavorInfo && !readOnly; // 플레이버 이름 O, 정보 X > 사용할 수 없는 플레이버로 판별
                return (
                  <Form.Item
                    name={['contents', index, 'flavorName']}
                    validateStatus={isFlavorUnavailable && 'error'}
                    help={isFlavorUnavailable ? locale.resource['txt.flavor-unavailable'] : null}
                    rules={[{ required: true, message: locale.resource['txt.required-field'] }]}
                    noStyle
                  >
                    {readOnly ? (
                      text
                    ) : (
                      <Select
                        placeholder={locale.resource['txt.select-option-required']}
                        options={flavorOptions}
                      />
                    )}
                  </Form.Item>
                );
              },
            },
            {
              title: locale.resource['txt.disk'],
              dataIndex: 'disk',
              width: 100,
              render: (text, record, index) =>
                readOnly ? (
                  `${text} ${locale.resource['txt.gb']}`
                ) : (
                  <Form.Item name={['contents', `${index}`, 'disk']} noStyle>
                    <InputNumber
                      addonAfter={locale.resource['txt.gb']}
                      min={0}
                      initialValues={text}
                      onChange={() => {
                        form.setFields([{ name: ['contents', `${index}`, 'disk'], errors: [] }]);
                      }}
                    />
                  </Form.Item>
                ),
            },
            {
              title: locale.resource['txt.language'],
              dataIndex: 'locale',
              render: (text) =>
                readOnly ? (
                  text
                ) : (
                  <Select defaultValue="ko">
                    <Select.Option value="ko">{locale.resource['txt.korean']}</Select.Option>
                  </Select>
                ),
            },
          ]}
        />
      </Form.Item>
      <Form.Item
        label={locale.resource['txt.execution-date']}
        name="approveDate"
        extra={locale.resource['txt.excution-date-msg']}
        rules={[
          () => ({
            validator(_, value) {
              if (value > moment() || !value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(locale.resource['txt.excution-date-val']));
            },
          }),
        ]}
        initialValue={null} // #193685
      >
        <DatePicker
          showTime={{
            defaultValue: moment('00:00', 'HH:mm'),
            format: 'HH:mm',
          }}
          inputReadOnly={1}
          disabled={readOnly}
          placeholder=""
          disabledDate={(date) => date && date < moment().startOf('day')}
          showNow={false}
          format="YYYY-MM-DD HH:mm"
          renderExtraFooter={() => (
            <TimePreset
              onSelect={(time) => {
                form.setFieldsValue({ approveDate: time });
              }}
            />
          )}
        />
      </Form.Item>
    </Form>
  );
}

export default DesktopCreate;

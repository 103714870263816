import {
  Button,
  Carousel,
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Dropdown,
  Form,
  Grid,
  Menu,
  Row,
  Skeleton,
  Space,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import { Icon } from 'components';
import { useDesktops } from 'hooks/useDesktops';
import LocaleContext from 'locales';
import moment from 'moment';
import { displaySize } from 'pages/utils';
import React, { useContext, useEffect, useRef } from 'react';
import { AiFillWindows, AiOutlineLeft, AiOutlineReload, AiOutlineRight } from 'react-icons/ai';
import { TiSpanner } from 'react-icons/ti';
import { mediaScreen } from 'utils';
import ConnHistoryTable from './ConnHistoryTable';
import DesktopMessage from './DesktopMessage';
import NoAvailableDesktops from './NoAvailableDesktops';
import RenderTags from './RenderTags';
import { ConnectBtn, Monitor } from './styles';

const { useBreakpoint } = Grid;

/**
 * 접속 가능 Desktops: (siteCustoms.defaultDesktopViewer가 carousel인 경우)
 * @param {string} redirectUrlToVd 브라우저의 window.location값으로 URL Redirection을 위한 url
 * @param {boolean} isAutoConnect DB 환경 설정의 config.application.vsclient.auto-connect 값
 * @returns ReactNode
 */
export default function ViewCarousel({ redirectUrlToVd, isAutoConnect }) {
  const { locale } = useContext(LocaleContext);
  const { state, dispatch, fetchDesktops, setCurrent, onConnect, onFailover, onAutoConnect } =
    useDesktops();
  const { desktops, current, visibleConnHistory } = state;
  const breakpoint = useBreakpoint();
  const screens = mediaScreen(breakpoint);

  // refs
  const [form] = Form.useForm();
  const carouselRef = useRef(null);

  // 초기 렌더링 및 새로고침 시 할당된 VD 목록을 가져온다.
  useEffect(() => fetchDesktops.runAsync(), []);

  // 할당받은 VD(그룹) 목록에서 current를 찾은 후 최초 표시할 슬라이드 넘버를 세팅한다.
  useEffect(() => {
    if (current) {
      // TODO 웹소켓으로 current가 계속 변하기 때문에 목록 데이터에서 current를 못찾는 버그가 발생할 수 있다.(현재 해결책을 찾지 못함)
      const idx = desktops.findIndex((el) => el.id + el.vdId === current.id + current.vdId);
      carouselRef.current.goTo(idx);
    }
    if (current && isAutoConnect) {
      onAutoConnect(moment().add(3, 'm'), form.getFieldValue('multi-monitors'), redirectUrlToVd);
    }
  }, [current]);

  const gotoNext = () => carouselRef.current.next();
  const gotoPrev = () => carouselRef.current.prev();

  // 슬라이드가 변경되었을 때 웹소켓 구독을 위해 current를 세팅한다. afterChange로 하면 무한 루프에 빠지기 때문에 주의
  const onBeforeChange = (currentSlide, nextSlide) => {
    setCurrent(desktops[nextSlide]);
  };

  if (desktops.length === 0) {
    return <NoAvailableDesktops locale={locale} fetchDesktops={fetchDesktops} />;
  }

  return !current ? (
    <Row gutter={[24, 0]} align="middle" style={{ flexWrap: 'nowrap' }}>
      <Col>
        <Skeleton.Button active size="large" block shape="circle" />
      </Col>
      <Col flex="800px">
        <Skeleton.Image active style={{ width: 800, height: 240 }} />
      </Col>
      <Col>
        <Skeleton.Button active size="large" block shape="circle" />
      </Col>
    </Row>
  ) : (
    <Spin spinning={fetchDesktops.loading}>
      <Row gutter={24} align="middle" style={{ flexWrap: 'nowrap' }}>
        <Col>
          <Button
            shape="circle"
            icon={<AiOutlineLeft className="anticon" />}
            onClick={gotoPrev}
            style={{ visibility: desktops.length > 1 ? 'visible' : 'hidden' }}
          />
        </Col>
        <Col style={{ width: 'calc(100% - 100px)' }}>
          <Carousel
            dots={false}
            ref={carouselRef}
            beforeChange={onBeforeChange}
            slidesToShow={1}
            slidesPerRow={1}
            swipeToSlide
          >
            {
              // map의 각 요소는 el이 맞으나 웹소켓을 받은 데이터를 실시간 업데이트하기 위해 current 데이터로 한다.
              desktops.map((el) => (
                <div className="carouselCard" key={el.id + el.vdId}>
                  <Row gutter={[32, 8]} align="middle" style={{ flexWrap: 'nowrap' }}>
                    <Col>
                      <Typography.Title level={3} style={{ fontWeight: 500 }}>
                        <Space align="center">
                          <AiFillWindows className="anticon" fontSize={32} color="#0078D6" />
                          <Typography.Text
                            ellipsis
                            style={{ display: 'inline-block', maxWidth: 142 }}
                          >
                            {current.adAccount || current.vdGroupName}
                          </Typography.Text>
                          <Tooltip title={locale.resource['txt.refresh']}>
                            <Button
                              type="text"
                              icon={<AiOutlineReload className="anticon" />}
                              onClick={() => fetchDesktops.runAsync()}
                            />
                          </Tooltip>
                        </Space>
                      </Typography.Title>
                      <Divider style={{ margin: '0px 0 12px' }} />
                      {screens.isDesktop && (
                        <div className="carouselCard__flavor">
                          <div>
                            <div className="carouselCard__label">{locale.resource['txt.cpu']}</div>
                            <Typography.Title level={4} style={{ fontWeight: 500 }}>
                              {current.cpus}
                              {locale.resource['txt.core']}
                            </Typography.Title>
                          </div>
                          <div>
                            <div className="carouselCard__label">
                              {locale.resource['txt.memory']}
                            </div>
                            <Typography.Title level={4} style={{ fontWeight: 500 }}>
                              {displaySize(current.ram).size}
                              {displaySize(current.ram).unit}
                            </Typography.Title>
                          </div>
                          <div>
                            <div className="carouselCard__label">{locale.resource['txt.disk']}</div>
                            <Typography.Title level={4} style={{ fontWeight: 500 }}>
                              {current.size}
                              {locale.resource['txt.gb']}
                            </Typography.Title>
                          </div>
                        </div>
                      )}
                      <Descriptions column={1}>
                        {!screens.isDesktop && (
                          <>
                            <Descriptions.Item label={locale.resource['txt.cpu']}>
                              {current.cpus}
                              {locale.resource['txt.core']}
                            </Descriptions.Item>
                            <Descriptions.Item label={locale.resource['txt.memory']}>
                              {displaySize(current.ram).size}
                              {displaySize(current.ram).unit}
                            </Descriptions.Item>
                            <Descriptions.Item label={locale.resource['txt.disk']}>
                              {current.size}
                              {locale.resource['txt.gb']}
                            </Descriptions.Item>
                          </>
                        )}
                        <Descriptions.Item label={locale.resource['txt.status']}>
                          <RenderTags desktop={current} locale={locale} />
                        </Descriptions.Item>
                        <Descriptions.Item label={locale.resource['txt.os']}>
                          {current.os}
                        </Descriptions.Item>
                        <Descriptions.Item label={locale.resource['txt.ip']}>
                          {current.ipAddress || locale.resource['txt.not-assigned-yet']}
                        </Descriptions.Item>
                        <Descriptions.Item label={locale.resource['txt.last-date']}>
                          <Tooltip title={locale.resource['txt.last-connection-history']}>
                            <Button
                              size="small"
                              type="link"
                              className="linkButton"
                              onClick={() =>
                                dispatch({ type: 'set-visibleConnHistory', payload: true })
                              }
                            >
                              {current.connHistory.length
                                ? current.connHistory[0].loginDate
                                : locale.resource['txt.no-connection-history']}
                            </Button>
                          </Tooltip>
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                    <Col>
                      <Monitor screens={screens}>
                        <DesktopMessage locale={locale} current={current} />
                        <Tooltip title={locale.resource[current.actions.connect.tooltip]}>
                          <ConnectBtn
                            size="large"
                            disabled={current.actions.connect.disabled}
                            icon={current.actions.connect.icon}
                            onClick={() =>
                              onConnect(form.getFieldValue('multi-monitors'), redirectUrlToVd)
                            }
                          >
                            {locale.resource['txt.connect']}
                          </ConnectBtn>
                        </Tooltip>
                        <Form form={form}>
                          <Form.Item name="multi-monitors" valuePropName="checked" noStyle>
                            <Checkbox
                              style={{ color: '#ffffff' }}
                              disabled={!current.isAccessibleIp || current.locked}
                            >
                              {locale.resource['txt.dual-monitor']}
                            </Checkbox>
                          </Form.Item>
                        </Form>
                        <div className="failoverDropdown" style={{ textAlign: 'right' }}>
                          <Dropdown
                            overlay={() => {
                              const menuItems = current.actions.failovers.map((item) => {
                                return (
                                  <Menu.Item
                                    key={item.label}
                                    disabled={item.disabled}
                                    onClick={() => onFailover(item)}
                                  >
                                    {locale.resource[item.label]}
                                  </Menu.Item>
                                );
                              });
                              return <Menu>{menuItems}</Menu>;
                            }}
                          >
                            <Button type="text" icon={<Icon name={<TiSpanner />} />}>
                              {locale.resource['txt.self-failover']}
                            </Button>
                          </Dropdown>
                        </div>
                      </Monitor>
                    </Col>
                  </Row>
                </div>
              ))
            }
          </Carousel>
        </Col>
        <Col>
          <Button
            shape="circle"
            icon={<AiOutlineRight className="anticon" />}
            onClick={gotoNext}
            style={{ visibility: desktops.length > 1 ? 'visible' : 'hidden' }}
          />
        </Col>
      </Row>
      <ConnHistoryTable
        visible={visibleConnHistory}
        dispatch={dispatch}
        dataSource={current.connHistory}
      />
    </Spin>
  );
}

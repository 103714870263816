import { Col, DatePicker, Form, Row, Table } from 'antd';
import React from 'react';
import { ApprovalProcess, MailCC, OwnDisk, Panel, RequestInfo } from './components';

function DiskUsetime() {
  return (
    <Form layout="horizontal" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <RequestInfo />
        </Col>
        <Col span={24}>
          <OwnDisk title="Disk 보유 정보" rowSelection={{ type: 'radio' }} />
        </Col>
        <Col span={24}>
          <Panel title="Disk 변경 정보">
            <Table
              size="small"
              rowKey="key"
              dataSource={[
                {
                  key: 1,
                  name: 'LGDKRA.EA팀',
                  term: 100,
                },
              ]}
              columns={[
                { title: 'Disk 이름', dataIndex: 'name' },
                {
                  title: '연장 기간',
                  dataIndex: 'term',
                  render: () => <DatePicker.RangePicker />,
                },
              ]}
            />
          </Panel>
        </Col>
        <Col span={24}>
          <ApprovalProcess />
        </Col>
        <Col span={24}>
          <MailCC />
        </Col>
      </Row>
    </Form>
  );
}

export default DiskUsetime;

import { Alert, Col, DatePicker, Form, List, message, Modal, Row } from 'antd';
import LocaleContext from 'locales';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { getDocumentDetail, requestApproval, updateApproval } from 'services/websocket/session';
import { getErrorFields, getValidationMsgs } from '../utils';
import { OwnDesktops, RequestInfo, TimePreset, UserSearchInput } from './components';

function DesktopReinstall({ readOnly, form, id, onClose }) {
  const { locale } = useContext(LocaleContext);
  const [user, setUser] = React.useState('');
  const [selectedVd, setSelectedVd] = React.useState([]);
  const [userName, setUserName] = React.useState('');

  useEffect(() => {
    if (!id) {
      form.resetFields();
      setUser('');

      const applicant = localStorage.getItem('userId');
      form.setFieldsValue({
        applicant,
        type: 'approval.types.reset-vd',
      });
      return;
    }

    getDocumentDetail(id).then((res) => {
      const data = res?.data; // #178732#note-16
      let approveDate = data?.approveDate;

      if (approveDate !== null) {
        approveDate = moment(approveDate);
      }

      form.setFieldsValue({
        ...data,
        approveDate,
        applyDate: moment(data.applyDate).format('YYYY-MM-DD HH:mm:ss'),
      });
      setUser(data?.user);
      setUserName(data?.userName);
    });
  }, [id]);

  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 19 }}
      onFinish={async ({ approveDate, ...fields }) => {
        const payload = {
          ...fields,
          contents: selectedVd,
          approveDate: approveDate?.format('YYYY-MM-DD HH:mm') || approveDate,
        };
        try {
          const result = id ? await updateApproval(id, payload) : await requestApproval(payload);
          if (result.status === 'error') {
            const { invalidParams } = result;
            const errors = getErrorFields(getValidationMsgs(invalidParams));
            form.setFields(errors);
            const generalErrors = errors.find((el) => el.name[0] === 'generalValidationError'); // generalValidationError는 무조건 하나만 받고 errors를 배열로 여러 개 받는다.
            if (generalErrors) {
              Modal.error({
                title: locale.resource['txt.failed-to-save'],
                content: generalErrors.errors.map((el) => <div>{el}</div>),
              });
            }
            return;
          }
          form.resetFields();
          onClose();
        } catch (e) {
          message.error(e);
        }
      }}
    >
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Alert
            showIcon
            type="warning"
            message={
              <List
                size="small"
                split={false}
                dataSource={[locale.resource['txt.os-reinstall-msg']]}
                renderItem={(item) => <List.Item style={{ padding: 4 }}>{item}</List.Item>}
              />
            }
          />
        </Col>
        <Col span={24}>
          <RequestInfo readOnly={readOnly} />
          <Form.Item label={locale.resource['txt.approval-type']} name="type" required>
            {locale.resource['txt.vd-reset']}
          </Form.Item>
          <Form.Item
            label={locale.resource['txt.user-and-id']}
            name="user"
            rules={[{ required: true, message: locale.resource['txt.required-field'] }]}
          >
            <UserSearchInput
              readOnly1={readOnly}
              onSuccess={async (uid) => {
                // 처리
                setUser(uid);
              }}
              userName={userName}
            />
          </Form.Item>
          <Form.Item
            label={locale.resource['txt.reinstallation']}
            name="contents"
            valuePropName="contents"
            required
          >
            <OwnDesktops
              title={false}
              bodyStyle={{ padding: 0 }}
              user={user}
              readOnly={readOnly}
              onSelect={(record) => {
                setSelectedVd(record);
              }}
              id={id}
            />
          </Form.Item>
          <Form.Item
            label={locale.resource['txt.execution-date']}
            name="approveDate"
            extra={locale.resource['txt.excution-date-msg']}
            rules={[
              () => ({
                validator(_, value) {
                  if (value > moment() || !value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(locale.resource['txt.excution-date-val']));
                },
              }),
            ]}
            initialValue={null} // #193685
          >
            <DatePicker
              showTime={{
                defaultValue: moment('00:00', 'HH:mm'),
                format: 'HH:mm',
              }}
              inputReadOnly={1}
              disabled={readOnly}
              placeholder=""
              disabledDate={(date) => date && date < moment().startOf('day')}
              showNow={false}
              format="YYYY-MM-DD HH:mm"
              renderExtraFooter={() => (
                <TimePreset
                  onSelect={(time) => {
                    form.setFieldsValue({ approveDate: time });
                  }}
                />
              )}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default DesktopReinstall;

/* eslint-disable react/react-in-jsx-scope */
import { Space, Typography } from 'antd';
import { TextEllipsis } from 'components';
import LocaleContext from 'locales';
import { displaySize } from 'pages/utils';
import { useCallback, useContext, useEffect, useState } from 'react';
import { getFlavors } from 'services/websocket/session';
/**
 * 플레이버 정보를 가져오는 hook
 * 서비스 신청 페이지에서 사용한다.
 */
export default function useFlavorInfo() {
  const { locale } = useContext(LocaleContext);
  const [flavorData, setFlavorData] = useState([]);

  const fetchFlavors = useCallback(async () => {
    try {
      getFlavors().then(({ data, success }) => {
        if (success) {
          const flavorInfo = data.data;
          setFlavorData(flavorInfo);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchFlavors();
  }, [fetchFlavors]);

  /**
   * 선택한 플레이버 정보를 가져온다.
   * @param {string} flavorName 플레이버 이름
   * @returns {object} 서비스 신청 payload에 들어갈 플레이버 정보
   */
  const getSelectedFlavor = (name) => {
    const selectedFlavor = flavorData.find(({ flavorName }) => flavorName === name);
    if (!selectedFlavor) {
      return null; // or throw an error
    }
    // eslint-disable-next-line no-unused-vars
    const { flavorId, flavorName, cpus, ram, extraSpecs, ...rest } = selectedFlavor;
    return { flavorId, flavorName, cpus, ram, extraSpecs };
  };

  return [
    {
      flavorData,
      flavorOptions: flavorData.map(({ flavorName, cpus, ram, extraSpecs }) => {
        const { size, unit } = displaySize(ram);
        return {
          label: (
            <Space>
              <TextEllipsis text={flavorName} />
              <Typography.Text type="secondary">
                {cpus}Core / {size}
                {unit} / GPU{' '}
                {extraSpecs?.['resources:VGPU']
                  ? locale.resource['txt.used']
                  : locale.resource['txt.not-used']}
              </Typography.Text>
            </Space>
          ),
          value: flavorName,
        };
      }),
    },
    getSelectedFlavor,
  ];
}

import { request } from './util';

/*
 * 연결 가능한 VD 그룹을 가져옴
 */
export async function getAvailableVdGroups() {
  return request({
    scope: 'portal',
    name: 'session/get-available-vds',
    data: {},
  });
}

export async function getAvailableVdDetail(data) {
  return request({
    scope: 'portal',
    name: 'session/get-available-vd-detail',
    data,
  });
}

/*
 * VD 제어(전원켜기, 재시작...)
 */
export async function requestVdAction(vdGroupId, vdId, action) {
  return request({
    scope: 'portal',
    name: 'session/control-vd-action',
    data: {
      vdGroupId,
      vdId,
      cmd: action,
    },
  });
}

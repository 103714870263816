import React, { useState } from 'react';
import { Badge, Button, Col, Descriptions, Drawer, Row, Table } from 'antd';
import Icon from 'components/Icon';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';

const disks = [
  {
    key: 1,
    name: '마케팅 1팀',
    region: 'seoul',
    purpose: 'Team',
    disk: 200,
    period: '영구(0)',
    status: '사용 중',
    createDate: '2021-12-18 22:15:57',
  },
  {
    key: 2,
    name: '영업지원팀',
    region: 'busan',
    purpose: 'Team',
    disk: 200,
    period: '영구(0)',
    status: '사용 중',
    createDate: '2021-10-01 01:23:04',
  },
  {
    key: 3,
    name: '마케팅 3팀',
    region: 'CA.US',
    purpose: 'Team',
    disk: 200,
    period: '영구(0)',
    status: '사용 중',
    createDate: '2021-10-03 06:28:39',
  },
];

function renderPermission(value) {
  let icon;
  if (value) {
    icon = <Icon name={<AiOutlineCheckCircle fontSize={16} color="green" />} />;
  } else {
    icon = <Icon name={<AiOutlineCloseCircle fontSize={16} color="red" />} />;
  }
  return icon;
}

function UsageDisk() {
  const [visible, setVisible] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  const handleClick = (record) => {
    setSelectedData(record);
    setVisible(true);
  };

  const diskColumns = [
    {
      title: '이름',
      dataIndex: 'name',
      fixed: 'left',
      render: (item, record) => (
        <Button size="small" type="link" onClick={() => handleClick(record)}>
          {item}
        </Button>
      ),
    },
    {
      title: 'Region',
      dataIndex: 'region',
      width: 140,
    },
    {
      title: '용도',
      dataIndex: 'purpose',
      width: 140,
    },
    {
      title: '용량',
      dataIndex: 'disk',
      width: 100,
      align: 'right',
      render: (item) => <span>{item}GB</span>,
    },
    {
      title: '사용 기간(연장 횟수)',
      dataIndex: 'period',
      width: 180,
    },
    {
      title: '상태',
      dataIndex: 'status',
      width: 120,
      render: (item) => <Badge color="green" text={item} />,
    },
    {
      title: '생성 일자',
      dataIndex: 'createDate',
      width: 180,
    },
  ];

  return (
    <>
      <Table
        size="small"
        pagination={false}
        rowKey="key"
        dataSource={disks}
        columns={diskColumns}
        scroll={{ x: 1000 }}
      />
      <Drawer
        title={selectedData.name}
        width={640}
        visible={visible}
        onClose={() => setVisible(false)}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <div style={{ padding: 8 }}>
              <strong>기본 정보</strong>
            </div>
            <Descriptions bordered size="small" column={1} labelStyle={{ width: 200 }}>
              <Descriptions.Item label="이름">{selectedData.name}</Descriptions.Item>
              <Descriptions.Item label="Region">{selectedData.region}</Descriptions.Item>
              <Descriptions.Item label="용도">{selectedData.purpose}</Descriptions.Item>
              <Descriptions.Item label="용량">{selectedData.disk}</Descriptions.Item>
              <Descriptions.Item label="사용 기간(연장 횟수)">
                {selectedData.period}
              </Descriptions.Item>
              <Descriptions.Item label="상태">
                <Badge color="green" text={selectedData.status} />
              </Descriptions.Item>
              <Descriptions.Item label="생성 일자">{selectedData.createDate}</Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={24}>
            <div style={{ padding: 8 }}>
              <strong>사용자 권한 정보</strong>
            </div>
            <Table
              size="small"
              bordered
              rowKey="key"
              dataSource={[
                {
                  key: 1,
                  user: '강현준',
                  size: true,
                  disposal: true,
                  userControl: true,
                  readWrite: true,
                  read: true,
                },
                {
                  key: 2,
                  user: '강서연',
                  size: false,
                  disposal: false,
                  userControl: false,
                  readWrite: true,
                  read: true,
                },
                {
                  key: 3,
                  user: '권준서',
                  size: false,
                  disposal: false,
                  userControl: true,
                  readWrite: true,
                  read: true,
                },
                {
                  key: 4,
                  user: '신지훈',
                  size: false,
                  disposal: false,
                  userControl: false,
                  readWrite: true,
                  read: true,
                },
                {
                  key: 5,
                  user: '홍민서',
                  size: false,
                  disposal: false,
                  userControl: false,
                  readWrite: false,
                  read: true,
                },
              ]}
              columns={[
                { title: '사용자', dataIndex: 'user' },
                {
                  title: '권한',
                  children: [
                    {
                      title: '용량',
                      align: 'center',
                      dataIndex: 'size',
                      render: (value) => renderPermission(value),
                    },
                    {
                      title: '폐기',
                      align: 'center',
                      dataIndex: 'disposal',
                      render: (value) => renderPermission(value),
                    },
                    {
                      title: '사용자',
                      align: 'center',
                      dataIndex: 'userControl',
                      render: (value) => renderPermission(value),
                    },
                    {
                      title: '읽기/쓰기',
                      align: 'center',
                      dataIndex: 'readWrite',
                      render: (value) => renderPermission(value),
                    },
                    {
                      title: '읽기',
                      align: 'center',
                      dataIndex: 'read',
                      render: (value) => renderPermission(value),
                    },
                  ],
                },
              ]}
            />
          </Col>
        </Row>
      </Drawer>
    </>
  );
}

export default UsageDisk;

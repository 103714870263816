import {
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Tag,
  Tooltip,
} from 'antd';
import Icon from 'components/Icon';
import React from 'react';
import { AiOutlinePlus, AiOutlineQuestionCircle, AiOutlineSearch } from 'react-icons/ai';
import { ApprovalProcess, MailCC, Panel, RequestInfo, SelectHR, SelectTeam } from './components';

function DiskCreate() {
  const [selectTeamModal, setSelectTeamModal] = React.useState({ undefined });
  const [selectUserModal, setSelectUserModal] = React.useState({ undefined });

  return (
    <Form layout="horizontal" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <RequestInfo />
        </Col>
        <Col span={24}>
          <Panel title="Disk 생성 정보">
            <Descriptions column={1} bordered size="small" labelStyle={{ width: 140 }}>
              <Descriptions.Item label="Disk 이름">
                <Input defaultValue="LGDKRA.EA팀" />
              </Descriptions.Item>
              <Descriptions.Item label="용도">Team</Descriptions.Item>
              <Descriptions.Item label="조직">
                <Space>
                  <span>LGDKRA.EA팀</span>
                  <Button
                    size="small"
                    icon={<Icon name={<AiOutlineSearch />} />}
                    onClick={() => setSelectTeamModal({ title: '조직 선택', visible: true })}
                  >
                    선택
                  </Button>
                  <SelectTeam
                    title={selectTeamModal?.title}
                    visible={selectTeamModal?.visible}
                    onCancel={() => setSelectTeamModal(undefined)}
                    onOk={() => setSelectTeamModal(undefined)}
                  />
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label="PM/리더">박민재</Descriptions.Item>
              <Descriptions.Item label="용량(GB)">
                <InputNumber addonAfter="GB" defaultValue={10} style={{ width: 128 }} />
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Space>
                    <span>소유자</span>
                    <Tooltip title="Disk 용량/폐기/권한 관리자">
                      <Icon name={<AiOutlineQuestionCircle />} />
                    </Tooltip>
                  </Space>
                }
              >
                <Tag>박민재/park.mj/팀장/LGDKRA.EA팀</Tag>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Space>
                    <span>관리자</span>
                    <Tooltip title="Disk 사용자 권한 관리자">
                      <Icon name={<AiOutlineQuestionCircle />} />
                    </Tooltip>
                  </Space>
                }
              >
                <Tag>박민재/park.mj/팀장/LGDKRA.EA팀</Tag>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Space>
                    <span>일반 사용자</span>
                    <Tooltip title="Disk 읽기/쓰기 권한자">
                      <Icon name={<AiOutlineQuestionCircle />} />
                    </Tooltip>
                  </Space>
                }
              >
                <Tag>LGDKRA.EA팀/VDIGRP_55720/LGDKRA.EA팀</Tag>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Space>
                    <span>제한 사용자</span>
                    <Tooltip title="Disk 읽기 권한자">
                      <Icon name={<AiOutlineQuestionCircle />} />
                    </Tooltip>
                  </Space>
                }
              >
                <Space>
                  <Tag closable>박민재/park.mj/팀장/LGDKRA.EA팀</Tag>
                </Space>
                <Button
                  size="small"
                  icon={<Icon name={<AiOutlinePlus />} />}
                  onClick={() => setSelectUserModal({ title: '제한 사용자 선택', visible: true })}
                >
                  추가
                </Button>
                <SelectHR
                  title={selectUserModal?.title}
                  visible={selectUserModal?.visible}
                  onCancel={() => setSelectUserModal(undefined)}
                  onOk={() => setSelectUserModal(undefined)}
                />
              </Descriptions.Item>
            </Descriptions>
          </Panel>
        </Col>
        <Col span={24}>
          <ApprovalProcess />
        </Col>
        <Col span={24}>
          <MailCC />
        </Col>
      </Row>
    </Form>
  );
}

export default DiskCreate;

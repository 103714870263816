import { Button, Layout, Result, Typography } from 'antd';
import LocaleContext from 'locales';
import React, { useContext, useEffect, useState } from 'react';
import { getCustomRedirect } from 'services/websocket/session';

function Transfer() {
  const [customUrls, setCustomUrls] = useState();
  const { locale } = useContext(LocaleContext);
  useEffect(() => {
    getCustomRedirect().then((customUrl) => {
      setCustomUrls(customUrl);
    });
  }, []);

  return (
    <Layout.Content>
      <Result
        title={locale.resource['txt.file-download-guide']}
        subTitle={<Typography.Paragraph>{locale.resource['txt.vd-to-local']}</Typography.Paragraph>}
        extra={
          <Button type="primary" key="console" href={customUrls?.fileDownLoad} target="_blank">
            {customUrls?.fileDownLoad}
          </Button>
        }
      >
        <Typography style={{ whiteSpace: 'pre-line' }}>
          {locale.resource['txt.write-application']}
        </Typography>
      </Result>
    </Layout.Content>
  );
}

export default Transfer;

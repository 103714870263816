import {
  Avatar,
  Button,
  Card,
  Descriptions,
  Form,
  Input,
  Modal,
  Skeleton,
  Typography,
  message,
} from 'antd';
import LocaleContext from 'locales';
import { getValidationMsgs2 } from 'pages/utils';
import React, { useContext } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { changePassword, getBioAuthUser } from 'services/websocket/auth';
import { getCustomRedirect, getSessionUser } from 'services/websocket/session';
import ThemeContext from 'store/ThemeContext';
import styled from 'styled-components';
import { encryptRSA, getRSAKey } from 'services/auth';
import packageJson from '../../../../package.json';

const StyledCard = styled(Card)`
  border-radius: 8px;
  background-color: #eaf0f7;
  .ant-card-body {
    padding: 40px;
  }
  .ant-descriptions-row:last-child > th,
  .ant-descriptions-row:last-child > td {
    padding-bottom: 0px;
  }
`;
const styles = {
  avata: {
    color: '#94a2f4',
    backgroundColor: '#5b69bc',
    size: 40,
  },
  form: {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  },
};

/**
 * 사용자 정보 표시
 * @returns ReactNode
 */
function UserInfo({ bioAuth }) {
  const { locale } = useContext(LocaleContext);
  const [changePasswordVisible, setChangePasswordVisible] = React.useState(false);
  const [user, setUser] = React.useState(undefined);
  const [customUrls, setCustomUrls] = React.useState();
  const [passwordResetForm] = Form.useForm();
  const theme = useContext(ThemeContext);
  const [encryptKey, setEncryptKey] = React.useState();

  React.useEffect(() => {
    getSessionUser().then((res) => {
      setUser(res);
    });
    getRSAKey().then(({ data }) => setEncryptKey(data.key));
    getCustomRedirect().then((customUrl) => setCustomUrls(customUrl));
  }, []);

  const onFinish = async () => {
    const userId = localStorage.getItem('userId');
    const password = passwordResetForm.getFieldValue('newPwd1');
    const currentPassword = passwordResetForm.getFieldValue('currentPassword');

    const payload = {
      userId,
      password: encryptRSA(password, encryptKey),
      currentPassword: encryptRSA(currentPassword, encryptKey),
    };
    const res = await changePassword(payload);

    if (res.success) {
      Modal.success({
        centered: true,
        title: locale.resource['password.change.success'],
        content: locale.resource['password.change.success.to-requested-password'],
        onOk: () => window.location.reload(),
      });
      return;
    }
    if (res.errorType === 'validation') {
      const errors = getValidationMsgs2(res.data).map((el) => {
        if (el.name[0] === 'password') {
          return { ...el, name: ['newPwd1'] };
        }
        return el;
      });
      passwordResetForm.setFields(errors);
      return;
    }
    message.error(locale.resource(res.errorMessage));
  };

  /**
   * 비밀번호 변경
   *   방법 1. 자체: 현재 페이지는 로그인 완료 상태(= 인증 완료)이기 때문에 신규 비밀번호를 바로 입력받는다.
   *   방법 2. windowOpen(외부): 환경설정 > 기능 > Portal 내 링크 커스터마이징 > 비밀번호 변경 url을 연다.
   * @returns ReactNode || window.open
   */
  const doPasswordChange = async () => {
    let externalUrl = customUrls?.passwordChange || undefined; // 비밀번호 초기화 url

    // #186818 하나은행 생체 인증을 사용하는 경우 생체인증 서버에 인증 대상인지 질의한다.
    if (bioAuth && packageJson.config?.preid?.startsWith('hanabank')) {
      try {
        const res = await getBioAuthUser(localStorage.getItem('userId'));
        if (!res.success) {
          throw new Error('txt.bio-auth-msg.conn-refuesed');
        }
        const { registeredQr, registeredOtp, registeredFace, registeredPattern } = res.data?.user;
        if (registeredQr || registeredOtp || registeredFace || registeredPattern) {
          externalUrl = 'http://asi.infohana.com:8082';
        }
      } catch (err) {
        return Modal.error({
          title: locale.resource['txt.operation-failed'],
          content: locale.resource[err.message],
        });
      }
    }

    const windowOpen = (url) => {
      const windowFeatures =
        theme.customUrls?.passwordChange?.windowFeatures ||
        `height=${window.screen.height},width=${window.screen.width},resizable=yes,fullscreen=yes`;
      window.open(url, '', windowFeatures);
    };

    return externalUrl ? windowOpen(externalUrl) : setChangePasswordVisible(true);
  };

  return (
    <StyledCard bordered={false}>
      <Skeleton loading={user === undefined} avatar active>
        <Card.Meta
          avatar={<Avatar style={styles.avata} size={40} icon={<AiOutlineUser />} />}
          title={user && <Typography.Title level={4}>{user.name ?? '-'}</Typography.Title>}
          description={
            user && (
              <Descriptions size="small" column={2}>
                <Descriptions.Item label={locale.resource['txt.id-ko']}>
                  {user.sAMAccountName}
                </Descriptions.Item>
                <Descriptions.Item label={locale.resource['txt.department']}>
                  {user.organization ?? '-'}
                </Descriptions.Item>
                <Descriptions.Item label={locale.resource['txt.email']}>
                  {user.mail ?? '-'}
                </Descriptions.Item>
                {theme.customUrls?.passwordChange && (
                  <Descriptions.Item label={locale.resource['txt.password']}>
                    <Button size="small" onClick={doPasswordChange}>
                      {locale.resource['txt.change']}
                    </Button>
                    <Modal
                      title={locale.resource['txt.change-password']}
                      visible={changePasswordVisible}
                      width={380}
                      okText={locale.resource['txt.change']}
                      cancelText={locale.resource['txt.cancel']}
                      onOk={passwordResetForm.submit}
                      onCancel={() => {
                        setChangePasswordVisible(false);
                        passwordResetForm.resetFields();
                      }}
                      destroyOnClose
                    >
                      <Form layout="vertical" form={passwordResetForm} onFinish={onFinish}>
                        <Form.Item
                          label={locale.resource['txt.current-password']}
                          name="currentPassword"
                          rules={[
                            { required: true, message: locale.resource['txt.enter-password'] },
                          ]}
                        >
                          <Input.Password
                            visibilityToggle={false}
                            allowClear
                            autoComplete="current-password"
                          />
                        </Form.Item>
                        <Form.Item
                          label={locale.resource['txt.new-password']}
                          name="newPwd1"
                          rules={[
                            { required: true, message: locale.resource['txt.enter-password'] },
                          ]}
                        >
                          <Input.Password
                            visibilityToggle={false}
                            allowClear
                            autoComplete="current-password"
                          />
                        </Form.Item>
                        <Form.Item
                          label={locale.resource['txt.confirm-password']}
                          name="newPwd2"
                          dependencies={['newPwd1']}
                          hasFeedback
                          rules={[
                            { required: true, message: locale.resource['txt.enter-password'] },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (getFieldValue('newPwd1') === value) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(locale.resource['txt.password-error']),
                                );
                              },
                            }),
                          ]}
                        >
                          <Input.Password
                            visibilityToggle={false}
                            allowClear
                            autoComplete="new-password"
                          />
                        </Form.Item>
                      </Form>
                    </Modal>
                  </Descriptions.Item>
                )}
              </Descriptions>
            )
          }
        />
      </Skeleton>
    </StyledCard>
  );
}

export default UserInfo;

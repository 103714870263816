import { request } from './util';

export async function getOwnVdsOfUser(userId) {
  return request({
    scope: 'portal',
    name: 'users/get-own-vds',
    data: {
      userId,
    },
  });
}

export async function getVdsOfUser(userId, approvalId) {
  return request({
    scope: 'portal',
    name: 'users/get-user-vds',
    data: {
      userId,
      approvalId,
    },
  });
}

export async function getVdGroups() {
  return request({
    scope: 'portal',
    name: 'users/get-vdgroups',
    data: {},
  });
}

export async function getUser(user) {
  return request({
    scope: 'portal',
    name: 'users/get-user-info',
    data: { user },
  });
}

export async function getUserSetting() {
  return request({
    scope: 'portal',
    name: 'users/get-settings',
    data: {},
  });
}

/*
 * 사용자 정보 설정
 */
export async function saveUserSetting(data) {
  return request({
    scope: 'portal',
    name: 'users/save-settings',
    data,
  });
}

export async function getLoacleData(locale) {
  return request({
    scope: 'portal',
    name: 'users/get-locale',
    data: { locale },
  });
}

import request from './request';

/**
 * ! deprecated: websocket api 도입으로 사용 금지
 * @deprecated websocket api를 사용하여 주십시오.
 */
export async function getAvailableVdGroups() {
  const res = await request.get(`/portal/api/session/vdgroups`, {
    headers: { Pragma: 'no-cache' },
  });
  return res.data;
}

/**
 * ! deprecated: websocket api 도입으로 사용 금지
 * @deprecated websocket api를 사용하여 주십시오.
 */
export async function getAvailableVdDetail(id) {
  const res = await request.get(`/portal/api/session/vdgroups/${id}`, {
    headers: { Pragma: 'no-cache' },
  });
  return res.data;
}

/**
 * ! deprecated: websocket api 도입으로 사용 금지
 * @deprecated websocket api를 사용하여 주십시오.
 */
export async function requestVdAction(groupId, action) {
  const res = await request.post(`/portal/api/session/vdgroups/${groupId}/${action}`);
  return res;
}

// /*
//  * 자동 접속할 vd 설정 저장
//  */
// export async function saveAutoLoginVd(vdGroupId) {
//   const res = await request.post(`/portal/api/session/vdgroups/autologin`, vdGroupId);
//   return res;
// }

export async function getUserSetting() {
  const res = await request.get(`/portal/api/users/settings`, {
    headers: { Pragma: 'no-cache' },
  });
  return res.data;
}

/*
 * 사용자 정보 설정
 */
export async function saveUserSetting(payload) {
  const res = await request.put(`/portal/api/users/settings`, {
    autoLoginGroup: payload.vdGroupId,
  });
  return res;
}

export async function getLoacleData(locale) {
  const res = await request.get(`/portal/api/locale/res/string?locale=${locale}`, {
    headers: { Pragma: 'no-cache' },
  });
  return res.data;
}

// 환경 설정 값 가져오기
export async function getCurrentConfig(key) {
  const res = await request.get(
    `/portal/api/preferences/current-config`,
    key && { params: { key } },
  );
  return res.data;
}

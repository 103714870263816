import { Tag } from 'antd';
import { getStateIconInfo } from 'pages/utils';
import React from 'react';

/**
 * VD 방식 및 상태 렌더링
 * @param {object} desktop 표현할 VD의 정보
 * @returns ReactNode
 */
function RenderTags({ desktop, locale }) {
  // state와 subState가 다르면 같이 표시해준다.
  const stateStr = () => {
    const state = locale.resource[desktop.state];
    const subState = locale.resource[desktop.subState];
    const result = subState && state !== subState ? `${state}(${subState})` : state;
    return result;
  };
  return (
    <div>
      <Tag>{locale.resource[desktop.groupType]}</Tag>
      <Tag color={getStateIconInfo(desktop.state)}>
        {stateStr() || locale.resource['txt.unknown']}
      </Tag>
    </div>
  );
}

export default RenderTags;

/* eslint-disable import/prefer-default-export */
/**
 * FIXME: 원래는 babel과 browserlist에서 해결해야 할 이슈이나 땜빵 처리
 */

// IE 11 지원을 위한 Promise.allSettled Polyfill
export function PromiseAllSettled(promises) {
  const mappedPromises = promises.map((p) => {
    return p
      .then((value) => {
        return {
          status: 'fulfilled',
          value,
        };
      })
      .catch((reason) => {
        return {
          status: 'rejected',
          reason,
        };
      });
  });
  return Promise.all(mappedPromises);
}

// IE 11 지원을 위한 A태그 download attribute Polyfill
// https://github.com/jelmerdemaat/dwnld-attr-polyfill
export const downloadPolyfill = (() => {
  const isBrowser = typeof window !== 'undefined';
  const downloadAttributeSupport = isBrowser && 'download' in document.createElement('a');
  const msSaveBlob = isBrowser && typeof window.navigator.msSaveBlob !== 'undefined';

  if (!downloadAttributeSupport && msSaveBlob) {
    document.addEventListener('click', (evt) => {
      const { target } = evt;
      const { tagName } = target;
      if (tagName === 'A' && target.hasAttribute('download')) {
        evt.preventDefault();
        const { href } = target;
        const download = target.getAttribute('download');
        const fileName = download || href.split('/').pop();
        const xhr = new XMLHttpRequest();
        xhr.open('GET', href);
        xhr.responseType = 'blob';
        xhr.onreadystatechange = () => {
          if (xhr.readyState !== 4) {
            return;
          }
          if (xhr.status === 200) {
            window.navigator.msSaveBlob(xhr.response, fileName);
          } else {
            throw new Error('download-attribute-polyfill:', xhr.status, xhr.statusText);
          }
        };
        xhr.send();
      }
    });
  }
})();

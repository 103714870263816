import { Modal, Space, Spin } from 'antd';
import LocaleContext from 'locales';
import React, { useContext, useEffect, useState } from 'react';
import * as VsclientService from 'services/vsclient';
import ThemeContext from 'store/ThemeContext';

function ClientUpdateCheck({ onEnd }) {
  const theme = useContext(ThemeContext);
  const [updating, setUpdating] = useState(false);
  const { locale } = useContext(LocaleContext);

  const createMarkup = (value) => {
    return { __html: value };
  };

  useEffect(() => {
    const checkFunc = async () => {
      try {
        await VsclientService.isUpdate();
        // eslint-disable-next-line no-unused-expressions
        !updating && setUpdating(true);
      } catch (e) {
        setUpdating(false);
        onEnd?.(true);
      }
      setTimeout(checkFunc, 5000);
    };

    if (theme?.updateCheck) {
      checkFunc();
    }
  }, []);

  return (
    <div>
      {updating && (
        <Modal footer={false} visible title={locale.resource['txt.updating']} width={320}>
          <Space style={{ padding: 16 }} size={16} align="center">
            <Spin spinning style={{ marginTop: 6 }} />
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={createMarkup(locale.resource['txt.updating-client'])}
            />
          </Space>
        </Modal>
      )}
    </div>
  );
}

export default ClientUpdateCheck;

import React from 'react';

import { Redirect, Switch } from 'react-router-dom';

import { Basic, Blank, RouteWithLayout } from './layouts';
import * as Pages from './pages';

export default function Routes({ config }) {
  return (
    <Switch>
      <Redirect exact from="/" to="/auth/login" />
      <Redirect exact from="/pre-check" to="/auth/pre-check" />
      <Redirect exact from="/help" to="/help/notice/list" />
      <RouteWithLayout
        name="URL Redirection"
        menuId="urlRedirectAfterUpdating"
        component={Pages.ClientUpdateCheckInURLRedirection}
        exact
        layout={Blank}
        path="/url-redirect/updating"
        config={config}
      />
      <RouteWithLayout
        name="URL Redirection"
        menuId="urlRedirect"
        component={Pages.urlRedirect}
        exact
        layout={Blank}
        path="/url-redirect"
        config={config}
      />
      <RouteWithLayout
        name="PreCheck"
        menuId="preCheck"
        component={Pages.PreCheck}
        exact
        layout={Blank}
        path="/auth/pre-check"
        componentProps={{
          config,
        }}
        config={config}
      />
      <RouteWithLayout
        name="Login"
        menuId="login"
        component={Pages.Login}
        exact
        layout={Blank}
        componentProps={{
          config,
        }}
        path="/auth/login"
        config={config}
      />
      <RouteWithLayout
        name="txt.sign-up"
        menuId="register"
        component={Pages.Register}
        exact
        layout={Blank}
        path="/auth/register"
        config={config}
      />
      <RouteWithLayout
        name="txt.required-program"
        menuId="requiredApps"
        component={Pages.RequiredApps}
        exact
        layout={Blank}
        path="/auth/requiredApps"
        config={config}
      />
      <RouteWithLayout
        name="txt.desktops"
        menuId="desktops"
        component={Pages.Desktops}
        exact
        layout={Basic}
        path="/desktops"
        componentProps={{
          config,
        }}
        config={config}
      />
      <RouteWithLayout
        name="txt.file-download"
        menuId="transfer"
        component={Pages.Transfer}
        exact
        layout={Basic}
        path="/transfer"
        config={config}
      />
      <RouteWithLayout
        name="txt.request-approval"
        menuId="request"
        component={Pages.Request}
        exact
        layout={Basic}
        path="/request"
        access={['request']}
        config={config}
      />
      {/* <RouteWithLayout
        name="승인 업무"
        key='approval'
        component={Pages.Approval}
        exact
        layout={Basic}
        path="/approval"
        config={config}
      /> */}
      {/* <RouteWithLayout
        name="Help Center"
        key='help'
        component={Pages.Help}
        exact
        layout={Basic}
        path="/help"
        config={config}
      /> */}
      <RouteWithLayout
        name="txt.notice"
        menuId="notice"
        component={Pages.NoticeList}
        exact
        layout={Basic}
        path="/help/notice/list"
        config={config}
      />
      <RouteWithLayout
        name="txt.notice"
        menuId="notice"
        component={Pages.NoticeView}
        exact
        layout={Basic}
        path="/help/notice/view"
        config={config}
      />
      <RouteWithLayout
        name="txt.faq"
        menuId="faq"
        component={Pages.Faq}
        exact
        layout={Basic}
        path="/help/faq"
        config={config}
      />
      <RouteWithLayout
        name="txt.download"
        menuId="download"
        component={Pages.Download}
        exact
        layout={Basic}
        path="/help/download"
        config={config}
      />
      <RouteWithLayout
        name="txt.my-account"
        menuId="myInfo"
        component={Pages.MyInfo}
        componentProps={{
          config,
        }}
        exact
        layout={Basic}
        path="/myInfo"
        config={config}
      />
      <RouteWithLayout
        name="txt.usage-status"
        menuId="usage"
        component={Pages.Usage}
        exact
        layout={Basic}
        path="/myInfo/usage"
        config={config}
      />
      <RouteWithLayout
        name="Error"
        menuId="error"
        component={Pages.Error}
        exact
        layout={Basic}
        path="/error"
        config={config}
      />
    </Switch>
  );
}

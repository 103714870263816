import axios from 'axios';

const UpgradeCode = 'ED378D66-D8A9-41A9-AF53-9880D7EE141F';
// const strErrUrl = 'http://fsw.qa.fasoo.com/demo.html';
const cmd = 'CHKMSI';

async function CheckVersionPort(protocol, port, version) {
  const date = new Date();
  const url =
    `${protocol}://localhost:${port}/` +
    `@CMD:${cmd}@P1:${UpgradeCode}@P2:${version}@P3:` +
    `` +
    `@P4:` +
    `` +
    `@${date}`;

  try {
    // axios로 바뀔예정
    return (await axios.get(url)).status === 200;
  } catch (e) {
    return false;
  }
}

function GetOsInfo() {
  const tp = navigator.platform;
  const ua = navigator.userAgent;

  const result = {};

  // platform
  if (tp === 'Win32' || tp === 'Win64') result.platform = 'WINDOWS';
  else if (tp === 'MacIntel') result.platform = 'MACOSX';
  else if (tp.search('Linux') >= 0) result.platform = 'LINUX';
  else result.platform = 'UNKNOWN';

  // version, bit
  if (result.platform === 'WINDOWS') {
    if (ua.indexOf('Windows NT 5.1') !== -1) {
      result.version = '5.1';
      result.name = 'XP';
    } else if (ua.indexOf('Windows NT 5.2') !== -1) {
      result.version = '5.2';
      result.name = 'XP_64';
    } else if (ua.indexOf('Windows NT 6.0') !== -1) {
      result.version = '6.0';
      result.name = 'VISTA';
    } else if (ua.indexOf('Windows NT 6.1') !== -1) {
      result.version = '6.1';
      result.name = '7';
    } else if (ua.indexOf('Windows NT 6.2') !== -1) {
      result.version = '6.2';
      result.name = '8';
    } else if (ua.indexOf('Windows NT 6.3') !== -1) {
      result.version = '6.3';
      result.name = '8.1';
    } else if (ua.indexOf('Windows NT 6.4') !== -1) {
      result.version = '6.4';
      result.name = '10';
    } else if (ua.indexOf('Windows NT 10.0') !== -1) {
      result.version = '10.0';
      result.name = '10';
    } else if (ua.indexOf('Windows NT') !== -1) {
      // TODO
    } else {
      result.version = 'UNKNOWN';
      result.name = 'UNKNOWN';
    }

    if (ua.indexOf('WOW64') !== -1 || ua.indexOf('Win64') !== -1) result.bit = '64';
    else result.bit = '32';
  } else if (result.platform === 'MACOSX') {
    if ((ua.indexOf('Mac OS X 10_5') || ua.indexOf('Mac OS X 10.5')) !== -1) {
      result.version = '10.5';
      result.name = 'Leopard';
    } else if ((ua.indexOf('Mac OS X 10_6') || ua.indexOf('Mac OS X 10.6')) !== -1) {
      result.version = '10.6';
      result.name = 'Snow Leopard';
    } else if ((ua.indexOf('Mac OS X 10_7') || ua.indexOf('Mac OS X 10.7')) !== -1) {
      result.version = '10.7';
      result.name = 'Lion';
    } else if ((ua.indexOf('Mac OS X 10_8') || ua.indexOf('Mac OS X 10.8')) !== -1) {
      result.version = '10.8';
      result.name = 'Mountain Lion';
    } else if ((ua.indexOf('Mac OS X 10_9') || ua.indexOf('Mac OS X 10.9')) !== -1) {
      result.version = '10.9';
      result.name = 'Mavericks';
    } else if ((ua.indexOf('Mac OS X 10_10') || ua.indexOf('Mac OS X 10.10')) !== -1) {
      result.version = '10.10';
      result.name = 'Yosemite';
    } else if (ua.indexOf('Mac OS X 10') !== -1) {
      // TODO
    } else {
      result.version = 'UNKNOWN';
      result.name = 'UNKNOWN';
    }

    result.bit = '64';
  } else if (result.platform === 'LINUX') {
    // TODO
  } else {
    result.version = 'UNKNOWN';
    result.name = 'UNKNOWN';
  }

  return result;
}

export function GetBrowserInfo() {
  const tp = navigator.platform;
  const N = navigator.appName;
  const ua = navigator.userAgent;
  let tem;
  let M;

  // if opera
  M = ua.match(/(opera|opr)\/?\s*(\.?\d+(\.\d+)*)/i);
  // eslint-disable-next-line no-cond-assign
  if (M && (tem = ua.match(/version\/([\\.\d]+)/i)) != null) {
    // eslint-disable-next-line prefer-destructuring
    M[2] = tem[1];
  }
  M = M ? { browser: 'Opera', version: M[2] } : M;

  // Edge
  if (!M) {
    M = ua.match(/(edge)\/?\s*(\.?\d+(\.\d+)*)/i);
    // eslint-disable-next-line no-cond-assign
    if (M && (tem = ua.match(/version\/([\\.\d]+)/i)) != null) {
      // eslint-disable-next-line prefer-destructuring
      M[2] = tem[1];
    }
    M = M ? { browser: 'Edge', version: M[2] } : M;
  }

  if (!M) {
    M = ua.match(/(trident|chrome|safari|firefox)\/?\s*(\.?\d+(\.\d+)*)/i);
    if (M) {
      // eslint-disable-next-line no-cond-assign
      if ((tem = ua.match(/rv:([\d]+)/)) != null) {
        // eslint-disable-next-line prefer-destructuring
        M[2] = tem[1];
      }
      // eslint-disable-next-line no-cond-assign
      else if ((tem = ua.match(/version\/([\\.\d]+)/i)) != null) {
        // eslint-disable-next-line prefer-destructuring
        M[2] = tem[1];
      }
    }

    try {
      if (M[1] === 'Trident') M[1] = 'MSIE';
      M = M
        ? { browser: M[1], version: M[2] }
        : { browser: N, version1: navigator.appVersion, other: '-?' };

      if (M.version === '4.0') M.version = '8.0';
      else if (M.version === '5.0') M.version = '9.0';
      else if (M.version === '6.0') M.version = '10.0';
      else if (M.version === '7.0') M.version = '11.0';
    } catch (e) {
      // Err
    }
  }

  // if IE7 under
  if (!M) {
    M = ua.match(/MSIE ([67].\d+)/);
    if (M) M = { browser: 'MSIE', version: M[1] };
  }

  if (M.version) {
    const verArr = M.version.split('.');
    // eslint-disable-next-line prefer-destructuring
    M.version = verArr[0];
  }

  if (M.browser === 'MSIE') {
    if (tp === 'Win32') {
      M.bit = '32';
    } else if (tp === 'Win64') {
      M.bit = '64';
    }
  }

  return M;
}

export default async function CheckInstall(window, version) {
  const osInfo = GetOsInfo();
  const browserInfo = GetBrowserInfo();
  const browserName = browserInfo.browser.toLowerCase();
  const osName = osInfo.name.toLowerCase();

  if (
    osName !== 'xp' &&
    osName !== 'vista' &&
    osName !== '7' &&
    osName !== '8' &&
    osName !== '8.1' &&
    osName !== '10' &&
    osName === 'xp_64' &&
    osInfo.bit === '64'
  ) {
    return null;
  }

  let secureProtocol = 0;

  if (
    browserName === 'msie' ||
    browserName === 'chrome' ||
    browserName === 'firefox' ||
    browserName === 'opera'
  ) {
    secureProtocol = 'http';
    let mainPort = 2457; // [vmCube 수정] 변수선언부 누락 : 전역변수화 방지를 위해 수정
    let subPort = 4620; // [vmCube 수정] 변수선언부 누락 : 전역변수화 방지를 위해 수정

    if (window.location.href.indexOf('https://') !== -1) {
      secureProtocol = 'https';
      mainPort = 12457;
      subPort = 14620;
    }

    return [
      CheckVersionPort(secureProtocol, mainPort, version),
      CheckVersionPort(secureProtocol, subPort, version),
    ];
  }
}

import { Image, Space, Typography } from 'antd';
import React, { useContext, useState, useEffect } from 'react';
import ThemeContext from 'store/ThemeContext';
import { AiOutlinePhone } from 'react-icons/ai';

/**
 * 앱 Copyright
 * @param {object} styles css
 * @returns ReactNode
 */
function AppCopyright({ styles }) {
  const [customCopyright, setCustomCopyright] = useState(null);
  const theme = useContext(ThemeContext);
  const { type } = theme.copyright;

  // 만약 커스텀된 copyright이 image면 리소스를 가져온다.
  // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
  useEffect(() => {
    if (type === 'image') {
      (async () => {
        const resource = await import(`images/${theme.copyright.value}`);
        setCustomCopyright(resource.default);
      })();
    }
  }, []);

  if (type === 'string') {
    return (
      <Typography.Paragraph type="secondary" style={styles}>
        {theme.copyright.value}
      </Typography.Paragraph>
    );
  }
  if (type === 'image') {
    return <Image src={customCopyright} preview={false} style={styles} />;
  }
  // TODO: #189874 임시 조치, 향후 setting.js 제거할 때 확인 필요
  if (type === 'meritz') {
    return (
      <Space size={4} style={{ textAlign: 'left', fontSize: 11, alignItems: 'self-end' }}>
        <AiOutlinePhone style={{ fontSize: 24 }} />
        <div>
          <Typography.Text strong>가상 데스크탑 문의 사항</Typography.Text>
          <div>&gt; T. 6454-4848(HelpDesk전화번호)</div>
        </div>
      </Space>
    );
  }
  return null;
}

export default AppCopyright;

import { Alert, Button, Checkbox, Col, Form, Input, Modal, Row, Space } from 'antd';
import { AppCopyright, AppLogo, Icon } from 'components';
import LocaleContext from 'locales';
import { LocaleChanger } from 'pages/components';
import { DownloadTable } from 'pages/help/Download';
import { localeKeyToString } from 'pages/utils';
import React, { useContext, useEffect, useState } from 'react';
import { AiOutlineLock, AiOutlineUser } from 'react-icons/ai';
import { accountLogin, getBioAuthUser } from 'services/websocket/auth';

// ? why not using websocket api?
// ? 로그인 페이지가 뜰 때 호출되는 api는 rest로 날린다.
// ? 그 이유는 웹소켓 연결이 계속 지속되기 때문이다...
import { encryptRSA, getCustomRedirect, getRSAKey } from 'services/auth';
import { startSessionTimer } from 'services/sessionTimer';
import ConfigContext from 'store/ConfigContext';
import LoginMenuContext from 'store/LoginMenuContext';
import ThemeContext from 'store/ThemeContext';
import styled from 'styled-components';
import moment from 'moment';

import packageJson from '../../../package.json';
import copilot from '../../images/copilot.png';
import meritzImg01 from '../../images/meritz_img01.png';
import meritzImg02 from '../../images/meritz_img02.png';
import ResetPassword from './ResetPassword';

const styles = {
  formHeight: {
    height: 40,
  },
};

const StyledBackground = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .loginBox {
    margin: 0 auto;
    padding: 40px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.07), 0 3px 4px -1px rgba(0, 0, 0, 0.07),
      0 4px 9px 2px rgba(0, 0, 0, 0.07);
  }
`;

const StyledBox = styled.div`
  margin: 0 auto;
  width: 368px;
  padding: 40px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.07), 0 3px 4px -1px rgba(0, 0, 0, 0.07),
    0 4px 9px 2px rgba(0, 0, 0, 0.07);
`;

function Login({ history, location, config: backConf }) {
  // states
  const [error, setError] = useState(undefined);
  const [customUrls, setCustomUrls] = useState();
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);
  const [serviceGuideLink, setServiceGuideLink] = useState(null);
  const [checked, setChecked] = useState(false);
  const [credential, setCredential] = useState({ success: false }); // {success(인증 성공 여부), idpw(id/pw 인증 정보), twoFactor}
  const [btnLoading, setBtnLoading] = useState(false);
  const [encryptKey, setEncryptKey] = useState();
  const [isBioAuthUser, setIsBioAuthUser] = useState(undefined); // 생체 인증 대상자 여부
  const [resetPwdProps, setResetPwdProps] = useState({ visible: false }); // 비밀번호 변경 컴포넌트 props

  // refs
  const [loginForm] = Form.useForm();

  // contexts
  const { locale } = useContext(LocaleContext);
  const hideMenus = useContext(LoginMenuContext);
  const theme = useContext(ThemeContext);
  const config = useContext(ConfigContext);
  const { LoginExtraForms, passwordReset: customPasswordReset, MultiFactor } = config;

  // ! 하나은행 생체인증 커스텀
  // ! PreCheck 페이지에서 생체인증 과정에서 에러가 발생하면 location.message로 에러를 전송한다.
  // * message가 없이 온 경우라면 precheck가 완료된지 여부에 따라 동작
  // * precheck이 돌지 않았는데 로그인페이지로 온 경우는 (사용자가 url에 x.x.x.x/portal/auth/login을 직접 입력한경우) Precheck으로 보낸다.
  useEffect(() => {
    // preCheck 페이지를 거쳐서 온 것이 아니라면 prechecked로 보낸다.
    if (config.preCheck && !location.preChecked && sessionStorage.getItem('logout') !== 'true') {
      history.push({
        pathname: '/auth/pre-check',
        redirectUrlToVd: location?.redirectUrlToVd,
      });

      return;
    }

    if (location.message) {
      setError(localeKeyToString(location.message));
      if (location.message === 'txt.check-required-program') {
        setDownloadModalVisible(true);
      }
    }

    if (backConf?.features?.bioAuth?.use) {
      (async () => {
        const res = await getBioAuthUser(localStorage.getItem('userId'));

        if (res.success) {
          const { registeredQr, registeredOtp, registeredFace, registeredPattern } = res.data?.user;
          setIsBioAuthUser(registeredQr || registeredOtp || registeredFace || registeredPattern);
        } else {
          setIsBioAuthUser(undefined);
        }
      })();
    }

    // #190291
    if (config.autoLogin && packageJson.config?.preid?.startsWith('hanabank')) {
      setChecked(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      const resource = await import(`download/${theme.serviceGuide.link}`);
      setServiceGuideLink(resource.default);
    })();

    getCustomRedirect().then((customUrl) => setCustomUrls(customUrl));
    getRSAKey().then(({ data }) => setEncryptKey(data.key));

    const userId = localStorage.getItem('userId');
    const isSaveId = localStorage.getItem('saveId') === 'true';

    if (isSaveId && userId) {
      loginForm.setFieldsValue({ userId, saveId: true });
    }
  }, []);

  /**
   * 커스텀 Url 열기
   * @param {*} url 대상 url
   * @return window.open
   */
  const openCustomUrl = (url) => {
    const windowFeatures =
      theme.customUrls?.passwordReset?.windowFeatures ||
      `height=${window.screen.height},width=${window.screen.width},resizable=yes,fullscreen=yes`;
    window.open(url, '', windowFeatures);
  };

  // 로그인 submit
  const onFinish = async (values) => {
    setBtnLoading(true);
    setError(undefined); // sumit 할때마다 error를 clear 한다.

    const saveId = values.saveId ?? false; // ID 저장이 undefined인 경우 false로 설정한다.
    const password1 = encryptRSA(values.password, encryptKey);
    const res = await accountLogin({ ...values, autoLogin: checked, password: password1 });

    // res.twoFactor.type: 'require' | 'require-secret-key' | 'unnecessary' | 'unknown' | 'error'
    if (res.result) {
      if (res.twoFactor.type === 'error' || res.twoFactor.type === 'unknown') {
        setError(locale.resource[res.twoFactor.msg]);
      } else if (res.twoFactor.type === 'require' || res.twoFactor.type === 'require-secret-key') {
        setCredential({
          success: false,
          idpw: {
            userId: res.userId,
            password: password1,
            username: res.username,
            autoLogin: checked,
            autoConnect: true,
            redirectComplete: false,
            // 토큰 없음
          },
          twoFactor: res.twoFactor,
        });
      } else {
        setCredential({
          success: true,
          idpw: {
            userId: res.userId,
            username: res.username,
            autoLogin: checked,
            autoConnect: true,
            redirectComplete: false,
            token: res.token,
            saveId,
          },
        });
      }
    } else if (res.ldapCode === 773 || res.ldapCode === 532) {
      if (
        backConf?.features?.bioAuth?.use &&
        packageJson.config?.preid?.startsWith('hanabank') &&
        isBioAuthUser
      ) {
        openCustomUrl('http://asi.infohana.com:8082');
      } else {
        setResetPwdProps({ visible: true, userId: res.userId, msg: res.msg });
      }
    } else {
      setError(localeKeyToString(res.msg));
    }
    setBtnLoading(false);
  };

  useEffect(() => {
    // 로그인 하기
    if (credential.success) {
      const { idpw } = credential;
      if (!config.saveId) delete idpw.saveId;
      Object.entries(idpw).forEach(([key, value]) => localStorage.setItem(key, value));
      startSessionTimer();

      sessionStorage.removeItem('logout');
      history.push({
        pathname: '/auth/requiredApps',
        redirectUrlToVd: location?.redirectUrlToVd,
      });
    }
  }, [credential]);

  /**
   * 비밀번호 초기화하기
   *   방법 1. InputForResetPWD(자체): 입력받은 아이디의 이메일로 초기화 URL을 보내 처리
   *   방법 2. windowOpen(외부): 환경설정 > 기능 > Portal 내 링크 커스터마이징 > 비밀번호 초기화 url을 연다.
   * @returns ReactNode || window.open
   */
  const doPasswordReset = async () => {
    let externalUrl = customUrls?.passwordReset || undefined; // 비밀번호 초기화 url
    // #186818 생체 인증을 사용하는 경우 생체인증 서버에 인증 대상인지 질의한다.
    if (backConf?.features?.bioAuth?.use && packageJson.config?.preid?.startsWith('hanabank')) {
      if (isBioAuthUser === undefined) {
        return Modal.error({
          title: locale.resource['txt.operation-failed'],
          content: locale.resource['txt.bio-auth-msg.conn-refuesed'],
        });
      }
      if (isBioAuthUser) {
        externalUrl = 'http://asi.infohana.com:8082';
      }
    }
    if (customPasswordReset) {
      return customPasswordReset({ locale, customUrls });
    }
    return externalUrl ? openCustomUrl(externalUrl) : setResetPwdProps({ visible: true });
  };

  // TODO 로그인페이지 로직 분리시 제거
  return config.siteName !== 'meritz' ? (
    <StyledBackground>
      <div style={{ position: 'absolute', top: '1em', right: '1em' }}>
        <LocaleChanger />
      </div>
      <StyledBox style={config.loginBoxStyles}>
        <Row gutter={[0, 32]}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <AppLogo />
          </Col>
          <Col span={24}>
            {/* startsWith('require') = require | require-secret-key */}
            {Object.prototype.hasOwnProperty.call(credential, 'twoFactor') &&
            credential.twoFactor.type.startsWith('require') ? (
              <MultiFactor credential={credential} setCredential={setCredential} />
            ) : (
              <>
                {error && (
                  <Alert
                    type="error"
                    showIcon
                    style={{ marginBottom: 24 }}
                    message={<div style={{ whiteSpace: 'pre-line' }}>{error}</div>}
                  />
                )}
                <Form onFinish={onFinish} layout="vertical" form={loginForm}>
                  <Form.Item
                    name="userId"
                    rules={[{ required: true, message: locale.resource['txt.enter-id'] }]}
                  >
                    <Input
                      prefix={<Icon name={<AiOutlineUser focusable={false} />} />}
                      placeholder={locale.resource['txt.login-id']}
                      style={styles.formHeight}
                      autoFocus
                      autoComplete="username"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: locale.resource['txt.enter-password'],
                      },
                    ]}
                  >
                    <Input.Password
                      visibilityToggle={false}
                      allowClear
                      prefix={<Icon name={<AiOutlineLock focusable={false} />} />}
                      placeholder={locale.resource['txt.login-password']}
                      style={styles.formHeight}
                      autoComplete="current-password"
                    />
                  </Form.Item>
                  {LoginExtraForms && <LoginExtraForms />}
                  <div style={{ marginBlock: 16 }}>
                    {
                      // 자동로그인 옵션을 사용하고, 생체인증 사용자가 로그아웃한 경우가 아닌 경우에 자동로그인 체크박스 출력
                      config.autoLogin &&
                        !(
                          sessionStorage.getItem('logout') === 'true' &&
                          localStorage.getItem('bioAuth') === 'true'
                        ) && (
                          <Form.Item name="autoLogin" noStyle>
                            <Checkbox
                              checked={checked}
                              onChange={(e) => setChecked(e.target.checked)}
                            >
                              {locale.resource['txt.auto-login']}
                            </Checkbox>
                          </Form.Item>
                        )
                    }
                    {config.saveId && (
                      <Form.Item name="saveId" noStyle valuePropName="checked" initialValue={false}>
                        <Checkbox>{locale.resource['txt.save-id']}</Checkbox>
                      </Form.Item>
                    )}
                  </div>
                  <Form.Item style={{ marginTop: 24 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      style={styles.formHeight}
                      loading={btnLoading}
                    >
                      LOGIN
                    </Button>
                  </Form.Item>
                </Form>
              </>
            )}
          </Col>
          {!packageJson.config?.preid?.startsWith('hanabank') ? (
            <Col span={24} style={{ textAlign: 'center' }}>
              <Row gutter={[0, 8]}>
                {packageJson.config?.preid?.startsWith('kiat') &&
                  // 산기원 > 2단계 인증에서는 패스워드 안내 문구를 표시하지 않는다.
                  !(
                    Object.prototype.hasOwnProperty.call(credential, 'twoFactor') &&
                    credential.twoFactor?.type === 'require'
                  ) && (
                    <Alert
                      style={{ whiteSpace: 'pre-line', textAlign: 'left' }}
                      type="info"
                      message={locale.resource['txt.kiat-login-notice']}
                    />
                  )}
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Space>
                    {!hideMenus.includes('signup') && customUrls?.signUp && (
                      <Button
                        type="link"
                        onClick={() => {
                          window.open(
                            customUrls?.signUp,
                            '',
                            `${
                              theme.customUrls?.signUp?.windowFeatures !== undefined
                                ? theme.customUrls.signUp.windowFeatures
                                : `height=${window.screen.height},width=${window.screen.width},resizable=yes,fullscreen=yes`
                            }`,
                          );
                        }}
                      >
                        {locale.resource['txt.register-id']}
                      </Button>
                    )}
                    <div>
                      <Button type="link" style={{ padding: 0 }} onClick={doPasswordReset}>
                        {locale.resource['config.features.custom-redirect.password-reset']}
                      </Button>
                      <ResetPassword
                        resetPwdProps={resetPwdProps}
                        setResetPwdProps={setResetPwdProps}
                      />
                    </div>
                  </Space>
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Space>
                    {!hideMenus.includes('guide') && (
                      <a href={serviceGuideLink} download className="ant-btn">
                        {locale.resource[theme.serviceGuide.text]}
                      </a>
                    )}
                    {!hideMenus.includes('fileDownload') && (
                      <>
                        <Button onClick={() => setDownloadModalVisible(true)}>
                          {locale.resource['txt.download-client']}
                        </Button>
                        <Modal
                          title={locale.resource['txt.download-client']}
                          centered
                          width={720}
                          visible={downloadModalVisible}
                          onOk={() => setDownloadModalVisible(false)}
                          onCancel={() => setDownloadModalVisible(false)}
                          destroyOnClose
                          footer={[
                            <Button key="ok" onClick={() => setDownloadModalVisible(false)}>
                              {locale.resource['txt.ok']}
                            </Button>,
                          ]}
                        >
                          <DownloadTable size="small" locale={locale} />
                        </Modal>
                      </>
                    )}
                  </Space>
                </Col>
              </Row>
            </Col>
          ) : (
            // #190292 [하나은행]포탈 로그인페이지 UI 수정 요청의 건
            <Col
              span={24}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <div>
                <Button type="link" style={{ padding: 0 }} onClick={doPasswordReset}>
                  {locale.resource['config.features.custom-redirect.password-reset']}
                </Button>
                <ResetPassword resetPwdProps={resetPwdProps} setResetPwdProps={setResetPwdProps} />
              </div>
              <div>
                <Button onClick={() => setDownloadModalVisible(true)}>
                  {locale.resource['txt.download-client']}
                </Button>
                <Modal
                  title={locale.resource['txt.download-client']}
                  centered
                  width={720}
                  visible={downloadModalVisible}
                  onOk={() => setDownloadModalVisible(false)}
                  onCancel={() => setDownloadModalVisible(false)}
                  destroyOnClose
                  footer={[
                    <Button key="ok" onClick={() => setDownloadModalVisible(false)}>
                      {locale.resource['txt.ok']}
                    </Button>,
                  ]}
                >
                  <DownloadTable size="small" locale={locale} />
                </Modal>
              </div>
            </Col>
          )}
          {config.siteName === 'lgeCWC' && (
            <Col span={24} style={{ textAlign: 'center' }}>
              <img src={copilot} alt="copilot" style={{ width: 32 }} />
            </Col>
          )}
        </Row>
      </StyledBox>
      <div style={{ marginTop: 16 }}>
        <AppCopyright />
      </div>
    </StyledBackground>
  ) : (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        background: '#fcf9f9',
        paddingBottom: 20,
      }}
    >
      <div style={{ position: 'absolute', top: '1em', right: '1em' }}>
        <LocaleChanger />
      </div>
      {moment().isBefore('2024-04-01') && (
        <img src={meritzImg02} alt="망고2 오픈" style={{ marginBottom: 36 }} />
      )}
      <div
        style={{
          margin: '0 auto',
          width: 1000,
          borderRadius: 16,
          backgroundColor: '#ffffff',
          overflow: 'hidden',
          boxShadow:
            '0 1px 1px 1px rgba(0, 0, 0, 0.07), 0 3px 4px -1px rgba(0, 0, 0, 0.07), 0 4px 9px 2px rgba(0, 0, 0, 0.07)',
        }}
      >
        <Row style={{ height: 480, alignItems: 'stretch' }}>
          <Col flex="448px" style={{ padding: '60px 80px 0' }}>
            <Row gutter={[0, 0]}>
              <Col span={24} style={{ paddingBottom: 30 }}>
                <AppLogo />
              </Col>
              <Col span={24}>
                {/* startsWith('require') = require | require-secret-key */}
                {Object.prototype.hasOwnProperty.call(credential, 'twoFactor') &&
                credential.twoFactor.type.startsWith('require') ? (
                  <MultiFactor credential={credential} setCredential={setCredential} />
                ) : (
                  <>
                    {error && (
                      <Alert
                        type="error"
                        showIcon
                        style={{ marginBottom: 16 }}
                        message={<div style={{ whiteSpace: 'pre-line' }}>{error}</div>}
                      />
                    )}
                    <Form onFinish={onFinish} layout="vertical" form={loginForm}>
                      <Form.Item
                        name="userId"
                        rules={[{ required: true, message: locale.resource['txt.enter-id'] }]}
                      >
                        <Input
                          prefix={<Icon name={<AiOutlineUser focusable={false} />} />}
                          placeholder={locale.resource['txt.login-id']}
                          style={styles.formHeight}
                          autoFocus
                          autoComplete="username"
                        />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: locale.resource['txt.enter-password'],
                          },
                        ]}
                      >
                        <Input.Password
                          visibilityToggle={false}
                          allowClear
                          prefix={<Icon name={<AiOutlineLock focusable={false} />} />}
                          placeholder={locale.resource['txt.login-password']}
                          style={styles.formHeight}
                          autoComplete="current-password"
                        />
                      </Form.Item>
                      {LoginExtraForms && <LoginExtraForms />}
                      <div style={{ marginBlock: 16 }}>
                        {config.autoLogin && (
                          <Form.Item name="autoLogin" noStyle>
                            <Checkbox
                              checked={checked}
                              onChange={(e) => setChecked(e.target.checked)}
                            >
                              {locale.resource['txt.auto-login']}
                            </Checkbox>
                          </Form.Item>
                        )}
                        {config.saveId && (
                          <Form.Item
                            name="saveId"
                            noStyle
                            valuePropName="checked"
                            initialValue={false}
                          >
                            <Checkbox>{locale.resource['txt.save-id']}</Checkbox>
                          </Form.Item>
                        )}
                      </div>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          block
                          style={styles.formHeight}
                          loading={btnLoading}
                        >
                          LOGIN
                        </Button>
                      </Form.Item>
                    </Form>
                  </>
                )}
              </Col>
              <Col span={24} style={{ paddingTop: 10 }}>
                <Row gutter={[0, 8]}>
                  <Col span={24}>
                    <Space>
                      {!hideMenus.includes('signup') && customUrls?.signUp && (
                        <Button
                          type="link"
                          onClick={() => {
                            window.open(
                              customUrls?.signUp,
                              '',
                              `${
                                theme.customUrls?.signUp?.windowFeatures !== undefined
                                  ? theme.customUrls.signUp.windowFeatures
                                  : `height=${window.screen.height},width=${window.screen.width},resizable=yes,fullscreen=yes`
                              }`,
                            );
                          }}
                        >
                          {locale.resource['txt.register-id']}
                        </Button>
                      )}
                      <div>
                        <Button type="link" style={{ padding: 0 }} onClick={doPasswordReset}>
                          {locale.resource['config.features.custom-redirect.password-reset']}
                        </Button>
                        <ResetPassword
                          resetPwdProps={resetPwdProps}
                          setResetPwdProps={setResetPwdProps}
                        />
                      </div>
                    </Space>
                  </Col>
                  <Col span={24}>
                    <Space>
                      {!hideMenus.includes('guide') && (
                        <a href={serviceGuideLink} download className="ant-btn">
                          {locale.resource[theme.serviceGuide.text]}
                        </a>
                      )}
                      {!hideMenus.includes('fileDownload') && (
                        <>
                          <Button onClick={() => setDownloadModalVisible(true)}>
                            {locale.resource['txt.download-client']}
                          </Button>
                          <Modal
                            title={locale.resource['txt.download-client']}
                            centered
                            width={720}
                            visible={downloadModalVisible}
                            onOk={() => setDownloadModalVisible(false)}
                            onCancel={() => setDownloadModalVisible(false)}
                            footer={[
                              <Button key="ok" onClick={() => setDownloadModalVisible(false)}>
                                {locale.resource['txt.ok']}
                              </Button>,
                            ]}
                          >
                            <DownloadTable size="small" locale={locale} />
                          </Modal>
                        </>
                      )}
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col
            flex="auto"
            style={{
              backgroundColor: '#ebf5fc',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img src={meritzImg01} alt="VD-i 이미지" />
          </Col>
        </Row>
      </div>
      <div style={{ marginTop: 16 }}>
        <AppCopyright />
      </div>
    </div>
  );
}

export default Login;

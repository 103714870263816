import { Modal, Table } from 'antd';
import LocaleContext from 'locales';
import React, { useContext } from 'react';

/**
 * VD 접속 기록 테이블
 * @param {boolean} visible modal visible
 * @param {function} dispatch handler modal visible
 * @param {object[]} dataSource
 * @returns ReactNode
 */
function ConnHistoryTable({ visible, dispatch, dataSource }) {
  const { locale } = useContext(LocaleContext);
  const onClose = () => dispatch({ type: 'set-visibleConnHistory', payload: false });
  return (
    <Modal
      title={locale.resource['txt.last-connection-history']}
      visible={visible}
      width={840}
      onOk={onClose}
      onCancel={onClose}
      cancelButtonProps={{ style: { display: 'none' } }}
      destroyOnClose
    >
      <Table
        size="small"
        rowKey="sessionId"
        dataSource={dataSource}
        columns={[
          { title: locale.resource['txt.connected-date'], dataIndex: 'loginDate', width: 140 },
          { title: locale.resource['txt.disconnected-date'], dataIndex: 'logoutDate', width: 140 },
          { title: locale.resource['txt.adAccount'], dataIndex: 'adAccount' },
          { title: locale.resource['txt.vdId'], dataIndex: 'vdId', width: 268 },
          { title: locale.resource['txt.source-ip'], dataIndex: 'ip' },
        ]}
      />
    </Modal>
  );
}

export default ConnHistoryTable;

/**
 * 아이콘은 react-icons 패키지를 사용하고 있지만,
 * antd의 아이콘 스타일을 승계하기 위해 class="anticon"이 필요함
 * 따라서, <Icon /> 컴포넌트를 통해 이를 해결
 * 이는 react-icons에서 가져오는 아이콘은 항상 <Icon />으로 감싸야 함을 의미함
 * 추가로 아이콘에 size, color등의 스타일을 지정하는 방법은 임포트한 아이콘( = {name})에서 정의하면 됨
 * TO-DO
 *   1. 모든 컴포넌트가 class="anticon" 는 아님, 드롭다운에서는 class="ant-dropdown-menu-item-icon" 등 상황에 따라 다름
 */

import React from 'react';
import { IconContext } from 'react-icons';

export default function Icon(props) {
  const { name } = props;
  return <IconContext.Provider value={{ className: 'anticon' }}>{name}</IconContext.Provider>;
}

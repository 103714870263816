import { Modal } from 'antd';
import LocaleContext from 'locales';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { checkAccount, deleteToken } from 'services/websocket/auth';
import { getAvailableVdGroups } from 'services/websocket/mgmt';
import { assignVd, unassignVds } from 'services/websocket/session';

import * as VsclientService from 'services/vsclient';
import {
  connectByVdStart,
  getXauthFromCookie,
  isWindows,
  startAgent,
  vsClagentPortCheck,
} from './utils';

function URLRedirectPage({ history, location }) {
  const redirectUrl = decodeURIComponent(location?.search?.split('?url=')[1]);
  const { locale } = useContext(LocaleContext);

  const redirect = async (vdGroup, token, isUnassigned) => {
    // 윈도우가 아니면 url 리다이렉션 기능을 제공하지 않는다.
    if (!isWindows) {
      return;
    }

    const searchParam = {
      'conn-req-url': `${window.location.protocol}//${window.location.host}/portal/api/session/vds/${vdGroup.vdId}/connection`,
      'conn-token': token,
      userId: localStorage.getItem('userId'),
      userName: localStorage.getItem('username'),
      adAccount: vdGroup.adAccount || null,
      vdIp: vdGroup.ipAddress || null,
      vdId: vdGroup.vdId || null, // 내정보의 자동 접속는 VD를 할당받지 않은 VD 그룹일 수 있음
      vdGroupType: vdGroup.groupType,
      vdGroupId: vdGroup.id || vdGroup.groupId,
      vdGroupName: vdGroup.vdGroupName || vdGroup.groupName,
      'redirect-url': redirectUrl,
    };

    // vsclient에게 넘겨줄 args
    const args = new URLSearchParams(searchParam).toString();

    try {
      await vsClagentPortCheck();
      window.location = `http://localhost:${localStorage.getItem(
        'clagentPort',
      )}/vsclient/redirect-url?args=${btoa(args)}`;
    } catch (error) {
      await startAgent(10).then((result) => {
        if (result) {
          window.location = `http://localhost:${localStorage.getItem(
            'clagentPort',
          )}/vsclient/redirect-url?args=${btoa(args)}`;
        } else {
          Modal.warning({
            title: locale.resource['task-result.state.check'],
            content: locale.resource['txt.retry-after-vsclagent-check'],
          });
        }

        // clagent가 실행되지 못했으니 할당 해제를 요청한다.
        if (isUnassigned) {
          unassignVds({ vdIds: [vdGroup?.vdId] });
        }
      });
    }
  };

  const redirectWithoutToken = async () => {
    // 윈도우가 아니면 url 리다이렉션 기능을 제공하지 않는다.
    if (!isWindows) {
      return;
    }

    const searchParam = {
      'redirect-url': redirectUrl,
    };
    // vsclient에게 넘겨줄 args
    const args = new URLSearchParams(searchParam).toString();
    try {
      await vsClagentPortCheck();
      window.location = `http://localhost:${localStorage.getItem(
        'clagentPort',
      )}/vsclient/redirect-url?args=${btoa(args)}`;
    } catch (error) {
      await startAgent(10).then((result) => {
        if (result) {
          window.location = `http://localhost:${localStorage.getItem(
            'clagentPort',
          )}/vsclient/redirect-url?args=${btoa(args)}`;
        } else {
          Modal.warning({
            title: locale.resource['task-result.state.check'],
            content: locale.resource['txt.retry-after-vsclagent-check'],
          });
        }
      });
    }
  };

  useEffect(async () => {
    // 윈도우가 아니면 url 리다이렉션 기능을 제공하지 않는다.
    if (!isWindows) {
      return;
    }
    if (_.isEmpty(locale.resource)) {
      return;
    }

    await vsClagentPortCheck();

    const getRedirectVd = async (token) => {
      const res = await getAvailableVdGroups();
      // 할당된 VD가 없을 때
      if (res.status !== 'error' && res.data.length === 0) {
        Modal.warning({
          title: locale.resource['txt.fail-url-redirection'],
          content: locale.resource['txt.no-available-desktops'],
        });
        return;
      }

      // 그 외 에러 발생 시
      if (res.status === 'error') {
        Modal.warning({
          title: locale.resource['txt.fail-url-redirection'],
          content: res.errorMsg,
        });
        return;
      }
      // 우선 순위는 자동접속VD > 자동접속그룹이다.
      let index = res.data.findIndex((d) => d.autoConnectVd === 1);

      if (index === -1) {
        index = res.data.findIndex((d) => d.autoConnectGroup === 1);
      }

      if (index === -1) {
        index = 0;
      }

      // 접속 불가 IP인 경우
      if (!res.data[index].isAccessibleIp) {
        Modal.error({
          title: locale.resource['txt.fail-url-redirection'],
          content: locale.resource['txt.inaccessible-ip-msg'],
        });
        return;
      }

      if (res.data[index].state.endsWith('.state.stopped')) {
        connectByVdStart(res.data[index], moment().add(3, 'm'), (groupInfo) =>
          redirect(groupInfo, token),
        );
      } else if (!res.data[index].adAccount) {
        // VD에 할당되어있지 않은 경우
        // 1. 할당해라
        // 2. 접속해라
        const vd = await assignVd({ vdGroupId: res.data[index].id, vdId: res.data[index].vdId });
        const isUnassigned = true;
        // eslint-disable-next-line no-unused-expressions
        vd.state.endsWith('stopped')
          ? connectByVdStart(vd, moment().add(3, 'm'), (groupInfo) => redirect(groupInfo, token))
          : redirect(vd, token, isUnassigned);
      } else {
        redirect(res.data[index], token);
      }
    };

    // ! FIXME: websocket기반이 되면서 getXauthFromCookie는 필요없어짐 하지만 없애면 기존 사용자들이
    // !        자동 로그인이 풀리기 때문에 토큰을 가져와야함
    // !        추후 해당 형상이 어느정도 안정화 운영이 된다면 cookie에 token이 존재하지 않으니 없애도된다.
    const token = localStorage.getItem('token') || getXauthFromCookie();

    // 토큰이 없거나 토큰이 있더라도 자동로그인으로 만들어진 토큰이 아닌 경우에는 정상적인 토큰이 아니다.
    if (!token || (token && localStorage.getItem('autoLogin') !== 'true')) {
      localStorage.removeItem(token);

      // (포탈 세션 만료 && VD 실행 중) 인 상황에서 url 리디렉션을 시도하면
      // 클라이언트가 떠 있으면 url 리디렉션을 수행하고 클라이언트가 떠있지 않으면 로그인 페이지로 이동한다.
      if (isWindows) {
        try {
          const result = await VsclientService.isRunning();
          if (result.data === true) {
            await redirectWithoutToken();
            return;
          }
        } catch (e) {
          // 아무일 안함
        }
      }

      history.push({
        pathname: '/auth/login',
        redirectUrlToVd: redirectUrl,
      });
    } else {
      // 토큰은 존재하나 패스워드가 변경되어 인증에 실패한 경우를 처리
      try {
        await checkAccount(token);

        // ! FIXME: websocket기반이 되면서 getXauthFromCookie는 필요없어짐 하지만 없애면 기존 사용자들이 자동 로그인이 풀리기 때문에 토큰을 가져와야함
        if (!localStorage.getItem('token')) {
          // ! 추후 해당 형상이 어느정도 안정화 운영이 된다면 cookie에 token이 존재하지 않으니 없애도된다.
          localStorage.setItem('token', token);
        }
      } catch (e) {
        Modal.warning({
          title: locale.resource['txt.auth-fail'],
          content: locale.resource['txt.session-expired-or-pwd-change'],
          onOk: async () => {
            await deleteToken();
            history.push({
              pathname: '/auth/login',
              redirectUrlToVd: redirectUrl,
            });
          },
        });
        return;
      }

      await getRedirectVd(token);
    }
  }, [locale.resource]);

  // eslint-disable-next-line react/react-in-jsx-scope
  return <></>;
}

export default URLRedirectPage;

import { Button, Col, Layout, Row, Spin, Table, Typography, Modal } from 'antd';
import LocaleContext from 'locales';
import _ from 'lodash';
import React, { useContext, useEffect } from 'react';
import { getRequieredProgram } from 'services//websocket/session';
import { PromiseAllSettled } from 'utils/Polyfills';
import ReqAppConfig from '../config/required_app.config';
import { isWindows, startAgent, vsClagentPortCheck } from '../utils';

function Download() {
  const { locale } = useContext(LocaleContext);
  return (
    <Layout.Content>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Typography.Paragraph>
            {locale.resource['txt.install-required-programs']}
          </Typography.Paragraph>
        </Col>
        <Col span={24}>
          <DownloadTable locale={locale} />
        </Col>
      </Row>
    </Layout.Content>
  );
}

export function DownloadTable({ onCheckProgram, ...res }) {
  const { locale } = useContext(LocaleContext);
  const [dataSource, setDataSource] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    if (_.isEmpty(locale.resource)) {
      return;
    }

    setLoading(true);

    // mgmt로 부터 현재 확인해야하는 프로그램 목록을 가져온다.
    const loadingFunc = async () => {
      if (isWindows) {
        const portCheck = await vsClagentPortCheck();
        if (!portCheck) {
          Modal.warning({
            title: locale.resource['task-result.state.check'],
            content: locale.resource['txt.retry-after-vsclagent-check'],
          });
        }
      }
      const programs = await getRequieredProgram();
      return programs;
    };

    loadingFunc().then((programs) => {
      const dataLoading = Object.entries(ReqAppConfig.requiredProgram(programs)).map(
        async ([k, v]) => {
          const state = v.onInstallCheck
            ? (await PromiseAllSettled(await v.onInstallCheck(window)))
                .map((result) => result.value)
                .some(Boolean)
            : true;
          return {
            key: k,
            ...v,
            title: locale.resource[v.title],
            description: locale.resource[v.description],
            state,
          };
        },
      );

      PromiseAllSettled(dataLoading).then((data) => {
        setDataSource(data.map((d) => d.value));
        setLoading(false);
        onCheckProgram?.(data.map((d) => d.value.state).every(Boolean));
      });
    });
  }, [locale.resource]);

  const columns = [
    {
      title: locale.resource['txt.program-name'],
      dataIndex: 'title',
      width: 140,
    },
    {
      title: locale.resource['txt.description'],
      dataIndex: 'description',
    },
    {
      title: locale.resource['txt.status'],
      dataIndex: 'state',
      render: (text, record) => {
        const agentChk =
          record.key === 'vsclient' ? (
            <>
              <Typography.Paragraph>
                {locale.resource['txt.unknown']}
                <Typography.Text type="secondary">
                  ({locale.resource['txt.installed-already-run-program']})
                </Typography.Text>
              </Typography.Paragraph>
              {isWindows && (
                <Button
                  size="small"
                  onClick={(e) => {
                    if (e) {
                      startAgent(2).then(() => {
                        window.location.reload();
                      });
                    }
                  }}
                >
                  {locale.resource['txt.run-agent']}
                </Button>
              )}
            </>
          ) : (
            locale.resource['txt.not-installed']
          );
        const stateMap = {
          true: locale.resource['txt.installed'],
          false: agentChk,
        };
        return stateMap[text];
      },
    },
    {
      title: locale.resource['txt.download'],
      dataIndex: 'downloadPath',
      render: (path) => (
        <Button target="_blank" href={path}>
          {locale.resource['txt.download']}
        </Button>
      ),
    },
  ];

  return (
    <Spin spinning={loading}>
      <Table
        rowKey="key"
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...res}
      />
    </Spin>
  );
}

export default Download;

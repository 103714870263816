import { Button, Layout, Modal, Space, Table, Tag } from 'antd';
import { LinkButton } from 'components';
import DesktopCreate from 'pages/Request/DesktopCreate';
import DesktopDelete from 'pages/Request/DesktopDelete';
import DesktopReinstall from 'pages/Request/DesktopReinstall';
import DesktopUpdate from 'pages/Request/DesktopUpdate';
import React from 'react';

function Approval() {
  const [detail, setDetail] = React.useState(undefined);

  const onCancel = () => {
    setDetail(undefined);
  };

  return (
    <Layout.Content>
      <Table
        title={() => (
          <Space>
            <Button>승인</Button>
            <Button>반려</Button>
          </Space>
        )}
        size="small"
        rowKey="key"
        dataSource={[
          {
            key: 1,
            data1: 'Desktop 신규 생성',
            data2: '강서현',
            data3: '장수빈',
            data4: '2021-06-24 07:20:52',
            data5: '승인',
          },
          {
            key: 2,
            data1: 'Desktop 신규 생성',
            data2: '강은주',
            data3: '홍현준',
            data4: '2021-12-25 05:58:21',
            data5: '대기',
          },
          {
            key: 3,
            data1: 'Desktop 폐기',
            data2: '홍윤서',
            data3: '신민준',
            data4: '2021-05-31 13:55:12',
            data5: '반려',
          },
          {
            key: 4,
            data1: 'Desktop 폐기',
            data2: '오서연',
            data3: '임서윤',
            data4: '2021-06-25 08:41:33',
            data5: '대기',
          },
        ]}
        columns={[
          {
            title: '신청 내용',
            dataIndex: 'data1',
            // render: (item) => <SwitchDetail approvalType={item} />,
            render: (text) => <LinkButton onClick={() => setDetail(text)}>{text}</LinkButton>,
          },
          {
            title: '신청자',
            dataIndex: 'data2',
            width: 120,
          },
          {
            title: '승인자',
            dataIndex: 'data3',
            width: 120,
          },
          {
            title: '승인 일시',
            dataIndex: 'data4',
            width: 180,
          },
          {
            title: '상태',
            dataIndex: 'data5',
            width: 100,
            render: (item) => <Tag>{item}</Tag>,
          },
        ]}
        rowSelection={{
          type: 'checkbox',
        }}
      />
      <Modal
        title={detail}
        visible={detail}
        onCancel={onCancel}
        width={940}
        bodyStyle={{ height: 'calc(100vh - 308px)', overflowY: 'auto' }}
        footer={
          <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
            <Button danger onClick={onCancel}>
              반려
            </Button>
            <Button type="primary" onClick={onCancel}>
              승인
            </Button>
          </Space>
        }
      >
        <Detail approvalType={detail} readOnly />
      </Modal>
    </Layout.Content>
  );
}

const Detail = ({ approvalType, readOnly }) => {
  let result;
  switch (approvalType) {
    case 'Desktop 신규 생성':
      result = <DesktopCreate readOnly={readOnly} />;
      break;
    case 'Desktop 사양 변경':
      result = <DesktopUpdate readOnly={readOnly} />;
      break;
    case 'Desktop 재설치(Win10)':
      result = <DesktopReinstall readOnly={readOnly} />;
      break;
    case 'Desktop 폐기':
      result = <DesktopDelete readOnly={readOnly} />;
      break;
    default:
      break;
  }
  return result;
};

export default Approval;

import { Button, Col, Form, Input, Modal, Row, Select, Space, Table, Typography } from 'antd';
import { LinkButton } from 'components';
import LocaleContext from 'locales';
import React, { useContext, useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { getUser } from 'services/websocket/users';

/**
 * 인사정보(Human Resource)를 조회하고 선택하는 컴포넌트
 * @param {string} title 모달 제목
 * @param {boolean} visible 모달 visible
 * @param {function} onCancel 모달 cancel 로직
 * @param {function} onOk 모달 ok 로직
 * @param {object} searchValue 검색 조건 { type: searchType, value: text }
 * @returns ReactNode
 */
function SelectHR({ title, visible, onCancel, onOk, searchValue }) {
  // states
  const [loading, setLoading] = useState(false); // 테이블 데이터 로딩중인지 여부
  const [dataSource, setDataSource] = useState([]); // 테이블 데이터
  const [selectedUser, setSelectedUser] = useState();
  const [currentPage, setCurrentPage] = useState(1); // Table의 현재 페이지

  // variable
  const { locale } = useContext(LocaleContext);

  // READ
  const onFinish = async (values) => {
    setLoading(true);
    getUser(values).then((data) => {
      setCurrentPage(1);
      setDataSource(data);
      setLoading(false);
    });
  };

  // UserSearchInput에 설정된 값이 있으면 그걸로 검색한다.
  useEffect(() => {
    if (searchValue?.value) {
      onFinish(searchValue);
    }
  }, [searchValue]);

  return (
    <Modal
      title={title}
      visible={visible}
      closable
      destroyOnClose
      width={740}
      cancelText={locale.resource['txt.cancel']}
      okText={locale.resource['txt.select']}
      onCancel={() => {
        setSelectedUser(undefined);
        setDataSource([]);
        onCancel();
      }}
      onOk={() => {
        setDataSource([]);
        onOk(selectedUser);
      }}
    >
      <Row>
        <Col>
          <Form
            onFinish={onFinish}
            initialValues={{
              type: searchValue?.type || 'name',
              value: searchValue?.value,
            }}
          >
            <Input.Group compact>
              <Form.Item name="type" noStyle>
                <Select
                  size="small"
                  style={{ marginBottom: 8, width: 60 }}
                  getPopupContainer={(triggerNode) => triggerNode.parentElement.parentElement}
                  options={[
                    { label: locale.resource['txt.name'], value: 'name' },
                    { label: locale.resource['txt.id'], value: 'sAMAccountName' },
                  ]}
                />
              </Form.Item>
              <Form.Item
                name="value"
                noStyle
                rules={[{ required: true, message: locale.resource['txt.required-field'] }]}
              >
                <Input
                  size="small"
                  placeholder={locale.resource['txt.search-user']}
                  style={{ width: 160 }}
                />
              </Form.Item>
              <Button
                htmlType="submit"
                size="small"
                icon={<AiOutlineSearch className="anticon" style={{ margin: '0 auto' }} />}
              />
            </Input.Group>
          </Form>
        </Col>
      </Row>
      <Table
        size="small"
        rowKey="dn"
        dataSource={dataSource}
        loading={loading}
        pagination={{
          current: currentPage,
          onChange: (page) => setCurrentPage(page),
        }}
        columns={[
          {
            title: locale.resource['txt.name'],
            dataIndex: 'userName',
            render: (text) => <LinkButton>{text}</LinkButton>,
          },
          { title: locale.resource['txt.user-id'], dataIndex: 'sAMAccountName' },
          { title: locale.resource['txt.department'], dataIndex: 'organization' },
        ]}
        rowSelection={{
          type: 'radio',
          onSelect: (record) => {
            setSelectedUser(record);
          },
        }}
      />
    </Modal>
  );
}

export function UserSearchInput({ readOnly1, value, onChange, onSuccess, userName }) {
  const [text, setText] = React.useState('');
  const [selectUserModal, setSelectUserModal] = React.useState({});
  const [searchType, setSearchType] = React.useState('name');
  const [userNm, setUserNm] = React.useState('');
  const { locale } = useContext(LocaleContext);

  useEffect(() => {
    setText(value);
  }, [value]);

  return readOnly1 ? (
    <Typography.Text>
      {userName}({text})
    </Typography.Text>
  ) : (
    <>
      <Space>
        <Select
          options={[
            { label: locale.resource['txt.name'], value: 'name' },
            { label: locale.resource['txt.id'], value: 'sAMAccountName' },
          ]}
          defaultValue={searchType}
          onChange={(val) => setSearchType(val)}
        />
        <Input.Search
          value={text}
          onChange={(e) => {
            setText(e.target.value);
            setUserNm('');
          }}
          onSearch={() => {
            setSelectUserModal({
              title: locale.resource['txt.select-user'],
              visible: true,
              value: { type: searchType, value: text },
            });
          }}
        />
        {userNm && (
          <Typography.Text>
            {locale.resource['txt.user']} : {userNm}({text})
          </Typography.Text>
        )}
      </Space>
      <SelectHR
        title={selectUserModal?.title}
        visible={selectUserModal?.visible}
        searchValue={selectUserModal?.value}
        onCancel={() => setSelectUserModal(undefined)}
        onOk={(selectedUser) => {
          // 여기서 onchange
          setText(selectedUser?.sAMAccountName || '');
          setUserNm(selectedUser?.userName);
          onChange(selectedUser?.sAMAccountName || '');
          setSelectUserModal(undefined);

          if (onSuccess) {
            onSuccess(selectedUser?.sAMAccountName || '');
          }
        }}
      />
    </>
  );
}

export default UserSearchInput;

/**
 * 앱 커스텀
 * 사이트별 커스텀 설정은 src\siteCustoms 에 작성한다.
 * ! 사이트가 추가될 때마다 불필요한 설정을 안하도록 구현한다.
 */

import { createContext } from 'react';
import * as siteCustoms from 'siteCustoms';
import packageJson from '../../package.json';

const preid =
  packageJson.config?.preid === 'hanabank-dev' ? 'hanabankDev' : packageJson.config?.preid;
const customized = siteCustoms[preid];

let config;
if (preid && customized) {
  config = { ...siteCustoms.basic, ...customized };
} else {
  config = { ...siteCustoms.basic };
}

const ConfigContext = createContext(config);

export default ConfigContext;

import React from 'react';
import DesktopCreate from './DesktopCreate';
import DesktopDelete from './DesktopDelete';
import DesktopReinstall from './DesktopReinstall';
import DesktopUpdate from './DesktopUpdate';
import DiskCreate from './DiskCreate';
import DiskDelete from './DiskDelete';
import DiskPermission from './DiskPermission';
import DiskUpdate from './DiskUpdate';
import DiskUsetime from './DiskUsetime';
import FileDownload from './FileDownload';
import FileDownloadEmergency from './FileDownloadEmergency';
import FileDownloadLarge from './FileDownloadLarge';
import FileDownloadTranz from './FileDownloadTranz';
import FileUpload from './FileUpload';
import FileUploadLarge from './FileUploadLarge';

function Detail({ approvalType, readOnly, id, form, onClose }) {
  let SwitchDetail;
  switch (approvalType) {
    case undefined:
      SwitchDetail = null;
      break;
    case 'approval.types.create-vd':
      SwitchDetail = <DesktopCreate id={id} readOnly={readOnly} form={form} onClose={onClose} />;
      break;
    case 'approval.types.change-spec-vd':
      SwitchDetail = <DesktopUpdate id={id} readOnly={readOnly} form={form} onClose={onClose} />;
      break;
    case 'approval.types.reset-vd':
      SwitchDetail = <DesktopReinstall id={id} readOnly={readOnly} form={form} onClose={onClose} />;
      break;
    case 'approval.types.delete-vd':
      SwitchDetail = <DesktopDelete id={id} readOnly={readOnly} form={form} onClose={onClose} />;
      break;
    case 'Disk 신청':
      SwitchDetail = <DiskCreate />;
      break;
    case 'Disk 용량변경 신청':
      SwitchDetail = <DiskUpdate />;
      break;
    case 'Disk 권한추가 신청':
      SwitchDetail = <DiskPermission />;
      break;
    case 'Disk 사용기간 변경 신청':
      SwitchDetail = <DiskUsetime />;
      break;
    case 'Disk 폐기 신청':
      SwitchDetail = <DiskDelete />;
      break;
    case '파일다운로드 신청':
      SwitchDetail = <FileDownload />;
      break;
    case '파일다운로드 신청(긴급)':
      SwitchDetail = <FileDownloadEmergency />;
      break;
    case '파일다운로드 신청(대용량)':
      SwitchDetail = <FileDownloadLarge />;
      break;
    case '파일다운로드 신청(Tranzfile)':
      SwitchDetail = <FileDownloadTranz />;
      break;
    case '파일업로드 신청':
      SwitchDetail = <FileUpload />;
      break;
    case '파일업로드 신청(대용량)':
      SwitchDetail = <FileUploadLarge />;
      break;

    default:
      break;
  }
  return SwitchDetail;
}

export default Detail;

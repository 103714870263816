import { request } from './util';

export async function getDocuments(filter) {
  return request({
    scope: 'portal',
    name: 'approval/get-approval-documents',
    data: {
      paging: { filter: { ...filter, applicantForPortal: localStorage.getItem('userId') } },
    },
  });
}

export async function getDocumentDetail(id) {
  return request({
    scope: 'portal',
    name: 'approval/get-approval-detail',
    data: {
      approvalId: id,
    },
  });
}

export async function requestApproval(payload) {
  return request({
    scope: 'portal',
    name: 'approval/request-approval',
    data: {
      ...payload,
    },
  });
}

export async function changeStateOfApproval(payload) {
  return request({
    scope: 'portal',
    name: 'approval/update-approvals-state',
    data: {
      ...payload,
    },
  });
}

export async function updateApproval(id, params) {
  return request({
    scope: 'portal',
    name: 'approval/update-approval',
    data: {
      approvalId: id,
      ...params,
    },
  });
}

export async function getFlavors() {
  return request({
    scope: 'portal',
    name: 'approval/get-flavor',
    data: { paging: { params: {}, sorter: {} } },
  });
}

export async function assignVd(params) {
  return request({
    scope: 'portal',
    name: 'session/assign-vd',
    data: params,
  });
}

export async function unassignVds(payload) {
  await request({
    scope: 'portal',
    name: 'session/unassign-vd',
    data: {
      ...payload,
    },
  });
}

export async function getSessionUser() {
  return request({
    scope: 'portal',
    name: 'session/get-session-user',
    data: {},
  });
}

export async function getRequieredProgram() {
  return request({
    scope: 'portal',
    name: 'session/get-required-program',
    data: {},
  });
}

export async function getCustomRedirect() {
  return request({
    scope: 'portal',
    name: 'session/get-custom-redirect',
    data: {},
  });
}

export async function getCustomClient() {
  return request({
    scope: 'portal',
    name: 'session/get-custom-client',
    data: {},
  });
}

import { Descriptions, Popover, Table } from 'antd';
import LocaleContext from 'locales';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { getVdsOfUser, getOwnVdsOfUser } from 'services/websocket/users';
import { displaySize } from 'pages/utils';
import Panel from './Panel';

function OwnDesktops({ title, bodyStyle, user, contents, readOnly, onSelect, id, apprType }) {
  const [dataSource, setDataSource] = useState([]);
  const [defaultSelectKey, setDefaultSelectKey] = useState([]);
  const { locale } = useContext(LocaleContext);

  useEffect(() => {
    if (!user) {
      setDataSource([]);
      return;
    }

    if (apprType === 'approval.types.create-vd') {
      getOwnVdsOfUser(user).then((data) => {
        let source = data?.data;

        if (readOnly && contents) {
          source = contents;
        }

        const keys = [];
        if (contents) {
          contents.forEach((v) =>
            source.map((d) => {
              if (v.id === d.id) {
                keys.push(v.id);
              }
              return null;
            }),
          );
        }
        setDataSource(source || []);
        setDefaultSelectKey(keys);
        if (contents) {
          onSelect(contents);
        }
      });
    } else {
      getVdsOfUser(user, id).then((data) => {
        let source = data?.data;

        if (readOnly && contents) {
          source = contents;
        }

        const keys = [];
        if (contents) {
          contents.forEach((v) =>
            source.map((d) => {
              if (v.id === d.id) {
                keys.push(v.id);
              }
              return null;
            }),
          );
        }
        setDataSource(source || []);
        setDefaultSelectKey(keys);
        if (contents) {
          onSelect(contents);
        }
      });
    }
  }, [user]);

  return (
    <Panel title={title} bodyStyle={bodyStyle}>
      <Table
        size="small"
        rowKey="id"
        pagination={false}
        dataSource={dataSource}
        columns={[
          {
            title: locale.resource['txt.desktop-group'],
            dataIndex: 'vdGroupName',
            render: (text, record) => `${text}( ${record.vdName} )`,
          },
          {
            title: locale.resource['txt.os'],
            dataIndex: 'os',
          },
          {
            title: locale.resource['txt.flavor'],
            dataIndex: 'flavorName',
            align: 'right',
            render: (text, record) => {
              const gpu = record.extraSpecs?.['resources:VGPU'];
              const { size, unit } = displaySize(record.ram);
              return (
                <Popover
                  title={locale.resource['txt.flavor-info']}
                  overlayStyle={{ width: 140 }}
                  content={
                    <Descriptions size="small" column={1}>
                      <Descriptions.Item label="CPU">
                        {record.cpus ?? '-'}
                        {locale.resource['txt.core']}
                      </Descriptions.Item>
                      <Descriptions.Item label="RAM">
                        {record.ram ? `${size}${unit}` : '-'}
                      </Descriptions.Item>
                      {gpu && (
                        <Descriptions.Item label="GPU">
                          {locale.resource['txt.use']}
                        </Descriptions.Item>
                      )}
                    </Descriptions>
                  }
                >
                  {text}
                </Popover>
              );
            },
          },
          {
            title: locale.resource['txt.disk'],
            dataIndex: 'disk',
            align: 'right',
          },
          {
            title: locale.resource['txt.created-date'],
            dataIndex: 'createDate',
            render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
          },
        ]}
        rowSelection={
          readOnly
            ? false
            : {
                type: 'checkbox',

                selectedRowKeys: defaultSelectKey,
                onChange: (key, record) => {
                  setDefaultSelectKey(key);
                  onSelect(record);
                },
              }
        }
      />
    </Panel>
  );
}

export default OwnDesktops;

import { Col, Form, Input, Modal, Row, Select, Table } from 'antd';
import { LinkButton } from 'components';
import React from 'react';

/**
 * 조직정보(Human Resource)를 조회하고 선택하는 컴포넌트
 * @param {string} title 모달 제목
 * @param {boolean} visible 모달 visible
 * @param {function} onCancel 모달 cancel 로직
 * @param {function} onOk 모달 ok 로직
 * @returns ReactNode
 */
function SelectTeam({ title, visible, onCancel, onOk }) {
  return (
    <Modal
      title={title}
      visible={visible}
      closable
      width={740}
      cancelText="취소"
      okText="선택"
      onCancel={onCancel}
      onOk={onOk}
      centered
    >
      <Form style={{ marginBottom: 16 }}>
        <Form.Item noStyle>
          <Row>
            <Col>
              <Input.Group compact>
                <Select defaultValue="조직" style={{ width: 120 }}>
                  <Select.Option value="조직">조직</Select.Option>
                  <Select.Option value="ID">ID</Select.Option>
                </Select>
                <Input.Search placeholder="검색" style={{ width: 240 }} />
              </Input.Group>
            </Col>
          </Row>
        </Form.Item>
      </Form>
      <Table
        size="small"
        dataSource={[
          {
            key: '1',
            name: 'Tactics',
            id: 'fe5d06ad',
            department: 'LGD Korea Group > 부서 이름 > 부서 이름',
            leader: '송은주',
          },
          {
            key: '2',
            name: 'Creative',
            id: '0704a7eb',
            department: 'LGD Korea Group > 부서 이름 > 부서 이름',
            leader: '김서윤',
          },
          {
            key: '3',
            name: 'Paradigm',
            id: 'b220197f',
            department: 'LGD Korea Group > 부서 이름',
            leader: '안수빈',
          },
          {
            key: '4',
            name: 'Program',
            id: '50271c89',
            department: 'LGD Korea Group > 부서 이름 > 부서 이름',
            leader: '조서윤',
          },
          {
            key: '5',
            name: 'Response',
            id: 'c6d9caa1',
            department: 'LGD Korea Group > 부서 이름 > 부서 이름',
            leader: '정지후',
          },
          {
            key: '6',
            name: 'Applications',
            id: '8844a926',
            department: 'LGD Korea Group > 부서 이름 > 부서 이름',
            leader: '박서연',
          },
          {
            key: '7',
            name: 'Solutions',
            id: '13f20478',
            department: 'LGD Korea Group',
            leader: '김서연',
          },
          {
            key: '8',
            name: 'Response',
            id: 'd8bfa6b4',
            department: 'LGD Korea Group > 부서 이름 > 부서 이름',
            leader: '최지훈',
          },
          {
            key: '9',
            name: 'Marketing',
            id: 'db4a8a7f',
            department: 'LGD Korea Group > 부서 이름 > 부서 이름',
            leader: '최민재',
          },
          {
            key: '10',
            name: 'Factors',
            id: '3d749129',
            department: 'LGD Korea Group > 부서 이름 > 부서 이름',
            leader: '안예은',
          },
          {
            key: '11',
            name: 'Operations',
            id: 'e161fb28',
            department: 'LGD Korea Group > 부서 이름 > 부서 이름',
            leader: '류수빈',
          },
          {
            key: '12',
            name: 'Tactics',
            id: 'b0f51b12',
            department: 'LGD Korea Group > 부서 이름 > 부서 이름',
            leader: '김건우',
          },
          {
            key: '13',
            name: 'Creative',
            id: 'd9353e5c',
            department: 'LGD Korea Group > 부서 이름 > 부서 이름',
            leader: '권수빈',
          },
        ]}
        columns={[
          {
            title: '조직 이름',
            dataIndex: 'name',
            render: (text) => <LinkButton>{text}</LinkButton>,
          },
          { title: '조직 ID', dataIndex: 'id' },
          { title: '조직 정보', dataIndex: 'department' },
          { title: 'PM/리더', dataIndex: 'leader' },
        ]}
      />
    </Modal>
  );
}

export default SelectTeam;

import { Typography } from 'antd';
import React from 'react';

// 접속 버튼 위에 표시할 접속 불가한 사유 메시지
function DesktopMessage({ locale, current }) {
  const msg = [];
  if (!current.isAccessibleIp) {
    msg.push(`${locale.resource['txt.inaccessible-ip-msg']}\n`);
  }
  if (current.subState === 'vd.sub-state.session-timeout') {
    msg.push(`${locale.resource['txt.session-timeout-msg']}\n`);
  }
  if (current.locked) {
    msg.push(`${locale.resource['txt.vd-locked-msg']}\n`);
  }
  return msg.length ? (
    <Typography.Paragraph className="desktopMessage">{msg}</Typography.Paragraph>
  ) : null;
}

export default DesktopMessage;

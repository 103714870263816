import { Button, Col, Layout, Result, Row } from 'antd';
import { Icon } from 'components';
import React, { useContext } from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import LocaleContext from 'locales';
import { DownloadTable } from '../help/Download';

function RequiredApps({ history, location }) {
  const { locale } = useContext(LocaleContext);

  return (
    <Layout.Content>
      <Result
        title={locale.resource['txt.check-required-program']}
        subTitle={
          <p style={{ whiteSpace: 'pre-line' }}>
            {locale.resource['txt.install-required-program']}
          </p>
        }
        extra={
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <Button
                size="large"
                icon={<Icon name={<AiOutlineRight />} />}
                onClick={() =>
                  history.push({
                    pathname: '/desktops',
                    redirectUrlToVd: location?.redirectUrlToVd,
                  })
                }
              >
                {locale.resource['txt.skip']}
              </Button>
            </Col>
            <Col span={24}>
              <DownloadTable
                size="small"
                locale={locale}
                onCheckProgram={(success) => {
                  if (success) {
                    history.push({
                      pathname: '/desktops',
                      redirectUrlToVd: location?.redirectUrlToVd,
                    });
                  }
                }}
              />
            </Col>
          </Row>
        }
      />
    </Layout.Content>
  );
}

export default RequiredApps;

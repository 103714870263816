import {
  Alert,
  Col,
  DatePicker,
  Form,
  InputNumber,
  List,
  Modal,
  Row,
  Select,
  Table,
  message,
} from 'antd';
import useFlavorInfo from 'hooks/useFlavorInfo';
import LocaleContext from 'locales';
import moment from 'moment';
import { displaySize, getErrorFields, getValidationMsgs } from 'pages/utils';
import React, { useContext, useEffect, useState } from 'react';
import { getDocumentDetail, requestApproval, updateApproval } from 'services/websocket/session';
import { getVdsOfUser } from 'services/websocket/users';
import { RequestInfo, TimePreset, UserSearchInput } from './components';

function DesktopUpdate({ readOnly, form, id, onClose }) {
  const [user, setUser] = React.useState();
  const [desktops, setDesktops] = useState([]);
  const [userName, setUserName] = React.useState('');
  const [changeSpecInfo, setChangeSpecInfo] = React.useState({});
  const { locale } = useContext(LocaleContext);
  const [{ flavorOptions }, getSelectedFlavor] = useFlavorInfo();

  useEffect(() => {
    if (!id) {
      form.resetFields();
      setDesktops([]);
      setUser(undefined);

      const applicant = localStorage.getItem('userId');
      form.setFieldsValue({
        applicant,
        type: 'approval.types.change-spec-vd',
      });
      return;
    }

    getDocumentDetail(id).then((res) => {
      const data = res?.data; // #178732#note-16
      const contents = data?.contents?.[0];
      // 수정 및 조회시 after값 설정
      const defaultSpec = {
        id: contents.id,
        // 대기 중 외의 상태(readonly)인 경우 vd before 초기값 세팅
        name: contents.name,
        before: {
          cpus: contents.before?.cpus,
          ram: contents.before?.ram,
          flavorId: contents.before?.flavorId,
          flavorName: contents.before?.flavorName,
          extraSpecs: contents.before?.extraSpecs,
          disk: contents.before?.disk,
        },
        after: {
          cpus: contents.after?.cpus,
          ram: contents.after?.ram,
          flavorId: contents.after?.flavorId,
          flavorName: contents.after?.flavorName,
          extraSpecs: contents.after?.extraSpecs,
          disk: contents.after?.disk,
        },
      };
      setChangeSpecInfo(defaultSpec);

      let approveDate = data?.approveDate;

      if (approveDate !== null) {
        approveDate = moment(approveDate);
      }
      form.setFieldsValue({
        ...data,
        contents,
        approveDate,
        applyDate: moment(data.applyDate).format('YYYY-MM-DD HH:mm:ss'),
      });

      setUser(data?.user);
      setUserName(data?.userName);
    });
  }, [id]);

  useEffect(() => {
    if (user) {
      getVdsOfUser(user, id).then((data) => {
        const desktop = data.data;
        let i = 0;
        const contents = desktop.map((d, index) => {
          // 대기 중 상태 인 경우 desktop에서 vd before 초기값 세팅
          const defaultBefore = {
            id: d.id,
            name: d.vdName,
            locale: 'ko',
            before: {
              cpus: d.cpus,
              ram: d.ram,
              flavorId: d.flavorId,
              flavorName: d.flavorName,
              extraSpecs: d.extraSpecs,
              disk: d.disk,
            },
            after: {},
          };
          if (changeSpecInfo?.id === d.id) {
            i = index;
            return { ...defaultBefore, after: changeSpecInfo.after, before: changeSpecInfo.before };
          }
          return defaultBefore;
        });
        setDesktops(contents);
        form.setFieldsValue({ contents: readOnly ? changeSpecInfo : contents[i] });
      });
    }
  }, [user]);

  const ChangeSpec = ({ readOnly1, value, onChange }) => {
    const [dataSource, setDataSource] = useState([]);
    const [data, setData] = useState(value);
    useEffect(() => {
      setData(value);
      setDataSource([
        {
          item: 'flavor',
          before: value?.before?.flavorName,
          after: value?.after?.flavorName,
        },
        {
          item: 'disk',
          before: value?.before?.disk,
          after: value?.after?.disk,
        },
      ]);
    }, [value]);

    return (
      <Table
        size="small"
        rowKey="key"
        pagination={false}
        dataSource={dataSource}
        columns={[
          {
            title: locale.resource['txt.type'],
            dataIndex: 'item',
            width: '20%',
            render: (text) => locale.resource[`txt.${text}`],
          },
          {
            title: locale.resource['txt.before'],
            dataIndex: 'before',
            width: '40%',
            render: (text, record) => {
              const beforeVdInfo = data?.before; // 사양 변경 최초 신청 시 선택된 VD 정보에서 플레이버 정보를 가져온다 (플레이버가 비공개 상태면 getSelectedFlavor로 정보를 가져올 수 없음)
              const { before } = changeSpecInfo; // 수정인 경우 기존에 저장된 값(contents)에서 참조

              return record.item === 'flavor' ? (
                <Form.Item
                  name={['contents', 'before', 'flavorName']}
                  help={
                    beforeVdInfo
                      ? `${data.before?.cpus ?? '-'}${locale.resource['txt.core']} / ${
                          data.before?.ram
                            ? `${displaySize(data.before?.ram).size}${
                                displaySize(data.before?.ram).unit
                              }`
                            : '-'
                        } / GPU ${
                          data.before?.extraSpecs?.['resources:VGPU']
                            ? locale.resource['txt.used']
                            : locale.resource['txt.not-used']
                        }`
                      : before &&
                        `${before?.cpus ?? '-'}${locale.resource['txt.core']} / ${
                          before?.ram
                            ? `${displaySize(before?.ram).size}${displaySize(before?.ram).unit}`
                            : '-'
                        } / GPU ${
                          before?.extraSpecs?.['resources:VGPU']
                            ? locale.resource['txt.used']
                            : locale.resource['txt.not-used']
                        }`
                  }
                  rules={[{ required: true, message: locale.resource['txt.required-field'] }]}
                >
                  {text}
                </Form.Item>
              ) : (
                <span>
                  {text}
                  {text && record.item === 'disk' && locale.resource['txt.gb']}
                </span>
              );
            },
          },
          {
            title: locale.resource['txt.after'],
            dataIndex: 'after',
            width: '40%',
            render: (text, record) => {
              const flavorInfo = text && getSelectedFlavor(text);
              const isFlavorUnavailable = text && !flavorInfo && !readOnly; // 플레이버 이름 O, 정보 X > 사용할 수 없는 플레이버로 판별
              return record.item === 'flavor' ? (
                <>
                  <Form.Item
                    name={['contents', 'after', 'flavorName']}
                    help={isFlavorUnavailable ? locale.resource['txt.flavor-unavailable'] : null}
                    validateStatus={isFlavorUnavailable && 'error'}
                    rules={[{ required: true, message: locale.resource['txt.required-field'] }]}
                  >
                    <Select
                      onChange={(v) => {
                        const changedInfo = v && getSelectedFlavor(v);
                        const values = form.getFieldsValue();
                        form.setFieldsValue({
                          ...values,
                          contents: {
                            ...values.contents,
                            after: {
                              ...values.contents.after,
                              cpus: changedInfo?.cpus,
                              ram: changedInfo?.ram,
                              extraSpecs: changedInfo?.extraSpecs,
                              flavorId: changedInfo?.flavorId,
                            },
                          },
                        });
                      }}
                      disabled={readOnly1}
                      placeholder={locale.resource['txt.select-option-required']}
                      options={flavorOptions}
                    />
                  </Form.Item>
                  <Form.Item hidden name={['contents', 'after', 'cpus']} />
                  <Form.Item hidden name={['contents', 'after', 'ram']} />
                  <Form.Item hidden name={['contents', 'after', 'extraSpecs']} />
                </>
              ) : (
                <Form.Item name={['contents', 'after', 'disk']}>
                  <InputNumber
                    value={record.after}
                    addonAfter={locale.resource['txt.gb']}
                    style={{ width: 120 }}
                    disabled={readOnly1}
                    min={0}
                    onChange={
                      data &&
                      ((val) => {
                        data.after.disk = val;
                        onChange(data);
                      })
                    }
                  />
                </Form.Item>
              );
            },
          },
        ]}
      />
    );
  };
  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 19 }}
      onFinish={async ({ contents, approveDate, ...fields }) => {
        const payload = {
          ...fields,
          contents: [contents],
          approveDate: approveDate?.format('YYYY-MM-DD HH:mm:ss') || approveDate,
        };
        try {
          const result = id ? await updateApproval(id, payload) : await requestApproval(payload);
          if (result.status === 'error') {
            const { invalidParams } = result;
            const errors = getErrorFields(getValidationMsgs(invalidParams));
            form.setFields(errors);
            const generalErrors = errors.find((el) => el.name[0] === 'generalValidationError'); // generalValidationError는 무조건 하나만 받고 errors를 배열로 여러 개 받는다.
            if (generalErrors) {
              Modal.error({
                title: locale.resource['txt.failed-to-save'],
                content: generalErrors.errors.map((el) => <div>{el}</div>),
              });
            }
            return;
          }
          form.resetFields();
          onClose();
        } catch (e) {
          message.error(e);
        }
      }}
    >
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Alert
            showIcon
            type="warning"
            message={
              <List
                size="small"
                split={false}
                style={{ marginLeft: 16 }}
                dataSource={[
                  locale.resource['txt.resizing-needs-to-reboot'],
                  locale.resource['txt.os-reinstall-msg'],
                  locale.resource['txt.cant-apply-duplication'],
                ]}
                renderItem={(item) => <List.Item style={{ padding: 0 }}>{item}</List.Item>}
              />
            }
          />
        </Col>
        <Col span={24}>
          <RequestInfo readOnly={readOnly} />
          <Form.Item label={locale.resource['txt.approval-type']} name="type" required>
            {locale.resource['txt.vd-resize']}
          </Form.Item>
          <Form.Item
            label={locale.resource['txt.user-and-id']}
            name="user"
            rules={[{ required: true, message: locale.resource['txt.required-field'] }]}
          >
            <UserSearchInput
              readOnly1={readOnly}
              onSuccess={async (uid) => {
                // 처리
                setUser(uid);
              }}
              userName={userName}
            />
          </Form.Item>

          <Form.Item
            label={locale.resource['txt.vd-resize']}
            name={['contents', 'id']}
            rules={[{ required: true, message: locale.resource['txt.required-field'] }]}
          >
            <Select
              placeholder={locale.resource['txt.select-user-desktops']}
              style={{ width: 'auto' }}
              disabled={readOnly}
              onSelect={(val) => {
                form.setFieldsValue({
                  contents: desktops.find((d) => d.id === val),
                });
              }}
              options={
                readOnly
                  ? [{ label: changeSpecInfo.name, value: changeSpecInfo.id }]
                  : desktops?.map((desktop) => ({
                      label: desktop.name,
                      value: desktop.id,
                    }))
              }
            />
          </Form.Item>
          <Form.Item
            label={null}
            wrapperCol={{ offset: 5 }}
            name="contents"
            rules={[{ required: true, message: locale.resource['txt.required-field'] }]}
          >
            <ChangeSpec readOnly1={readOnly} />
          </Form.Item>
          <Form.Item
            label={locale.resource['txt.execution-date']}
            name="approveDate"
            extra={locale.resource['txt.excution-date-msg']}
            rules={[
              () => ({
                validator(_, value) {
                  if (value > moment() || !value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(locale.resource['txt.excution-date-val']));
                },
              }),
            ]}
            initialValue={null} // #193685
          >
            <DatePicker
              showTime={{
                defaultValue: moment('00:00', 'HH:mm'),
                format: 'HH:mm',
              }}
              inputReadOnly={1}
              disabled={readOnly}
              placeholder=""
              disabledDate={(date) => date && date < moment().startOf('day')}
              showNow={false}
              format="YYYY-MM-DD HH:mm"
              renderExtraFooter={() => (
                <TimePreset
                  onSelect={(time) => {
                    form.setFieldsValue({ approveDate: time });
                  }}
                />
              )}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default DesktopUpdate;

import axios from 'axios';
import crypto from 'crypto';
import request from './request';
/**
 * ! deprecated: websocket api 도입으로 사용 금지
 * @deprecated websocket api를 사용하여 주십시오.
 */
export async function accountLogin({ userId, password, autoLogin }) {
  const res = await request.post('/portal/api/auth', { userId, password, autoLogin });

  return res.status === 201 && res.data;
}

/**
 * ! deprecated: websocket api 도입으로 사용 금지
 * @deprecated websocket api를 사용하여 주십시오.
 */
export async function checkToken() {
  return request.get('/portal/api/auth');
}

/**
 * ! deprecated: websocket api 도입으로 사용 금지
 * @deprecated websocket api를 사용하여 주십시오.
 */
export async function doAutoLogin(data) {
  const res = await request.patch('/portal/api/auth', {
    data: { token: data },
  });
  return res;
}

/**
 * ! deprecated: websocket api 도입으로 사용 금지
 * @deprecated websocket api를 사용하여 주십시오.
 * @param {String} data
 * @returns
 */
export async function checkAccount(data) {
  const res = await request.post('/portal/api/auth/check-account', {
    data: { token: data },
  });
  return res;
}

/**
 * ! deprecated: websocket api 도입으로 사용 금지
 * @deprecated websocket api를 사용하여 주십시오.
 */
export async function accountLogout() {
  return request.post('/portal/api/auth/delete');
}

/**
 * ! deprecated: websocket api 도입으로 사용 금지
 * @deprecated websocket api를 사용하여 주십시오.
 */
export async function deleteToken() {
  return axios.post('/portal/api/auth/delete');
}

/**
 * ! deprecated: websocket api 도입으로 사용 금지
 * @deprecated websocket api를 사용하여 주십시오.
 */
export async function requestResetPassword(userId) {
  const res = await request.post(`/portal/api/auth/request/password-reset?userId=${userId}`);
  return res.data;
}

/**
 * ! deprecated: websocket api 도입으로 사용 금지
 * @deprecated websocket api를 사용하여 주십시오.
 */
export async function updatePasswordWithResetToken(token, password) {
  const res = await request.put('/portal/api/auth/update-password-with-reset-token', {
    token,
    password,
  });
  return res.data;
}

/**
 * ! deprecated: websocket api 도입으로 사용 금지
 * @deprecated websocket api를 사용하여 주십시오.
 */
export async function changePasswordFor773(user, password) {
  const res = await request.put('/portal/api/auth/change-password', {
    user,
    password,
  });
  return res.data;
}

/**
 * ! deprecated: websocket api 도입으로 사용 금지
 * @deprecated websocket api를 사용하여 주십시오.
 */
export async function getConnHistory(groupId) {
  const res = await request.get(`/portal/api/auth/conn-history/${groupId}`);
  return res.data;
}

/**
 * ! deprecated: websocket api 도입으로 사용 금지
 * @deprecated websocket api를 사용하여 주십시오.
 */
export async function getSessionUser() {
  const res = await request.get(`/portal/api/session/user`);
  return res;
}

/**
 * ! deprecated: websocket api 도입으로 사용 금지
 * @deprecated websocket api를 사용하여 주십시오.
 */
export async function getRequieredProgram() {
  const res = await request.get(`/portal/api/session/required-program`);
  return res.data;
}

export async function getCustomRedirect() {
  const res = await request.get(`/portal/api/session/custom-redirect`);
  return res.data;
}

/**
 * ! deprecated: websocket api 도입으로 사용 금지
 * @deprecated websocket api를 사용하여 주십시오.
 */
export async function getCustomClient() {
  const res = await request.get(`/portal/api/session/custom-client`);
  return res.data;
}

/**
 * ! deprecated: websocket api 도입으로 사용 금지
 * @deprecated websocket api를 사용하여 주십시오.
 */
export async function twoFactorCodeGenerate({ channel, regenerate, loginInfo }) {
  const res = await request.post(
    `/portal/api/auth/additional-auth/generate?regenerate=${regenerate}`,
    { channel, ...loginInfo },
  );
  return res.data;
}

/**
 * ! deprecated: websocket api 도입으로 사용 금지
 * @deprecated websocket api를 사용하여 주십시오.
 */
export async function twoFactorAuth({ authCode, loginInfo }) {
  const res = await request.post('/portal/api/auth/additional-auth', { authCode, ...loginInfo });
  return res;
}

export async function getRSAKey() {
  return request.get('/portal/api/auth/rsa-public-key');
}

export function encryptRSA(text, key) {
  const buffer = Buffer.from(text);
  const encrypted = crypto.publicEncrypt(key, buffer);
  return encrypted.toString('hex');
}

import { Col, Form, Input, Modal, Row, Select, Table } from 'antd';
import { LinkButton } from 'components';
import React from 'react';

/**
 * 인사정보(Human Resource)를 조회하고 선택하는 컴포넌트
 * @param {string} title 모달 제목
 * @param {boolean} visible 모달 visible
 * @param {function} onCancel 모달 cancel 로직
 * @param {function} onOk 모달 ok 로직
 * @returns ReactNode
 */
function SelectHR({ title, visible, onCancel, onOk }) {
  return (
    <Modal
      title={title}
      visible={visible}
      closable
      width={740}
      cancelText="취소"
      okText="선택"
      onCancel={onCancel}
      onOk={onOk}
      centered
    >
      <Form style={{ marginBottom: 16 }}>
        <Form.Item noStyle>
          <Row>
            <Col>
              <Input.Group compact>
                <Select defaultValue="이름" style={{ width: 120 }}>
                  <Select.Option value="이름">이름</Select.Option>
                  <Select.Option value="ID">ID</Select.Option>
                  <Select.Option value="부서">부서</Select.Option>
                </Select>
                <Input.Search placeholder="검색" style={{ width: 240 }} />
              </Input.Group>
            </Col>
          </Row>
        </Form.Item>
      </Form>
      <Table
        size="small"
        dataSource={[
          {
            key: '1',
            name: '장민재',
            id: 'functionalities',
            position: '사원',
            department: 'Integration',
            work: '재직',
          },
          {
            key: '2',
            name: '안현우',
            id: 'silver',
            position: '사원',
            department: 'Assurance',
            work: '재직',
          },
          {
            key: '3',
            name: '신예준',
            id: 'interactive',
            position: '사원',
            department: 'Marketing',
            work: '재직',
          },
          {
            key: '4',
            name: '서민서',
            id: 'invoice',
            position: '사원',
            department: 'Intranet',
            work: '재직',
          },
          {
            key: '5',
            name: '권예은',
            id: 'invoice',
            position: '사원',
            department: 'Mobility',
            work: '재직',
          },
          {
            key: '6',
            name: '류현우',
            id: 'Avon',
            position: '사원',
            department: 'Research',
            work: '재직',
          },
          {
            key: '7',
            name: '황은주',
            id: 'Tuna',
            position: '사원',
            department: 'Intranet',
            work: '재직',
          },
          {
            key: '8',
            name: '홍하은',
            id: 'TCP',
            position: '사원',
            department: 'Interactions',
            work: '재직',
          },
          {
            key: '9',
            name: '류서윤',
            id: 'Bedfordshire',
            position: '사원',
            department: 'Interactions',
            work: '재직',
          },
          {
            key: '10',
            name: '장서현',
            id: 'architectures',
            position: '사원',
            department: 'Paradigm',
            work: '재직',
          },
          {
            key: '11',
            name: '김서윤',
            id: 'Account',
            position: '사원',
            department: 'Tactics',
            work: '재직',
          },
          {
            key: '12',
            name: '한윤서',
            id: 'azure',
            position: '사원',
            department: 'Tactics',
            work: '재직',
          },
          {
            key: '13',
            name: '조현우',
            id: 'wireless',
            position: '사원',
            department: 'Accounts',
            work: '재직',
          },
        ]}
        columns={[
          {
            title: '이름',
            dataIndex: 'name',
            render: (text) => <LinkButton>{text}</LinkButton>,
          },
          { title: '사용자 ID', dataIndex: 'id' },
          { title: '직급', dataIndex: 'position' },
          { title: '부서', dataIndex: 'department' },
          { title: '재직 여부', dataIndex: 'work', width: 90 },
        ]}
        rowSelection={{
          type: 'radio',
        }}
      />
    </Modal>
  );
}

export default SelectHR;

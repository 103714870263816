import { useRequest } from 'ahooks';
import { Modal, message, notification } from 'antd';
import LocaleContext from 'locales';
import _ from 'lodash';
import moment from 'moment';
import {
  connectByVdStart,
  getXauthFromCookie,
  isWindows,
  openApp,
  startAgent,
  vsClagentPortCheck,
} from 'pages/utils';
import React, { useContext, useEffect, useReducer } from 'react';
import { AiFillApi, AiOutlinePoweroff, AiOutlineRedo, AiOutlineSync } from 'react-icons/ai';
import * as VsclientService from 'services/vsclient';
import { accountLogout, checkAccount, getConnHistory } from 'services/websocket/auth';
import {
  getAvailableVdDetail,
  getAvailableVdGroups,
  requestVdAction,
} from 'services/websocket/mgmt';
import { assignVd, unassignVds } from 'services/websocket/session';
import ConfigContext from 'store/ConfigContext';
import { listenMessage, sendMessage } from 'utils/webSocket';

/**
 * 특정 VD에 내릴 접속 및 장애 조치 목록에 disable 상태를 추가한다.
 * @param {object} desktop VD 정보
 * @param {object[]} customActions 사이트용 커스텀 액션 목록
 * @returns {connect{}, failovers[]}
 */
function getDesktopActions(desktop, frontConf) {
  const { state, subState, isAccessibleIp, user, locked } = desktop;
  const { desktopConnect, desktopFailovers } = frontConf;
  // 액션별 속성
  // TODO disabled에 user와 subState는 null 일 수 있기 때문에 disabled의 결과값이 undefined가 될 수 있음
  const defaultFailovers = [
    {
      label: 'txt.power-on',
      icon: <AiOutlinePoweroff />,
      disabled:
        !user || locked
          ? true
          : !state.endsWith('.state.stopped') ||
            subState.endsWith('.sub-state.migrating') ||
            subState.endsWith('.sub-state.session-timeout') ||
            !isAccessibleIp,
    },
    {
      label: 'txt.vd-soft-reboot',
      icon: <AiOutlineRedo />,
      tooltip: 'txt.inaccessible-vd-msg',
      disabled:
        !user || locked
          ? true
          : !(
              state.endsWith('.state.ready') ||
              state.endsWith('.state.idle') ||
              state.endsWith('.state.disconnected')
            ) ||
            subState.endsWith('.sub-state.migrating') ||
            subState.endsWith('.sub-state.session-timeout') ||
            subState?.endsWith('.sub-state.agent-logoff') ||
            !isAccessibleIp,
    },
    {
      label: 'txt.vd-reboot',
      icon: <AiOutlineSync />,
      tooltip: 'txt.reboot-for-recovery',
      disabled:
        !user || locked
          ? true
          : !(
              state.endsWith('.state.ready') ||
              state.endsWith('.state.idle') ||
              state.endsWith('.state.disconnected') ||
              state.endsWith('.state.stopped') ||
              state.endsWith('.state.error')
            ) ||
            subState.endsWith('.sub-state.migrating') ||
            subState.endsWith('.sub-state.session-timeout') ||
            subState?.endsWith('.sub-state.agent-logoff') ||
            !isAccessibleIp,
    },
  ];
  const defaultConnect = {
    label: 'txt.connect',
    icon: <AiFillApi className="anticon" fontSize={24} />,
    disabled:
      locked ||
      !(
        state?.endsWith('.state.ready') ||
        state?.endsWith('.state.idle') ||
        state?.endsWith('.state.connected') ||
        state?.endsWith('.state.disconnected') ||
        state?.endsWith('.state.stopped')
      ) ||
      subState?.endsWith('.sub-state.session-timeout') ||
      subState?.endsWith('.sub-state.agent-logoff') ||
      !isAccessibleIp,
  };

  // defaultFailovers + 커스텀(desktopFailovers) 한 후 정렬한다.
  function customFailovers() {
    // 장애조치 정렬 순서, 여기에 없는 것은 뒤에 배치된다.
    const failoverPriority = {
      'txt.power-on': 1,
      'txt.vd-soft-reboot': 2,
      'txt.vd-reboot': 3,
    };

    return _.unionBy(desktopFailovers(desktop), defaultFailovers, 'label').sort((a, b) => {
      const priorityA = failoverPriority[a.label] || Infinity;
      const priorityB = failoverPriority[b.label] || Infinity;
      return priorityA - priorityB;
    });
  }

  const result = {
    connect: !desktopConnect ? defaultConnect : desktopConnect(desktop),
    failovers: !desktopFailovers ? defaultFailovers : customFailovers(),
  };

  return result;
}

// DesktopViewer의 상태 변경
function reducer(state, { type, payload }) {
  switch (type) {
    case 'set-desktops': {
      return { ...state, desktops: payload };
    }
    case 'set-current':
      return { ...state, current: payload };
    case 'set-visibleConnHistory':
      return { ...state, visibleConnHistory: payload };
    default:
      return state;
  }
}

/**
 * 접속 불가능한 VD인지 판별
 * @param {object} vd VD 정보
 * @returns boolean
 */
function isInvalidVd(vd) {
  const { state, user } = vd;
  return (
    state === 'vd-group.state.disposed' ||
    state === 'vd.state.disposing' ||
    (user !== null && user.toLowerCase() !== localStorage.getItem('userId').toLowerCase()) // 가상 데스크탑 할당 전이 아닌데 사용자가 맞지 않은 경우, AD는 대소문자를 구분하지 않기 때문에 소문자로 비교한다.;
  );
}

/**
 * Desktops에 관련된 훅
 * @param {withActionInDesktops: boolean} options 훅의 옵션 들
 *  - withActionInDesktops: desktops를 리턴할 때 actions을 추가할지 여부
 * @returns object
 */
// eslint-disable-next-line import/prefer-default-export
export function useDesktops(options = {}) {
  const { withActionInDesktops } = options;
  const frontConf = useContext(ConfigContext);
  const { locale } = useContext(LocaleContext);
  // 할당된 VD 목록과 현재 VD 정보
  const [state, dispatch] = useReducer(reducer, {
    desktops: [],
    current: undefined,
    visibleConnHistory: false,
  });
  const { desktops, current } = state;
  const fetchDesktops = useRequest(getAvailableVdGroups, {
    manual: true,
    onSuccess: (data) => {
      let result = data.data;
      if (withActionInDesktops) {
        result = result.map((el) => ({ ...el, actions: getDesktopActions(el, frontConf) }));
      }
      dispatch({ type: 'set-desktops', payload: result.filter((el) => !isInvalidVd(el)) });
    },
  });

  /**
   * 할당받은 데스크탑 목록 중 표시할(=선택된) 데스크탑의 정보(받은 정보 + 접속 기록 + 액션)를 세팅한다.
   * 기준: 선택한 데스크탑 || autoLogin=true인 첫번째 VD || desktops의 첫번째 VD
   * @param {vdInfo}[] desktops 할당된 데스크탑 목록
   * @param {vdInfo} selected 선택된 VD의 데이터
   * @returns {object} || undefined
   */
  const setCurrent = async (selected) => {
    const vd = selected || desktops.find((el) => el.autoLogin) || desktops[0] || undefined;
    // 할당된 VD가 없는 경우 current를 undefined로 세팅한다.
    if (!vd) {
      dispatch({ type: 'set-current', payload: vd });
      return;
    }

    /**
     * #193422#note-2 desktops의 정보는 오래되어 변경되었을 수 있고, 웹소켓으로 받은 데이터로 desktops 정보를 업데이트 할 수 없기 때문에
     * VD 정보를 새로 받아 온 후 접속 기록 + 액션을 추가하여 current로 세팅한다.
     */
    const newVdInfo = await getAvailableVdDetail({ vdGroupId: vd.id, vdId: vd.vdId });
    const isInvalid = !newVdInfo || isInvalidVd(newVdInfo); // 할당되었던 VD가 빠진 경우 refetchVd는 null로 반환된다.
    if (isInvalid) {
      message.info(locale.resource['txt.update-desktops']);
      fetchDesktops.runAsync();
      return;
    }
    const connHistory = await getConnHistory({ vdGroupId: vd.id, vdId: vd.vdId }).then((res) => {
      const result = res.map((el) => ({
        ...el,
        loginDate: moment(el.loginDate).format('YYYY-MM-DD HH:mm:ss'),
        logoutDate: el.logoutDate ? moment(el.logoutDate).format('YYYY-MM-DD HH:mm:ss') : null,
      }));
      return result;
    });
    newVdInfo.connHistory = connHistory;
    newVdInfo.actions = getDesktopActions(newVdInfo, frontConf); // VD에 내릴 접속 및 장애 조치 목록을 추가한다.
    dispatch({ type: 'set-current', payload: newVdInfo });
  };

  // 할당 데스크탑 목록이 바뀌면 current를 다시 세팅한다.
  useEffect(() => {
    setCurrent();
  }, [desktops]);

  /**
   * current가 바뀌면 VD/VD 그룹의 정보를 웹소켓으로 실시간 반영한다.
   * ? broadcast/vd-updated의 result는 변경된 정보만 들어온다.
   * ? 갱신 데이터가 있으면 setCurrent()에서 상세 정보를 다시 조회(getAvailableVdDetail)하기 때문에 그냥 setCurrent로 넘긴다.
   */
  useEffect(() => {
    if (current?.id) {
      listenMessage({ scope: 'common', name: 'broadcast/vd-updated' }, (result) => {
        if (result.data) {
          setCurrent(current);
        }
      });
    }

    // 할당받은 모든 VD 목록(= desktops)에 대해 구독 요청
    const views = state.desktops.map((group) => ({ type: 'group', id: group.id }));
    sendMessage({
      scope: 'common',
      name: 'set-view',
      data: {
        payload: {
          view: current?.vdId ? [...views, { type: 'vd', id: current?.vdId }] : views,
        },
      },
    });
  }, [current]);

  const connect = async (args) => {
    // ! FIXME: websocket기반이 되면서 getXauthFromCookie는 필요없어짐 하지만 없애면 기존 사용자들이 자동 로그인이 풀리기 때문에 토큰을 가져와야함. 추후 해당 형상이 어느정도 안정화 운영이 된다면 cookie에 token이 존재하지 않으니 없애도된다.
    const token = localStorage.getItem('token') || getXauthFromCookie();
    const { vd, isUnassigned, multiMonitors, redirectUrlToVd } = args;
    const target = vd || current; // 접속 대상

    if (token) {
      // 토큰은 존재하나 패스워드가 변경되어 인증에 실패한 경우를 처리
      try {
        await checkAccount(token);

        if (!localStorage.getItem('token')) {
          localStorage.setItem('token', token);
        }
      } catch (e) {
        Modal.warning({
          title: locale.resource['txt.auth-fail'],
          content: locale.resource['txt.session-expired-or-pwd-change'],
          onOk: async () => {
            await accountLogout();
          },
        });
        return;
      }

      const searchParam = {
        'conn-req-url': `${window.location.protocol}//${window.location.host}/portal/api/session/vds/${target.vdId}/connection`,
        'conn-token': token,
        userId: localStorage.getItem('userId'),
        userName: localStorage.getItem('username'),
        adAccount: target.adAccount || null,
        vdIp: target.ipAddress || null,
        vdId: target.vdId,
        vdGroupType: target.groupType,
        vdGroupId: target.groupId || target.id,
        vdGroupName: target.groupName || target.vdGroupName,
        ...(multiMonitors && { 'multi-monitors': true }),
      };

      if (redirectUrlToVd && localStorage.getItem('redirectComplete') !== 'true') {
        searchParam['redirect-url'] = redirectUrlToVd;
      }

      // vsclient에게 넘겨줄 args
      const clientArgs = new URLSearchParams(searchParam).toString();

      // #191683 windows인 clagent로 접속하고 그 외 Protocol Handler로 접속한다. 또한 윈도우가 아니면 url 리다이렉션 기능을 제공하지 않는다.
      if (isWindows) {
        try {
          await vsClagentPortCheck();
          if (redirectUrlToVd && localStorage.getItem('redirectComplete') !== 'true') {
            await VsclientService.redirectUrl(clientArgs);
            localStorage.setItem('redirectComplete', true);
            return;
          }

          await VsclientService.start(clientArgs);
        } catch (e) {
          const agt = await startAgent(6);

          if (
            frontConf.protocolHandler &&
            !redirectUrlToVd &&
            localStorage.getItem('redirectComplete') !== 'true'
          ) {
            window.location = `VdiVsclient:${btoa(clientArgs)}`;
            return;
          }

          // ie 버전에서 axios request가 액세스가 거부되었습니다 에러가 난 경우 window open으로 처리
          const url =
            redirectUrlToVd && localStorage.getItem('redirectComplete') !== 'true'
              ? `http://localhost:${localStorage.getItem(
                  'clagentPort',
                )}/vsclient/redirect-url?args=${btoa(clientArgs)}`
              : `http://localhost:${localStorage.getItem('clagentPort')}/vsclient/start?args=${btoa(
                  clientArgs,
                )}`;

          if (agt) {
            const ret = window.open(url);
            // eslint-disable-next-line no-unused-expressions
            isUnassigned && unassignVds({ vdIds: [target?.vdId] });
            fetchDesktops
              .runAsync()
              .then((res) => dispatch({ type: 'set-desktops', payload: res.data }));

            if (!ret) {
              Modal.warning({
                title: locale.resource['task-result.state.check'],
                content: locale.resource['txt.retry-after-vsclagent-check'],
              });
              return;
            }
            setTimeout(() => {
              ret.close();
            }, 3 * 1000);
          } else {
            Modal.warning({
              title: locale.resource['task-result.state.check'],
              content: locale.resource['txt.retry-after-vsclagent-check'],
            });
          }
        }
      } else {
        //  window.location = `VdiVsclient:${btoa(clientArgs)}`;
        openApp(btoa(clientArgs));
      }
    } else {
      Modal.error({
        title: locale.resource['txt.connect-fail'],
        content: locale.resource['txt.no-login-session'],
      });
    }
  };

  async function onConnect(multiMonitors, redirectUrlToVd, vd) {
    const targetVd = vd || current; // 접속 대상을 지정했으면 대상 VD로 없으면 current로 접속한다.
    // 접속할 수 없는 상태면 에러를 반환한다.
    if (targetVd.actions.connect.disabled) {
      Modal.error({
        title: locale.resource['txt.connect-fail'],
        content: locale.resource['txt.inaccessible-vd-state'],
      });
    }
    if (!targetVd.adAccount) {
      // VD에 할당되어있지 않은 경우 할당 후 접속한다.
      const newVd = await assignVd({ vdGroupId: targetVd.id, vdId: targetVd.vdId });
      const isUnassigned = true;
      // eslint-disable-next-line no-unused-expressions
      newVd.state.endsWith('stopped')
        ? connectByVdStart(newVd, moment().add(3, 'm'), (groupInfo) =>
            connect({
              vd: groupInfo,
              multiMonitors,
              redirectUrlToVd,
            }),
          )
        : connect({
            vd: newVd,
            isUnassigned,
            multiMonitors,
            redirectUrlToVd,
          });
    } else {
      // eslint-disable-next-line no-unused-expressions
      targetVd.state.endsWith('.state.stopped')
        ? connectByVdStart(targetVd, moment().add(3, 'm'), (groupInfo) =>
            connect({
              vd: groupInfo,
              multiMonitors,
              redirectUrlToVd,
            }),
          )
        : connect({
            vd: targetVd,
            multiMonitors,
            redirectUrlToVd,
          });
    }
  }

  /**
   * 데스크탑 명령
   * @param {object} failover current.actions.failovers의 요소가 들어온다.
   */
  function onFailover(failover, vd) {
    const targetVd = vd || current; // 조치 대상을 지정했으면 대상 VD로 없으면 current를 조치한다.
    // 받은 인자(failover)에 사전 정의된 onClick(예: 하나은행 원격지원 서비스)이 있으면 onClick을 실행하고 없으면 requestVdAction API를 실행한다.
    if (failover.onClick) {
      failover.onClick();
      return;
    }

    // 라벨과 API의 cmd키가 맞지 않아 별도 작성함
    const actionMap = {
      'txt.power-on': 'start',
      'txt.vd-soft-reboot': 'soft-reboot',
      'txt.vd-reboot': 'hard-reboot',
    };
    const modal = Modal.confirm({
      title: locale.resource[failover.label],
      content: locale.resource['txt.take-long-time'],
      okText: locale.resource[failover.label],
      onOk: () => {
        modal.update((preConfig) => ({
          ...preConfig,
          cancelButtonProps: { disabled: true },
          okButtonProps: { loading: true },
        }));

        async function executor(resolve, reject) {
          const res = await requestVdAction(targetVd.id, targetVd.vdId, actionMap[failover.label]);
          return res.status === 'success' ? resolve(res.message) : reject(res.message);
        }

        new Promise(executor)
          .then((data) => {
            // #193265 Desktop 재부팅 명령인 경우 OS가 완전히 부팅되기 이전에 접속하는 것을 막기 위해 성공했더라도 경고 문구를 표시한다.
            if (failover.label === 'txt.vd-reboot') {
              modal.update((preConfig) => ({
                ...preConfig,
                title: locale.resource['txt.operation-success'],
                content: (
                  <div style={{ whiteSpace: 'pre-line' }}>
                    {locale.resource['txt.hard-reboot.success-massage']}
                  </div>
                ),
                cancelText: locale.resource['txt.ok'],
                cancelButtonProps: { disabled: false, type: 'primary' },
                okButtonProps: { style: { display: 'none' } },
              }));
            } else {
              modal.destroy();
              message.success(locale.resource[data] || locale.resource['txt.operation-success']);
            }
          })
          .catch((error) => {
            modal.update((preConfig) => ({
              ...preConfig,
              title: locale.resource['txt.operation-failed'],
              content: locale.resource[error],
              cancelText: locale.resource['txt.close'],
              cancelButtonProps: { disabled: false },
              okButtonProps: { style: { display: 'none' } },
            }));
          });
        return true;
      },
    });
  }

  // 자동 접속
  const onAutoConnect = async (endTime, multiMonitors, redirectUrlToVd) => {
    if (!current) {
      return;
    }
    if (!current.isAccessibleIp) {
      notification.warning({
        message: locale.resource['txt.fail-auto-connection'],
        description: locale.resource['txt.inaccessible-ip-msg'],
      });
      return;
    }

    if (localStorage.getItem('autoConnect') === 'true') {
      // 접속 시도 중
      localStorage.setItem('autoConnect', false);
      if (!current.adAccount) {
        // VD에 할당되어있지 않은 경우
        // 1. 할당해라
        // 2. 접속해라
        const vd = await assignVd({ vdGroupId: current.id, vdId: current.vdId });
        if (vd.state.endsWith('stopped')) {
          connectByVdStart(vd, moment().add(3, 'm'), (groupInfo) =>
            connect({
              vd: groupInfo,
              multiMonitors,
              redirectUrlToVd,
            }),
          );
        } else {
          connect(vd);
        }
        return;
      }

      const inaccessible = current?.actions?.connect.disabled; // 접속 가능 상태
      if (current.state === 'vd.state.stopped') {
        connectByVdStart(current, endTime, (groupInfo) =>
          connect({
            vd: groupInfo,
            multiMonitors,
            redirectUrlToVd,
          }),
        );
        // 10초에 한 번씩 get 날려서 VD 상태가 접속 가능한 상태인지 파악
        // 접속 가능해지면 바로 연결
        // 3분이 지나도 연결 가능한 상태가 아니라면 접속 실패 모달 띄움
      } else if (!inaccessible) {
        connect(current);
      }
    }
  };

  return { state, dispatch, fetchDesktops, setCurrent, onConnect, onFailover, onAutoConnect };
}

import { Button, Col, Divider, Row, Space, Typography, Result, Upload, Layout } from 'antd';
import LocaleContext from 'locales';

import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { NavLink } from 'react-router-dom';
import { getDocumentDetail } from 'services/websocket/notice';
import styled from 'styled-components';

function View({ location }) {
  const { locale } = useContext(LocaleContext);
  const [data, setData] = useState();
  const [isActive, setIsActive] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (!location?.state?.guid) {
      return;
    }

    getDocumentDetail(location?.state?.guid).then((doc) => {
      if (doc.status) {
        setData(doc.notice);
        setFileList(
          doc.notice.fileInfo.map((fInfo, i) => ({
            uid: `${i}`,
            name: fInfo.filename,
            status: 'done',
            url: `/portal/api/notice/document/${location?.state?.guid}/download?file=${
              fInfo.hash
            }&token=${localStorage.getItem('token')}`,
            linkProps: { download: fInfo.filename },
            hash: fInfo.hash,
          })),
        );
      } else {
        setIsActive(false);
        setErrorMessage(locale.resource[doc.error]);
      }
    });
  }, [location?.state?.guid]);

  return (
    <Layout.Content>
      {isActive ? (
        <Row gutter={[16, 0]} align="middle">
          <Col span={24}>
            <Title>{data?.title}</Title>
          </Col>
          <Col span={24}>
            <Space size="large">
              {data?.name && (
                <Typography.Text type="secondary">
                  {locale.resource['txt.category']}: {`${data?.name}`}
                </Typography.Text>
              )}
              <Typography.Text type="secondary">
                {locale.resource['txt.writer']}: {`${data?.register}`}
              </Typography.Text>
              <Typography.Text type="secondary">
                {locale.resource['txt.requestered-date']}:{' '}
                {`${moment(data?.createDate).format('YYYY-MM-DD HH:mm:ss')}`}
              </Typography.Text>
            </Space>
          </Col>
          <Col span={24}>
            <Divider />
          </Col>
          <Col span={24}>
            {data?.contents && <ReactQuill theme="bubble" value={data?.contents} readOnly />}
          </Col>
          {fileList.length !== 0 && (
            <Col span={24}>
              <Typography.Title type="secondary" level={5}>
                {locale.resource['txt.attachment']}
              </Typography.Title>
              <Upload
                multiple
                fileList={fileList}
                showUploadList={{
                  showDownloadIcon: true,
                  downloadIcon: 'download',
                  showRemoveIcon: false,
                }}
              />
            </Col>
          )}
          <Col span={24}>
            <Divider />
          </Col>
          <Col span={24}>
            <Button>
              <NavLink to="/help/notice/list">{locale.resource['txt.list']}</NavLink>
            </Button>
          </Col>
        </Row>
      ) : (
        <Result
          status="warning"
          title={errorMessage}
          extra={
            <Button type="primary" key="list">
              <NavLink to="/help/notice/list">{locale.resource['txt.list']}</NavLink>
            </Button>
          }
        />
      )}
    </Layout.Content>
  );
}

const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: -0.02em;
`;

export default View;

import { sendMessage } from 'utils/webSocket';
import { request } from './util';

/*
 * Login
 */
export async function accountLogin(data) {
  return request({
    scope: 'portal',
    name: 'auth/login',
    data,
  });
}

export async function doAutoLogin(token) {
  return request({
    scope: 'portal',
    name: 'auth/auto-login',
    data: { token },
  });
}

/**
 *
 * @returns {Promise<Boolean>}
 */
export async function checkAccount(token) {
  const result = await request({
    scope: 'portal',
    name: 'auth/check-account',
    data: { token },
  });

  if (!result) {
    throw new Error('unauthorized');
  }

  return true;
}

export function deleteToken() {
  sendMessage({
    scope: 'portal',
    name: 'auth/logout',
    data: {
      payload: {
        userId: localStorage.getItem('userId'),
        userName: localStorage.getItem('username'),
      },
    },
  });

  localStorage.removeItem('username');
  localStorage.removeItem('autoLogin');
  localStorage.removeItem('token');
  localStorage.removeItem('password');
}

/*
 * Logout
 */
export function accountLogout() {
  deleteToken();

  sessionStorage.setItem('logout', true);

  window.location = '/portal/auth/login';
}

/**
 *
 * @param {String} userId
 * @returns {Promise<{type: String, message: String, description: String}>}
 */
export async function requestResetPassword(userId) {
  return request({
    scope: 'portal',
    name: 'auth/send-password-reset-mail',
    data: {
      userId,
    },
  });
}

/*
 * 토큰 확인 후 패스워드 변경
 */
export function updatePasswordWithResetToken(token, password) {
  return request({
    scope: 'portal',
    name: 'auth/update-password-with-reset-token',
    data: {
      resetToken: token,
      password,
    },
  });
}

/*
 * ldap 773 옵션(비밀번호 무조건 변경해야하는 경우)
 */
export async function changePassword(payload) {
  return request({
    scope: 'portal',
    name: 'auth/change-password',
    data: payload,
  });
}

export async function getConnHistory(data) {
  return request({
    scope: 'portal',
    name: 'auth/get-connection-history',
    data,
  });
}

// 2factor 인증코드 생성
export async function twoFactorCodeGenerate({ channel, regenerate, loginInfo }) {
  return request({
    scope: 'portal',
    name: 'auth/generate-addtional-auth-code',
    data: {
      channel,
      regenerate,
      ...loginInfo,
    },
  });
}

// 2factor 인증코드 검증
export async function twoFactorAuth(data) {
  return request({
    scope: 'portal',
    name: 'auth/do-additional-auth',
    data,
  });
}

// 2factor OTP 설정 키 저장
export async function saveOtpSecretkey(payload) {
  return request({
    scope: 'portal',
    name: 'auth/save-otp-secretkey',
    data: payload,
  });
}

export async function getRSAKey() {
  return request({
    scope: 'portal',
    name: 'auth/get-rsa-key',
    data: {},
  });
}

export async function checkPreAuth(userId) {
  return request({
    scope: 'portal',
    name: 'auth/pre-check',
    data: {
      userId,
    },
  });
}

// #186818 하나은행 펜타시큐리티 생체 인증 사용자 정보 조회
export async function getBioAuthUser(userId) {
  return request({
    scope: 'portal',
    name: 'auth/check-bio-auth-target',
    data: {
      userId,
    },
  });
}

// 비밀번호 재설정시 인증 채널 목록을 가져온다.
export async function getPasswordResetChannel() {
  return request({
    scope: 'portal',
    name: 'auth/get-password-reset-auth-channel',
    // data
  });
}

// 비밀번호 재설정시 인증 코드 생성을 요청한다.
export async function generatePasswordResetCode(data) {
  return request({
    scope: 'portal',
    name: 'auth/generate-password-reset-code',
    data,
  });
}

// 비밀번호 재설정시 인증 코드 생성을 요청한다.
export async function checkPasswordResetCode(data) {
  return request({
    scope: 'portal',
    name: 'auth/check-password-reset-code',
    data,
  });
}

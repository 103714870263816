import { Typography } from 'antd';
import React from 'react';

/**
 * 반응형 텍스트 말줄임: 부모 컨테이너 넓이의 100% 내에서 말줄임 됨
 * 단 <Table scroll={{ x: 'max-content' }} />인 경우 column에 width를 설정하는게 좋음
 * display: 'table'은 추후 문제될 수도 있으나 현재로서는 반응형 구현을 위한 대안이 없음
 * @param {string} text
 * @returns ReactNode
 */
function TextEllipsis({ text }) {
  return (
    <Typography.Text
      ellipsis={{
        tooltip: text,
        onEllipsis: () => {}, // 이 설정이 없으면 말줄임(...) 표시 안됨
      }}
      style={{ display: 'table', tableLayout: 'fixed', width: '100%', color: 'inherit' }}
    >
      {text}
    </Typography.Text>
  );
}

export default TextEllipsis;

/**
 * ! 신규 사이트 추가시 반드시 아래 문서 업데이트 해주세요.
 * https://gitlab.somansa.com/vdis/docs/-/wikis/%EC%82%AC%EC%9D%B4%ED%8A%B8-%EB%84%A4%EC%9E%84-%EC%A0%95%EC%9D%98
 */

import { getCurrentConfig } from 'services/mgmt';
import { AiFillApi, AiOutlineSync, AiOutlineWarning } from 'react-icons/ai';
import React from 'react';

export const siteName = 'hanabank';
export const preCheck = true;
export const autoLogin = true;

// 접속 가능 Desktops에서 VD에 내릴 커스텀 failovers: #192434
export const desktopFailovers = (desktop) => {
  const { subState, isAccessibleIp, user } = desktop;
  return [
    {
      label: 'txt.vd-reboot',
      icon: <AiOutlineSync />,
      tooltip: 'txt.reboot-for-recovery',
      disabled: !user
        ? true
        : subState.endsWith('.sub-state.migrating') ||
          subState.endsWith('.sub-state.session-timeout') ||
          subState?.endsWith('.sub-state.agent-logoff') ||
          !isAccessibleIp,
    },
    {
      label: 'txt.remote-support',
      icon: <AiOutlineWarning />,
      disabled: false,
      onClick: async () => {
        const res = await getCurrentConfig();
        return window.open(res.features.customRedirect.remoteHelpService, '', '');
      },
    },
  ];
};

// #180565 [하나은행] VD Portal에서 비활성화 VD 표시 및 사용자 안내 문구 추가 건
export const desktopConnect = (desktop) => {
  const { state, subState, isAccessibleIp } = desktop;
  const tooltip = `txt.connect.vd-${state?.split('.').pop()}`;
  return {
    label: 'txt.connect',
    icon: <AiFillApi className="anticon" fontSize={24} />,
    tooltip,
    disabled:
      !(
        state?.endsWith('.state.ready') ||
        state?.endsWith('.state.idle') ||
        state?.endsWith('.state.connected') ||
        state?.endsWith('.state.disconnected') ||
        state?.endsWith('.state.stopped')
      ) ||
      subState?.endsWith('.sub-state.session-timeout') ||
      subState?.endsWith('.sub-state.agent-logoff') ||
      !isAccessibleIp,
  };
};

import { request } from './util';

// Endpoint가 외부에서 접속하는지 판별한다.
/* eslint-disable import/prefer-default-export */
export async function checkExternalAccess() {
  return request({
    scope: 'portal',
    name: 'access/check-external',
    data: {},
  });
}

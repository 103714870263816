/**
 * @type {WebSocket}
 */
let ws = null;

/**
 * websocket message 별로 핸들링하여 처리
 *
 * @type {Object.<string, Object.<string,(result : {data: Any, newTokenInfo: {token:String, expires: Number }}, error ) => vold>>}
 */
const msgCallbackFuncs = {
  portal: {},
  common: {},
};

/**
 * 서버로 부터 메시지를 수신할 때 호출되는 콜백
 *
 * @param {{scope: ('portal' | 'common'), name: String, data: any }} message
 */
function onMessage(message) {
  const parsedMessage = JSON.parse(message.data);

  // 서버로부터 받은 메시지에 대해 콜백함수가 등록되어있다면(listenMessage 함수 참고)
  const callbackFunc = msgCallbackFuncs[parsedMessage.scope]?.[parsedMessage.name];
  if (callbackFunc) {
    callbackFunc(
      { data: parsedMessage.data, newTokenInfo: parsedMessage.newTokenInfo },
      parsedMessage.error,
    );
  }
}

/**
 * 웹소켓을 연결한다.
 *
 * @returns {WebSocket} ws
 */
function getWebSocket() {
  if (!ws || ws.readyState === ws.CLOSING || ws.readyState === ws.CLOSED) {
    const socketProtocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
    ws = new WebSocket(
      process.env.NODE_ENV === 'production'
        ? `${socketProtocol}://${window.location.hostname}/ws/mgmt`
        : `${socketProtocol}://${window.location.hostname}:8000`,
    );

    return new Promise((resolve, reject) => {
      ws.onopen = () => {
        ws.onmessage = onMessage;

        setInterval(() => {
          ws.send(
            JSON.stringify({
              scope: 'portal',
              name: 'socket-check',
            }),
          );
        }, 30 * 1000);

        resolve(ws);
      };

      ws.onerror = (err) => {
        reject(err);
      };

      ws.onclose = () => {
        if (localStorage.getItem('token')) {
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
        ws = null;
      };
    });
  }

  return ws;
}

/**
 * 메시지를 서버에 전송한다.
 *
 * @param {{scope: ('portal' | 'common'), name: String, data: any }} message
 */
export async function sendMessage(message) {
  const webSocket = await getWebSocket();

  if (webSocket && webSocket.readyState === webSocket.OPEN) {
    webSocket.send(JSON.stringify(message));
    return;
  }

  setTimeout(() => {
    if (webSocket && webSocket.readyState === webSocket.OPEN) {
      webSocket.send(JSON.stringify(message));
      return;
    }

    console.error('서버와 통신이되지 않습니다.');
  }, 1000);
}

/**
 * listenMessageName에 해당하는 메시지를 수신하면 callbackFunc을 실행하도록 등록한다.
 *
 * @param {{scope:('portal' | 'common'), name: string}} listenMessageFormat
 * @param {(result : {data: Any, newTokenInfo: {token:String, expires: Number }}, error ) => vold} callbackFunc
 *   - listenMessageName에 대한 메시지를 수신하면 callbackFunc을 실행한다.
 */
export function listenMessage(listenMessageFormat, callbackFunc) {
  msgCallbackFuncs[listenMessageFormat.scope][listenMessageFormat.name] = callbackFunc;
}

export function closeSocket() {
  if (ws.readyState !== ws.CLOSING || ws.readyState !== ws.CLOSED) {
    ws.close();
  }
}

import React, { useContext, useEffect } from 'react';
import LocaleContext from 'locales';
import { Route } from 'react-router-dom';
import { getCurrentConfig } from 'services/mgmt';

const RouteWithLayout = (props) => {
  const {
    layout: Layout,
    component: Component,
    name,
    menuId,
    access,
    componentProps,
    config,
    ...rest
  } = props;

  // const [disabledFeatureMenus, setDisabledFeatureMenus] = useState([]); // 비활성된 기능

  const { locale } = useContext(LocaleContext);

  if (access) {
    // #184958 메뉴 제어와 관련된 기능 목록으로 {configs의 키: menus.key}로 정의한다.
    const featureMenus = { approval: 'request' };

    /**
     * #184958 비활성된 기능 메뉴 목록
     * @return string[]
     */
    useEffect(() => {
      getCurrentConfig()
        .then((configs) => {
          const disabledFeatureMenus = Object.keys(featureMenus).reduce((acc, cur) => {
            return Object.prototype.hasOwnProperty.call(configs, cur)
              ? acc
              : [...acc, featureMenus[cur]];
          }, []);
          const isDisabled = disabledFeatureMenus.filter((el) => access.includes(el)).length !== 0;
          if (isDisabled) {
            window.location = '/portal/error';
          }
        })
        .catch((error) => console.error(error));
    }, []);
  }

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={(matchProps) => (
        <Layout currentPage={locale.resource[name]} menuId={menuId} config={config}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Component {...matchProps} currentPage={name} menuId={menuId} {...componentProps} />
        </Layout>
      )}
    />
  );
};

export default RouteWithLayout;

import checkClientInstall from './check_client_install';
import checkInstall from './vendor-supplied-js/check_fss_version';

// VD-i 필수 다운로드 소프트웨어 정의.. 여기서 설정하면 다운로드 테이블이 바뀐다.
export default {
  requiredProgram: (filter) => {
    let totalProgram = {
      vsclient: {
        title: 'txt.client',
        description: 'txt.install-client',
        downloadPath: '/portal/deploy/vsclient/vsclient-setup.exe',
        onInstallCheck: async () => {
          return [checkClientInstall()];
        },
      },
      // LGD FSS client
      fssClient: {
        title: 'txt.anti-capture',
        description: 'txt.anti-capture-desc',
        downloadPath: '/portal/deploy/FSS_Client_for_LGDISPLAY_VPN_x64.exe',
        onInstallCheck: async (window) => {
          const version = '1.26.0002';

          return checkInstall(window, version);
        },
      },
    };

    // filter로 거른다.
    if (filter || filter.length > 0) {
      totalProgram = Object.fromEntries(
        Object.entries(totalProgram).filter(([k]) => filter.includes(k)),
      );
    }

    return totalProgram;
  },
};

/**
 * 앱 디자인 커스텀
 * src/settings.js 에서 settings.hideMenu에서 설정된다.
 * package.json의 config.preid 설정으로 분기한다.
 */

import { createContext } from 'react';
import packageJson from '../../package.json';
import { settings } from '../settings';

const hideMenu = packageJson.config?.preid
  ? [...settings.hideMenu.default, ...settings.hideMenu[packageJson.config.preid]]
  : settings.hideMenu.default;
const MenuContext = createContext(hideMenu);

export default MenuContext;

import { Button, Card } from 'antd';
import LocaleContext from 'locales';
import React, { useContext, useReducer } from 'react';
import { NavLink } from 'react-router-dom';
import ConfigContext from 'store/ConfigContext';
import Faq from '../../help/Faq';
import NoticeList from '../../help/notice/List';

function reducer(state, action) {
  switch (action.type) {
    case 'tabChange':
      return { activeTabKey: action.key };
    default:
      throw new Error();
  }
}

/**
 * 최근 공지사항/FAQ 목록
 * @returns ReactNode
 */
function RecentNotify() {
  const [state, dispatch] = useReducer(reducer, { activeTabKey: 'notice' });

  const { locale } = useContext(LocaleContext);
  const config = useContext(ConfigContext);

  const tabList = [
    { key: 'notice', tab: locale.resource['txt.notice'], tabPane: <NoticeList recent /> },
    { key: 'FAQ', tab: locale.resource['txt.faq'], tabPane: <Faq recent /> },
  ];

  const TabPane = ({ activeTabKey }) => tabList.find((el) => el.key === activeTabKey).tabPane;

  return (
    <Card
      tabList={tabList}
      activeTabKey={state.activeTabKey}
      onTabChange={(key) => dispatch({ type: 'tabChange', key })}
      tabBarExtraContent={
        !config.siteName?.includes('hanabank') && (
          <NavLink to={state.activeTabKey === 'notice' ? '/help/notice/list' : '/help/faq'}>
            <Button size="small" type="link">
              {locale.resource['txt.more']}
            </Button>
          </NavLink>
        )
      }
    >
      <TabPane activeTabKey={state.activeTabKey} />
    </Card>
  );
}

export default RecentNotify;

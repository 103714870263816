import { Image } from 'antd';
import React, { useContext, useState, useEffect } from 'react';
import ThemeContext from 'store/ThemeContext';

/**
 * 앱 로고이며 무조건 이미지로 처리한다.
 * @param {Number} width 로고의 크기
 * @returns ReactNode
 */
function AppLogo({ width }) {
  const [logo, setLogo] = useState(null);
  const theme = useContext(ThemeContext);

  // 기본 or 커스텀 로고를 가져온다.
  // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
  useEffect(() => {
    (async () => {
      const resource = await import(`images/${theme.logo}`);
      setLogo(resource.default);
    })();

    return () => {
      // setLogo(null);
    };
  }, []);

  return <Image src={logo} preview={false} width={width} />;
}

export default AppLogo;

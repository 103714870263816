import { Card } from 'antd';
import React from 'react';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  .ant-card-head {
    font-size: 13px;
    border-bottom: 0;
  }
  .ant-card-head-title {
    padding: 0;
  }
  .ant-card-body {
    padding: 0;
  }
`;

/**
 * Panel: UI 그룹
 * @param {string} title UI 그룹 제목
 * @param {ReactNode} children 내용
 * @param {object} bodyStyle 카드의 body 스타일
 * @returns ReactNode
 */
function Panel({ title, children, bodyStyle }) {
  return (
    <StyledCard title={title} bordered={false} bodyStyle={bodyStyle}>
      {children}
    </StyledCard>
  );
}

export default Panel;

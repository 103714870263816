import { Alert, Col, Form, InputNumber, List, Row, Table } from 'antd';
import React from 'react';
import { ApprovalProcess, MailCC, OwnDisk, Panel, RequestInfo } from './components';

function DiskUpdate() {
  return (
    <Form layout="horizontal" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Alert
            showIcon
            type="warning"
            message={
              <List
                size="small"
                split={false}
                dataSource={['추가로 용량 변경이 필요한 경우 운영자에게 연락하시기 바랍니다.']}
                renderItem={(item) => <List.Item style={{ padding: 4 }}>{item}</List.Item>}
              />
            }
          />
        </Col>
        <Col span={24}>
          <RequestInfo />
        </Col>
        <Col span={24}>
          <OwnDisk title="Disk 보유 정보" rowSelection={{ type: 'radio' }} />
        </Col>
        <Col span={24}>
          <Panel title="Disk 변경 정보">
            <Table
              size="small"
              rowKey="key"
              dataSource={[
                {
                  key: 1,
                  name: 'LGDKRA.EA팀',
                  volume: 100,
                },
              ]}
              columns={[
                { title: 'Disk 이름', dataIndex: 'name' },
                {
                  title: '용량 변경',
                  dataIndex: 'volume',
                  render: (text) => (
                    <InputNumber addonAfter="GB" defaultValue={text} style={{ width: 124 }} />
                  ),
                },
              ]}
            />
          </Panel>
        </Col>
        <Col span={24}>
          <ApprovalProcess />
        </Col>
        <Col span={24}>
          <MailCC />
        </Col>
      </Row>
    </Form>
  );
}

export default DiskUpdate;

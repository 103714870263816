import {
  Alert,
  Button,
  Form,
  Input,
  Modal,
  Radio,
  Result,
  Space,
  Steps,
  Tooltip,
  Typography,
} from 'antd';
import LocaleContext from 'locales';
import moment from 'moment';
import { getValidationMsgs2, localeKeyToString } from 'pages/utils';
import React, { useContext, useEffect, useState } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import {
  changePassword,
  checkPasswordResetCode,
  generatePasswordResetCode,
  getPasswordResetChannel,
} from 'services/websocket/auth';
import { encryptRSA, getRSAKey } from 'services/auth';
import styled from 'styled-components';

const styles = {
  formHeight: {
    height: 32,
  },
  submintBtn: {
    height: 32,
  },
  spaceBetween: {
    display: 'flex',
    rowGap: 8,
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  remaindTime: {
    display: 'inline-block',
    width: 40,
    textAlign: 'center',
  },
};
const StyledResult = styled(Result)`
  padding: 0px;
  > .ant-result-icon {
    margin-bottom: 8px;
    padding: 0px;
    > svg {
      font-size: 40px;
      color: #52c41a;
    }
  }
  > .ant-result-title {
    font-size: 16px;
    line-height: initial;
  }
`;

/**
 * * FC: 인증 폼 컴포넌트 (Step 1)
 * @returns ReactNode
 */
function AuthForm({ currentStep, setCurrentStep, setUserId }) {
  const {
    locale,
    locale: {
      language: { locale: currentLocale },
    },
  } = useContext(LocaleContext);

  const isKoreanLocale = currentLocale === 'ko';

  const [expired, setExpired] = useState(false); // 인증코드 만료 여부
  const [generated, setGenerated] = useState({ result: false }); // 인증코드 생성 결과 {result: true, expireDate: "2022-05-02T08:56:06.784Z"}

  // * FC: 인증코드 생성 폼 (Step 1-1)
  const GenerateCodeForm = () => {
    // 백엔드와 협의된 인증 채널 목록

    // states
    const [channel, setChannel] = useState([]); // 환경 설정에 설정된 인증 채널 목록
    const [errorMessage, setError] = useState(null); // 에러 메시지

    // 환경 설정에 설정된 인증 채널 정보 요청
    async function requestChannel() {
      try {
        const res = await getPasswordResetChannel();
        const resetChannel =
          res?.map((item) => ({
            label: locale.resource[`txt.${item}`],
            value: item,
          })) || [];
        setChannel(resetChannel);
      } catch (error) {
        console.error(error);
      }
    }
    useEffect(() => {
      if (currentStep === 0) requestChannel();
    }, [currentStep]);
    const onFinish = async (values) => {
      try {
        const res = await generatePasswordResetCode(values);
        if (res.success) {
          setError(null);
          setGenerated({
            result: true,
            expireDate: res.expireDate,
            userId: values.userId,
          });
        } else {
          setError(localeKeyToString(res.errorMessage));
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (channel.length === 0) {
      return (
        <Alert
          showIcon
          message={
            <div style={{ whiteSpace: 'pre-line' }}>
              {locale.resource['txt.no-authentication-channel']}
            </div>
          }
          type="warning"
        />
      );
    }

    return (
      <>
        {errorMessage && (
          <Alert
            showIcon
            message={<div style={{ whiteSpace: 'pre-line' }}>{errorMessage}</div>}
            type="error"
            style={{ marginBottom: 15 }}
          />
        )}
        <Form
          layout="horizontal"
          labelCol={{ span: isKoreanLocale ? 4 : 9 }}
          wrapperCol={{ span: isKoreanLocale ? 20 : 15 }}
          onFinish={onFinish}
        >
          <Form.Item
            label={locale.resource['txt.auth-type']}
            name="channel"
            initialValue={channel[0]?.value}
          >
            <Radio.Group options={channel} />
          </Form.Item>
          <Form.Item
            required
            label={locale.resource['txt.login-id']}
            name="userId"
            rules={[{ required: true, message: locale.resource['txt.enter-id'] }]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            wrapperCol={{ span: isKoreanLocale ? 20 : 24, offset: isKoreanLocale ? 4 : 0 }}
          >
            <Button type="primary" size="large" htmlType="submit" block style={styles.submintBtn}>
              {locale.resource['txt.send-auth-code']}
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  };

  // * FC: 인증코드 입력 폼 (Step 1-2)
  const InputCodeForm = () => {
    const [errorMessage, setError] = useState(null); // 에러 메시지
    const [form] = Form.useForm();

    const onFinish = async (values) => {
      const payload = { ...values, userId: generated.userId };
      try {
        const res = await checkPasswordResetCode(payload);
        if (res.success) {
          setUserId(generated.userId);
          setCurrentStep(1);
          return;
        }
        if (res.errorType === 'general') {
          setError(localeKeyToString(res.errorMessage));
          return;
        }
      } catch (error) {
        console.error(error);
      }
    };

    // 인증코드 재요청
    const onCodeRegenerate = () => {
      setGenerated({ result: false });
      setExpired(false);
      setUserId(null);
    };

    if (expired) {
      return (
        <>
          <Typography.Paragraph type="warning">
            {locale.resource['two-factor-auth.fail.expired']}
          </Typography.Paragraph>
          <Button type="primary" style={styles.submintBtn} block onClick={onCodeRegenerate}>
            {locale.resource['txt.resend-auth-code']}
          </Button>
        </>
      );
    }
    return (
      <>
        {errorMessage && (
          <Alert
            showIcon
            message={<div style={{ whiteSpace: 'pre-line' }}>{errorMessage}</div>}
            type="error"
            style={{ marginBottom: 15 }}
          />
        )}
        <Form onFinish={onFinish} form={form}>
          <Space style={styles.spaceBetween}>
            <Form.Item style={{ flex: 1 }} name="otpNumber">
              <Input
                size="large"
                style={{ width: 300 }}
                placeholder={locale.resource['txt.enter-code']}
                addonAfter={<RemainedTime generated={generated} setExpired={setExpired} />}
              />
            </Form.Item>
            <Button type="text" style={styles.formHeight} onClick={onCodeRegenerate}>
              {locale.resource['txt.resend']}
            </Button>
          </Space>
          <Button type="primary" block style={styles.submintBtn} htmlType="submit">
            {locale.resource['txt.ok']}
          </Button>
        </Form>
      </>
    );
  };

  // FC: 발급된 인증코드의 남은 시간 출력
  const RemainedTime = () => {
    const [result, setResult] = useState(undefined); // 만료시각과 현재시각 비교 값(예: 2:58)

    // 만료시각이 임박했는가?
    const impend = result?.split(':')[0] === '0' && Number(result.split(':')[1]) < 10 && true;

    // generated 되면 인증시간 타이머를 동작시킨다.
    useEffect(() => {
      const timer = setInterval(() => {
        if (generated.result) {
          const expireTime = moment(generated.expireDate); // 서버로 부터 받은 만료 시각
          const now = moment();
          const diff = moment.duration(expireTime.diff(now));

          if (diff.asMilliseconds() > 0) {
            setResult(`${diff.minutes()}:${diff.seconds()}`);
          } else {
            setResult('0:0');
            setExpired(true);
            clearInterval(timer);
          }
        }
        return () => {
          clearInterval(timer);
        };
      }, 1000);
    }, [generated]);

    return (
      <Tooltip title={locale.resource['txt.last-time']}>
        <Typography.Text style={styles.remaindTime} type={impend ? 'danger' : null}>
          {result}
        </Typography.Text>
      </Tooltip>
    );
  };

  return <>{generated.result ? <InputCodeForm /> : <GenerateCodeForm />}</>;
}

/**
 * * FC: 신규 비밀번호 설정 폼(Step 2)
 * @returns ReactNode
 */
function NewPasswordForm({ userId, setCurrentStep, msg }) {
  const {
    locale,
    locale: {
      language: { locale: currentLocale },
    },
  } = useContext(LocaleContext);
  const [errorMessage, setErrorMessage] = useState(null); // 에러 메시지
  const [encryptKey, setEncryptKey] = useState();

  React.useEffect(() => {
    getRSAKey().then(({ data }) => setEncryptKey(data.key));
  }, []);
  const isKoreanLocale = currentLocale === 'ko';

  // refs
  const [form] = Form.useForm();

  const gotoNextStep = () => {
    form.resetFields();
    setCurrentStep((prev) => prev + 1);
  };
  const onFinish = async (values) => {
    try {
      const payload = {
        userId,
        password: encryptRSA(values.newPwd1, encryptKey),
        currentPassword: encryptRSA(values.currentPassword, encryptKey),
      };
      const res = await changePassword(payload);
      if (res.success) {
        gotoNextStep();
        return;
      }
      if (res.errorType === 'general') {
        setErrorMessage(localeKeyToString(res.errorMessage));
        return;
      }
      const errors = getValidationMsgs2(res.data).map((el) => {
        if (el.name[0] === 'password') {
          return { ...el, name: ['newPwd1'] };
        }
        return el;
      });
      form.setFields(errors);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      {msg && <Typography.Paragraph>{locale.resource[msg]}</Typography.Paragraph>}
      {errorMessage && (
        <Alert
          showIcon
          message={<div style={{ whiteSpace: 'pre-line' }}>{errorMessage}</div>}
          type="error"
          style={{ marginBottom: 15 }}
        />
      )}
      <Form
        layout="horizontal"
        labelCol={{ span: isKoreanLocale ? 8 : 10 }}
        wrapperCol={{ span: isKoreanLocale ? 16 : 14 }}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          label={locale.resource['txt.current-password']}
          name="currentPassword"
          rules={[{ required: true, message: locale.resource['txt.enter-password'] }]}
        >
          <Input.Password visibilityToggle={false} allowClear autoComplete="current-password" />
        </Form.Item>
        <Form.Item
          label={locale.resource['txt.new-password']}
          name="newPwd1"
          rules={[
            {
              required: true,
              message: locale.resource['txt.enter-password'],
            },
          ]}
        >
          <Input.Password visibilityToggle={false} allowClear autoComplete="new-password" />
        </Form.Item>
        <Form.Item
          label={locale.resource['txt.confirm-password']}
          name="newPwd2"
          dependencies={['newPwd1']}
          hasFeedback
          rules={[
            { required: true, message: locale.resource['txt.enter-password'] },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue('newPwd1') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(locale.resource['txt.password-error']));
              },
            }),
          ]}
        >
          <Input.Password visibilityToggle={false} allowClear autoComplete="new-password" />
        </Form.Item>
        <Form.Item label={null} wrapperCol={{ span: 16, offset: 8 }}>
          <Button type="primary" htmlType="submit" block style={styles.submintBtn}>
            {locale.resource['txt.reset']}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

/**
 * FC: 비밀번호 재설정
 * @param {object} resetPwdProps {visible}
 * @param {function} setResetPwdProps resetPwdProps 갱신 함수
 * @returns ReactNode
 */
export default function ResetPassword({ resetPwdProps, setResetPwdProps }) {
  const { visible, userId: loggedInUserId, msg } = resetPwdProps;
  const { locale } = React.useContext(LocaleContext);
  // states
  const [currentStep, setCurrentStep] = useState(0); // 현재 진행중인 스탭
  const [userId, setUserId] = useState(null); // 인증받는 사람의 아이디

  // 컴포넌트 초기화
  const onClose = () => {
    setResetPwdProps({ visible: false });
    setCurrentStep(0);
  };

  // Steps 구성을 위한 데이터
  let stepsData = [
    {
      key: 0,
      title: locale.resource['txt.user-authentication'],
      content: (
        <AuthForm currentStep={currentStep} setCurrentStep={setCurrentStep} setUserId={setUserId} />
      ),
    },
    {
      key: 1,
      title: locale.resource['txt.reset-password'],
      content: (
        <NewPasswordForm
          userId={loggedInUserId || userId}
          setCurrentStep={setCurrentStep}
          msg={msg}
        />
      ),
    },
    {
      key: 2,
      title: locale.resource['txt.completed'],
      content: (
        <StyledResult
          status="success"
          title={`${locale.resource['txt.reset-password']} ${locale.resource['txt.completed']}`}
          icon={<AiFillCheckCircle />}
          subTitle={locale.resource['txt.login-with-changed-password']}
          extra={[
            <Button type="primary" key="close" onClick={onClose}>
              {locale.resource['txt.close']}
            </Button>,
          ]}
        />
      ),
    },
  ];

  // * 로그인 성공 -> ldap 773으로 인해 모달이 열리는 경우 [1.사용자 인증] 단계를 생략한다.
  if (loggedInUserId) {
    stepsData.shift(); // 첫 번째 단계를 제거
    stepsData = stepsData.map((item) => {
      return { ...item, key: item.key - 1 }; // 나머지 단계 key를 -1로 설정
    });
  }

  // FC: 스탭으로 구성된 컨텐츠
  const StepContent = () => {
    const current = stepsData.find((el) => el.key === currentStep);
    return (
      <>
        <Steps size="small" current={current.key}>
          {stepsData.map((el) => (
            <Steps.Step title={el.title} key={el.key} />
          ))}
        </Steps>
        <div style={{ margin: '32px 24px', minHeight: 160 }}>{current.content}</div>
      </>
    );
  };

  return (
    <Modal
      title={locale.resource['txt.reset-password']}
      width={440}
      visible={visible}
      onCancel={onClose}
      maskClosable={false}
      footer={false}
    >
      <StepContent />
    </Modal>
  );
}

import { Alert, Button, Col, Form, Image, Input, Row, Space, Typography } from 'antd';
import LocaleContext from 'locales';
import React, { useContext, useState } from 'react';
import { saveOtpSecretkey, twoFactorAuth } from 'services/websocket/auth';

/**
 * 기본 OTP 인증: Google/Microsoft Authenticator
 * @param {object} credential {success(인증 성공 여부), idpw(id/pw 인증 정보), twoFactor}
 * @param {boolean} setCredential
 * @returns ReactNode
 */

function MultiFactor({ credential, setCredential }) {
  const {
    idpw,
    twoFactor: { type, channel = [], data },
  } = credential;
  const [error, setError] = useState(undefined);
  const [isRequireSecretKey, setIsRequireSecretKey] = useState(type === 'require-secret-key'); // OTP 설정 키 설정이 필요한지 여부
  const { locale } = useContext(LocaleContext);
  const isOnlyOTP = channel.length === 1 && channel[0] === 'otp'; // 추후 인증 수단이 여러 개인 경우 선택 UI가 필요함

  // 등록 완료되었음을 서버에 알린다. 서버는 등록 완료 메시지가 왔을 때 /tmp/user-secret-keys 폴더에 id.2fa 형태로 저장한다.
  const onSaveOtpSecretkey = async () => {
    const res = await saveOtpSecretkey({ userId: idpw.userId, secretKey: data?.key });
    if (res.success) {
      setIsRequireSecretKey(false);
    } else {
      setError(res.message);
    }
  };

  // 인증 코드를 검증한다.
  const onFinish = async (values) => {
    setError(undefined); // sumit 할때마다 error를 clear 한다.
    const res = await twoFactorAuth({ ...values, ...idpw });
    if (res.status === 'success') {
      localStorage.setItem('token', res.token);
      setCredential((prev) => ({ ...prev, success: true }));
    } else {
      setError(locale.resource[res.message] || res.error);
    }
  };

  // OTP 설정 컴포넌트
  function SetSecretkey() {
    return (
      <Space direction="vertical" size={0} style={{ alignItems: 'center' }}>
        <Typography.Paragraph style={{ whiteSpace: 'pre-line' }}>
          {locale.resource['two-factor-auth.otp.register-guide']}
        </Typography.Paragraph>
        <Image src={data?.qrcode} width={180} preview={false} />
        <div style={{ wordBreak: 'break-word' }}>
          {locale.resource['two-factor-auth.otp.setup-key']}: {data?.key}
        </div>
        <Button type="primary" style={{ marginTop: 16, width: 240 }} onClick={onSaveOtpSecretkey}>
          {locale.resource['txt.registration-complete']}
        </Button>
      </Space>
    );
  }

  // OTP 인증코드 입력 컴포넌트
  function InputCodeForm() {
    return (
      <Form onFinish={onFinish}>
        <Typography.Paragraph style={{ whiteSpace: 'pre-line' }}>
          {locale.resource['two-factor-auth.otp.require-code']}
        </Typography.Paragraph>
        <Form.Item noStyle name="otpNumber">
          <Input
            size="large"
            placeholder={locale.resource['txt.enter-code']}
            autoFocus
            autoComplete="off"
          />
        </Form.Item>
        <Button type="primary" size="large" block htmlType="submit" style={{ marginTop: 16 }}>
          {locale.resource['txt.ok']}
        </Button>
      </Form>
    );
  }

  if (!isOnlyOTP) {
    return (
      <Alert
        type="error"
        message={
          <div style={{ whiteSpace: 'pre-line' }}>
            {locale.resource['two-factor-auth.fail.environment']}
          </div>
        }
      />
    );
  }

  return (
    <Row gutter={[0, 16]}>
      {error && (
        <Col span={24}>
          <Alert type="error" message={<div style={{ whiteSpace: 'pre-line' }}>{error}</div>} />
        </Col>
      )}
      <Col span={24}>{isRequireSecretKey ? <SetSecretkey /> : <InputCodeForm />}</Col>
    </Row>
  );
}

export default MultiFactor;

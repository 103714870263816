import { Alert, Col, Form, List, Row } from 'antd';
import React from 'react';
import { ApprovalProcess, MailCC, OwnDisk, RequestInfo } from './components';

function DiskDelete() {
  return (
    <Form layout="horizontal" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Alert
            showIcon
            type="warning"
            message={
              <List
                size="small"
                split={false}
                dataSource={[
                  '필요한 데이터를 모두 로컬PC로 다운로드 완료하셨나요?',
                  '승인완료 후 Desktop은 사용이 불가능하므로, 신청하시기 전에 필요한 데이터를 모두 다운로드 받으신 후 신청하시기 바랍니다.',
                ]}
                renderItem={(item) => <List.Item style={{ padding: 4 }}>{item}</List.Item>}
              />
            }
          />
        </Col>
        <Col span={24}>
          <RequestInfo />
        </Col>
        <Col span={24}>
          <OwnDisk title="Disk 삭제 정보" rowSelection={{ type: 'radio' }} />
        </Col>
        <Col span={24}>
          <ApprovalProcess />
        </Col>
        <Col span={24}>
          <MailCC />
        </Col>
      </Row>
    </Form>
  );
}

export default DiskDelete;

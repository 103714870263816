import { Alert, Col, Form, List, Row } from 'antd';
import React from 'react';
import { ApprovalProcess, OwnDisk, RequestInfo } from './components';

function DiskPermission() {
  return (
    <Form layout="horizontal" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Alert
            showIcon
            type="warning"
            message={
              <List
                size="small"
                split={false}
                dataSource={[
                  '작업 완료 후 Desktop을 리부팅해야만 신청하신 Disk 접속이 가능합니다.',
                ]}
                renderItem={(item) => <List.Item style={{ padding: 4 }}>{item}</List.Item>}
              />
            }
          />
        </Col>
        <Col span={24}>
          <RequestInfo />
        </Col>
        <Col span={24}>
          <OwnDisk title="Disk 보유 정보" rowSelection={{ type: 'radio' }} />
        </Col>
        <Col span={24}>
          <ApprovalProcess />
        </Col>
      </Row>
    </Form>
  );
}

export default DiskPermission;

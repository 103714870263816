/* eslint-disable camelcase */
// domain id (통합sso일 때만 기입)
// 사용자 id
// 사용자 password

const { default: axios } = require('axios');

// sso class id (전용sso일 때만 기입)
async function loginFasooSSO(domain_id, id, password, class_id) {
  if (id === '' || password === '') return false;

  // url 프로토콜
  let mainPort = 2457;
  let protocol = 'http://';

  if (window.location.href.indexOf('https://') !== -1) {
    mainPort = 12457;
    protocol = 'https://';
  } else {
    mainPort = 2457;
    protocol = 'http://';
  }

  // ID, PW encoding
  const base64_id = btoa(id);
  const base64_pw = btoa(password);

  // 전달할 msg
  const msg =
    // eslint-disable-next-line prefer-template
    '@CMD:LOGINSSO' +
    '@P1:' +
    domain_id +
    '@P2:' +
    base64_id +
    '@P3:' +
    base64_pw +
    '@P4:' +
    class_id;

  // eslint-disable-next-line prefer-template
  const url = protocol + 'localhost:' + mainPort + '/' + msg;

  return (await axios.get(url)).status === 200;
}

export default loginFasooSSO;

import { Button, Col, Grid, Layout, Row, Spin } from 'antd';
import { Icon } from 'components';
import LocaleContext from 'locales';
import { ClientUpdateCheck } from 'pages';
import React, { useContext, useEffect } from 'react';
import { MdFileDownload } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { getRequieredProgram } from 'services/websocket/session';
import ConfigContext from 'store/ConfigContext';
import { mediaScreen } from 'utils';
import { PromiseAllSettled } from 'utils/Polyfills';
import ReqAppConfig from '../config/required_app.config';
import { isWindows, vsClagentPortCheck } from '../utils';
import { RecentNotify, ViewCarousel, ViewList } from './components';
import { DesktopsContainer, StyledResult } from './components/styles';
import ViewSimple from './components/ViewSimple';

const { useBreakpoint } = Grid;

// 플수 프로그램 체크중일 때 표시되는 컴포넌트
function CheckingRequiredApps({ loading, locale }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 220 }}>
      <Spin spinning={loading} tip={locale.resource['txt.checking-required-program']} />
    </div>
  );
}

// 필수 프로그램이 설치되지 않았을 때 표시되는 컴포넌트
function NotInstalledRequiredApps({ locale }) {
  return (
    <StyledResult
      status="error"
      title={locale.resource['txt.not-install-required-program']}
      subTitle={locale.resource['txt.refresh-after-install']}
      extra={[
        <NavLink to="/help/download" key="download">
          <Button type="primary" icon={<Icon name={<MdFileDownload />} />}>
            {locale.resource['txt.download-program']}
          </Button>
        </NavLink>,
      ]}
    />
  );
}

function Desktops({ location, config: backConf }) {
  const [RequiredAppsInstalled, setRequiredAppsInstalled] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const { locale } = useContext(LocaleContext);
  const { defaultDesktopViewer } = useContext(ConfigContext);
  const { redirectUrlToVd } = location;
  const isAutoConnect = backConf?.application.vsclient.autoConnect;
  const breakpoint = useBreakpoint();
  const screens = mediaScreen(breakpoint);

  useEffect(() => {
    setLoading(true);
    // mgmt로 부터 현재 확인해야하는 프로그램 목록을 가져온다.
    const loadingFunc = async () => {
      if (isWindows) {
        await vsClagentPortCheck();
      }
      const programs = await getRequieredProgram();
      return programs;
    };

    loadingFunc().then((programs) => {
      const dataLoading = Object.entries(ReqAppConfig.requiredProgram(programs)).map(
        async ([_, v]) => {
          const state = v.onInstallCheck
            ? (await PromiseAllSettled(await v.onInstallCheck(window)))
                .map((result) => result.value)
                .some(Boolean)
            : true;

          return state;
        },
      );

      PromiseAllSettled(dataLoading).then((data) => {
        // 모든 필수 항목들이 설치되어있는지 확인
        setRequiredAppsInstalled(data.map((d) => d.value).every(Boolean));
        setLoading(false);
      });
    });
  }, []);

  const DesktopViewer = () => {
    if (screens.isMobile) {
      return <ViewSimple redirectUrlToVd={redirectUrlToVd} />;
    }
    if (defaultDesktopViewer === 'carousel') {
      return <ViewCarousel redirectUrlToVd={redirectUrlToVd} isAutoConnect={isAutoConnect} />;
    }
    return <ViewList redirectUrlToVd={redirectUrlToVd} isAutoConnect={isAutoConnect} />;
  };

  return (
    <Layout.Content style={{ padding: 0 }}>
      <Row>
        <Col span={24}>
          <DesktopsContainer>
            {isWindows && <ClientUpdateCheck />}
            <div className="desktopsContainer__wrap">
              {loading ? (
                <CheckingRequiredApps loading={loading} locale={locale} />
              ) : (
                <>
                  {!RequiredAppsInstalled ? (
                    <NotInstalledRequiredApps locale={locale} />
                  ) : (
                    <DesktopViewer />
                  )}
                </>
              )}
            </div>
          </DesktopsContainer>
        </Col>
        <Col span={24} style={{ padding: 32, maxWidth: 980 }}>
          <RecentNotify />
        </Col>
      </Row>
    </Layout.Content>
  );
}

export default Desktops;

import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import packageJson from '../../package.json';
import lgeCWCLoginBg from '../images/login_bg_lgeCWC.jpg';

const LoginStyle = styled.div`
  height: 100vh;
  background-color: ${(props) => {
    const custom = props.custom[packageJson?.config?.preid];
    return custom ? custom.backgroundColor : '#ecf1f6';
  }};
  background-image: ${(props) => {
    const custom = props.custom[packageJson?.config?.preid];
    return custom ? `url(${custom.backgroundImage})` : 'none';
  }};
  background-repeat: ${(props) => {
    const custom = props.custom[packageJson?.config?.preid];
    return custom ? custom.backgroundRepeat : 'repeat';
  }};
  background-size: ${(props) => {
    const custom = props.custom[packageJson?.config?.preid];
    return custom ? custom.backgroundSize : 'auto auto';
  }};
  background-position: ${(props) => {
    const custom = props.custom[packageJson?.config?.preid];
    return custom ? custom.backgroundPosition : '0% 0%';
  }};
`;

const loginStyleCustom = {
  lgeCWC: {
    backgroundColor: '#f5f5f5',
    backgroundImage: lgeCWCLoginBg,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
};

export default function Blank(props) {
  const isLoginPage = window.location.pathname === '/portal/auth/login'; // 로그인 페이지인지 판별

  const { children } = props;
  return (
    <Layout.Content style={{ height: '100vh', padding: 0 }}>
      {isLoginPage ? <LoginStyle custom={loginStyleCustom}>{children}</LoginStyle> : children}
    </Layout.Content>
  );
}

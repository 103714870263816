import axios from 'axios';
import request from './request';

/**
 * NOTE args를 Base64로 encoding 해서 vsclient를 실행한다. 상세 내용은 vsclient의 AppArg class의 주석 참고.
 * client는 post/get 구분 안하고 받고 있으나 get으로 할 경우 IE 캐싱 문제로 동일 VD접속이 가능해짐 > post로 변경함
 */
export async function start(args) {
  return request.post(
    `http://localhost:${localStorage.getItem('clagentPort')}/vsclient/start?args=${btoa(args)}`,
  );
}

export async function redirectUrl(args) {
  return request.post(
    `http://localhost:${localStorage.getItem('clagentPort')}/vsclient/redirect-url?args=${btoa(
      args,
    )}`,
  );
}

export async function isUpdate() {
  // 업데이트 agent
  return request.post(`http://localhost:61821/`);
}

export async function isRunning() {
  return request.post(`http://localhost:${localStorage.getItem('clagentPort')}/vsclient/running`);
}

/**
 * vsclagent port 체크 함수
 * request로 체크하기에는 timeout 호출전 network error가 떨어져 axios로 직접 호출
 */
export async function checkClagent(port) {
  return axios.post(`http://localhost:${port}/`, null, { timeout: 500 });
}

export async function getClientRegistry(regKeys) {
  return request.get(
    `http://localhost:${localStorage.getItem('clagentPort')}/registry?keys=${encodeURIComponent(
      regKeys.join(','),
    )}`,
  );
}

export function dummyForPreventDefaultExportError() {}

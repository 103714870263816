import { Col, Row } from 'antd';
import { AppCopyright, AppLogo } from 'components';
import React from 'react';
import { Link } from 'react-router-dom';
import AppNavigation from './AppNavigation';

export default function AppSider({ menuId, screens, isSiderCollapsed, setVisibleDrawer }) {
  return (
    <Row style={{ flexFlow: 'column nowrap', height: '100%' }}>
      <Col flex="80px" style={{ display: 'flex', alignItems: 'center', padding: '0 24px' }}>
        {(!isSiderCollapsed || screens?.isMobile) && (
          <Link to="/desktops">
            <AppLogo />
          </Link>
        )}
      </Col>
      <Col flex="auto">
        <AppNavigation menuId={menuId} setVisibleDrawer={setVisibleDrawer} />
      </Col>
      {!isSiderCollapsed && (
        <Col flex="48px" style={{ textAlign: 'center' }}>
          <AppCopyright />
        </Col>
      )}
    </Row>
  );
}

import { Button, Menu, Result } from 'antd';
import styled from 'styled-components';
import monitor2 from '../../../images/monitor2.png';

export const DesktopsContainer = styled.div`
  background-color: #ecf1f6;
  padding: 32px;
  .desktopsContainer__wrap {
    max-width: 916px; // 아래 공지사항의 width와 맞춤
  }
`;

export const StyledResult = styled(Result)`
  padding: 32px;
  height: 220px;
  .ant-result-icon {
    margin-bottom: 8px;
  }
`;

export const VdMenu = styled(Menu)`
  &.ant-menu,
  .ant-menu-sub,
  &.ant-menu .ant-menu-sub {
    width: 200px;
    max-height: 200px;
    overflow-y: auto;
    background-color: #ecf1f6;
  }
`;

export const Monitor = styled.div`
  position: relative;
  width: ${(props) => (props.screens.isTablet ? '240px' : '326px')};
  height: ${(props) => (props.screens.isTablet ? '198px' : '270px')};
  background-image: url(${monitor2});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  padding-block-end: ${(props) => (props.screens.isTablet ? '58px' : '80px')};
  align-items: center;
  justify-content: center;
  gap: 16px;
  .desktopMessage {
    margin-bottom: 0;
    text-align: center;
    color: #ffffff;
    white-space: pre-line;
    line-height: 1;
  }
  .failoverDropdown {
    position: absolute;
    right: 4px;
    bottom: ${(props) => (props.screens.isTablet ? '34px' : '50px')};
  }
`;

export const ConnectBtn = styled(Button)`
  display: inline-flex;
  padding: 8px 40px;
  height: auto;
  border-color: rgba(255, 255, 255, 0.08);
  background-color: rgba(255, 255, 255, 0.16);
  color: #ffffff;
  font-weight: 500;
  &:hover,
  &:focus {
    color: #1890ff;
    border-color: #ffffff;
    background-color: #ffffff;
    box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
  }
  /* TODO: Styled-component의 props 선언하는 방법으로 변경 + CSS 명시도 문제 해결 필요  */
  ${(props) =>
    props.disabled &&
    `background-color: rgba(0, 0, 0, 0.16) !important;
    border-color: rgba(0, 0, 0, 0.32) !important;
    color: #ffffff !important;
    opacity: 0.6 !important;
    `}
`;

export const StyledBtn = styled(Button)`
  width: 156px;
  height: 56px;
  text-align: left;
  padding: 3px 24px;
  .anticon {
    font-size: 1.4em;
    vertical-align: middle;
  }
`;

import { request } from './util';

// VD-i 통합 버전 정보 조회
// eslint-disable-next-line import/prefer-default-export
export async function getIntegratedPackageVersion() {
  return request({
    scope: 'portal',
    name: 'common/integrated-package-version',
    data: {},
  });
}

/* eslint-disable no-underscore-dangle */
import { Alert, Button, Col, Form, Input, Radio, Row, Typography } from 'antd';
import LocaleContext from 'locales';
import moment from 'moment';
import loginFasooSSO from 'pages/config/vendor-supplied-js/fsw_weblogin';
import React, { useContext, useEffect, useState } from 'react';
import { twoFactorAuth, twoFactorCodeGenerate } from 'services/websocket/auth';

const styles = {
  formHeight: {
    height: 40,
  },
  spaceBetween: {
    display: 'flex',
    marginBlock: 4,
    gap: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  remaindTime: {
    display: 'inline-block',
    width: 32,
    textAlign: 'center',
  },
};

/**
 * Multi-Factor 인증
 * @param {object} credential {success(인증 성공 여부), idpw(id/pw 인증 정보), twoFactor}
 * @param {boolean} setCredential
 * @returns ReactNode
 */

function MultiFactor({ credential, setCredential }) {
  const { idpw, twoFactor } = credential;
  const { channel = [] } = twoFactor;

  // states
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [generated, setGenerated] = useState(false); // 인증코드 생성 결과 {result: true, expireDate: "2022-05-02T08:56:06.784Z"}
  const [expired, setExpired] = useState(false); // 인증코드 만료 여부

  // context
  const { locale } = useContext(LocaleContext);

  useEffect(() => {
    if (expired) setError(locale.resource['txt.auth-expired']);
  }, [expired]);

  useEffect(() => {
    if (twoFactor.data.userToken) {
      localStorage.setItem('sTokenKey', twoFactor.data.userToken);
    }
  }, []);

  // FC: 인증코드 생성 요청 폼
  const CodeGenerateForm = () => {
    const onFinish = async (values) => {
      setError(undefined); // sumit 할때마다 error를 clear 한다.
      setLoading(true);
      try {
        const res = await twoFactorCodeGenerate({ ...values, loginInfo: idpw });
        if (res.result) {
          setGenerated(res);
        } else {
          setError(locale.resource[res.msg]);
        }
      } catch (e) {
        setError(e?.response?.data?.msg);
      }
      setLoading(false);
    };

    // 인증 방식 옵션들
    const channelOptions = () =>
      channel.map((el) => ({ label: locale.resource[`txt.${el}`], value: el }));

    return (
      <Form style={{ width: '100%' }} onFinish={onFinish}>
        <div>{locale.resource['txt.two-factor']}</div>
        <Form.Item
          label={locale.resource['txt.auth-type']}
          name="channel"
          initialValue={channel.length && channel[0]}
          style={{ height: 26, marginBlock: 4 }}
        >
          <Radio.Group options={channelOptions()} />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block
          style={{ ...styles.formHeight, marginTop: 24 }}
          loading={loading}
        >
          {locale.resource['txt.send-auth-code']}
        </Button>
      </Form>
    );
  };

  // FC: 인증코드 입력 폼
  const InputCodeForm = () => {
    const onFinish = async (values) => {
      setError(undefined); // sumit 할때마다 error를 clear 한다.

      const res = await twoFactorAuth({ ...values, ...idpw });
      if (res.status === 'success') {
        localStorage.setItem('token', res.token);
        loginFasooSSO('0000000000013470', idpw.userId, localStorage.getItem('sTokenKey'), '');
        setCredential((prev) => ({ ...prev, success: true }));
      } else {
        setError(locale.resource[res.message] || res.error);
      }
    };

    // 인증코드 재요청
    const onCodeRegenerate = () => {
      setError(undefined);
      setGenerated(false);
      setExpired(false);
    };

    return (
      <Form style={{ width: '100%' }} onFinish={onFinish}>
        <div>{locale.resource['txt.enter-auth-code']}</div>
        <div style={styles.spaceBetween}>
          <Form.Item noStyle style={{ flex: 1 }} name="authCode">
            <Input
              size="large"
              style={{ flex: 1 }}
              placeholder={locale.resource['txt.enter-code']}
              disabled={expired}
              addonAfter={<RemainedTime generated={generated} setExpired={setExpired} />}
            />
          </Form.Item>
          <Button
            size="large"
            style={{ fontSize: 12 }}
            type={expired ? 'primary' : 'default'}
            onClick={onCodeRegenerate}
          >
            {locale.resource['txt.resend']}
          </Button>
        </div>
        <Button
          type="primary"
          block
          htmlType="submit"
          style={{ ...styles.formHeight, marginTop: 16 }}
          disabled={expired}
        >
          {locale.resource['txt.ok']}
        </Button>
      </Form>
    );
  };

  return (
    <Row gutter={[0, 16]}>
      {error && (
        <Col span={24}>
          <Alert type="error" message={<div style={{ whiteSpace: 'pre-line' }}>{error}</div>} />
        </Col>
      )}
      <Col span={24}>{generated.result ? <InputCodeForm /> : <CodeGenerateForm />}</Col>
    </Row>
  );
}

/**
 * 발급된 인증코드의 남은 시간을 리턴하는 함수
 * 인증코드 유효 기간(3분) 동안 1초마다 렌더링 되기 때문에 별도 Component로 뺌
 * @param {boolean} generated 인증코드 발급 여부에 따라 타이머를 동작시킨다.
 * @param {boolean} setExpired 인증코드의 유효 시간이 만료되었는지 판별한다.
 * @returns ReactNode
 */
function RemainedTime({ generated, setExpired }) {
  const [result, setResult] = useState(undefined); // 만료시각과 현재시각 비교 값(예: 2:58)

  // 만료시각이 임박했는가?
  const impend = result?.split(':')[0] === '0' && Number(result.split(':')[1]) < 10 && true;

  // generated 되면 인증시간 타이머를 동작시킨다.
  useEffect(() => {
    const timer = setInterval(() => {
      if (generated.result) {
        const expireTime = moment(generated.expireDate); // 서버로 부터 받은 만료 시각
        const now = moment();
        const diff = moment.duration(expireTime.diff(now));

        if (diff._milliseconds > 0) {
          setResult(`${diff._data.minutes}:${diff._data.seconds}`);
        } else {
          setResult('0:0');
          setExpired(true);
          clearInterval(timer);
        }
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [generated]);

  return (
    <Typography.Text style={styles.remaindTime} type={impend ? 'danger' : null}>
      {result}
    </Typography.Text>
  );
}

export default MultiFactor;

import { createContext } from 'react';

const LocaleContext = createContext({
  locale: { resource: {}, language: {}, param: 'ko-KR' },
  setLocale: () => {},
});

export const currentLocale = {};

export default LocaleContext;
// async function changeLocal e(param) {
//   const res = await axios.get(`/portal/api/locale/res/string?locale=${param}`);
//   Object.assign(locale, res.data);
//   Object.assign(language, lang() === 'ko-KR' ? koKR2 : enUS2);
// }

// changeLocale(lang());

// 참고: 자바스크립트 특성 상 axios 호출은 한번만 실행됨

// export { locale, language, changeLocale };

import { Button } from 'antd';
import React from 'react';
import { AiOutlineReload } from 'react-icons/ai';
import { StyledResult } from './styles';

/**
 * 사용할 수 있는 데스크탑이 없는 경우 표시
 * @param {object} locale 로케일 정보
 * @param {object} fetchDesktops useRequest(할당된 VD 목록 조회)
 * @returns ReactNode
 */
function NoAvailableDesktops({ locale, fetchDesktops }) {
  return (
    <StyledResult
      status="info"
      title={<div style={{ fontSize: 16 }}>{locale.resource['txt.no-available-desktops']}</div>}
      extra={[
        <Button
          key="refresh"
          onClick={() => fetchDesktops.runAsync()}
          icon={<AiOutlineReload className="anticon" />}
          loading={fetchDesktops.loading}
        >
          {locale.resource['txt.refresh']}
        </Button>,
      ]}
    />
  );
}

export default NoAvailableDesktops;

import { Form, Input, Typography } from 'antd';
import LocaleContext from 'locales';
import React, { useContext, useEffect, useState } from 'react';

function RequestInfo({ readOnly }) {
  const { locale } = useContext(LocaleContext);

  const Reason = ({ readOnly1, value, onChange }) => {
    const [text, setText] = useState('');

    useEffect(() => {
      setText(value);
    }, [value]);

    return readOnly1 ? (
      <Typography.Text style={{ whiteSpace: 'pre-line' }}> {text}</Typography.Text>
    ) : (
      <Input.TextArea
        value={text}
        onBlur={() => {
          onChange(text);
        }}
        onChange={(e) => setText(e.target.value)}
      />
    );
  };

  const Applicant = ({ value }) => {
    const name = localStorage.getItem('username');
    return <Typography.Text> {`${name}( ${value} )`}</Typography.Text>;
  };
  return (
    <>
      <Form.Item label={locale.resource['txt.applicant']} name="applicant" required>
        <Applicant />
      </Form.Item>
      {/* TODO: 신청일은 RUD 일때만 보이기 */}
      {readOnly && (
        <Form.Item label={locale.resource['txt.requested-date']} name="applyDate" required>
          <Input readOnly={readOnly} bordered={false} />
        </Form.Item>
      )}
      <Form.Item
        label={locale.resource['txt.reason']}
        name="reason"
        rules={[{ required: true, message: locale.resource['txt.required-field'] }]}
      >
        <Reason readOnly1={readOnly} />
      </Form.Item>
    </>
  );
}

export default RequestInfo;

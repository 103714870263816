import { Layout, Result } from 'antd';
import React from 'react';

function Error() {
  return (
    <Layout.Content>
      <Result status="warning" title="There are some problems with your operation." />
    </Layout.Content>
  );
}

export default Error;

import { Grid, Menu } from 'antd';
import { Icon } from 'components';
import LocaleContext from 'locales';
import React, { useContext, useEffect, useState } from 'react';
import {
  AiOutlineCustomerService,
  AiOutlineDesktop,
  AiOutlineDownload,
  AiOutlineFileText,
  AiOutlineNotification,
  AiOutlineQuestionCircle,
  AiOutlineSnippets,
} from 'react-icons/ai';
import { NavLink, useLocation } from 'react-router-dom';
import { getCurrentConfig } from 'services/mgmt';
import MenuContext from 'store/MenuContext';
import { mediaScreen } from 'utils';
import { sendMessage } from 'utils/webSocket';

const { useBreakpoint } = Grid;

// FIXME: 메뉴 구조인데 라우터랑 묶어 별도 관리 필요

function AppNavigation({ setVisibleDrawer }) {
  const [selectedKeys, setSelectedKeys] = useState([]); // 현재 선택된 메뉴
  const [disabledFeatureMenus, setDisabledFeatureMenus] = useState([]); // 비활성된 기능
  const { locale } = useContext(LocaleContext);
  const breakpoint = useBreakpoint();
  const screens = mediaScreen(breakpoint);

  const hideMenus = useContext(MenuContext); // 숨길 메뉴

  // #184958 메뉴 제어와 관련된 기능 목록으로 {configs의 키: menus.key}로 정의한다.
  const featureMenus = { approval: 'request' };

  /**
   * #184958 비활성된 기능 메뉴 목록
   * @return string[]
   */
  useEffect(() => {
    getCurrentConfig()
      .then((configs) => {
        setDisabledFeatureMenus(
          Object.keys(featureMenus).reduce((acc, cur) => {
            return Object.prototype.hasOwnProperty.call(configs, cur)
              ? acc
              : [...acc, featureMenus[cur]];
          }, []),
        );
      })
      .catch((error) => console.error(error));
  }, []);

  const menus = [
    {
      key: 'desktops',
      name: 'Desktops',
      to: '/desktops',
      icon: <Icon name={<AiOutlineDesktop />} />,
    },
    {
      key: 'transfer',
      name: locale.resource['txt.file-download'],
      to: '/transfer',
      icon: <Icon name={<AiOutlineFileText />} />,
    },
    {
      key: 'request',
      name: locale.resource['txt.request-approval'],
      to: '/request',
      icon: <Icon name={<AiOutlineSnippets />} />,
    },
    {
      key: 'help',
      name: 'Help Center',
      icon: <Icon name={<AiOutlineCustomerService />} />,
      children: [
        {
          key: 'notice',
          name: locale.resource['txt.notice'],
          to: '/help/notice/list',
          icon: <Icon name={<AiOutlineNotification />} />,
        },
        {
          key: 'faq',
          name: locale.resource['txt.faq'],
          to: '/help/faq',
          icon: <Icon name={<AiOutlineQuestionCircle />} />,
        },
        {
          key: 'download',
          name: locale.resource['txt.download'],
          to: '/help/download',
          icon: <Icon name={<AiOutlineDownload />} />,
        },
      ],
    },
  ];
  // 메뉴 숨김 처리
  const filteredhideMenus = menus.filter(
    (menu) => !hideMenus.includes(menu.key) && !disabledFeatureMenus.includes(menu.key),
  );

  // FIXME: 현재 페이지를 네비게이션에 표시하기 위한 임시 조치
  const currentUrl = useLocation().pathname;
  const currentUrlSplit = currentUrl.split('/');
  let currentPage;
  if (currentUrlSplit.includes('help')) {
    /* eslint-disable prefer-destructuring */
    currentPage = currentUrlSplit[2];
  } else {
    currentPage = currentUrlSplit[1];
  }
  useEffect(() => {
    sendMessage({
      scope: 'common',
      name: 'set-view',
      data: {
        payload: {
          view: null,
        },
      },
    });

    setSelectedKeys(currentPage);
  }, [useLocation().pathname]);

  // 메뉴 클릭 시
  const onMenuClick = () => {
    if (screens.isMobile) {
      setVisibleDrawer(false);
    }
  };

  return (
    <>
      <Menu
        defaultSelectedKeys="desktops"
        selectedKeys={selectedKeys}
        mode="inline"
        style={{
          backgroundColor: 'transparent',
          borderRightColor: 'transparent',
        }}
      >
        {filteredhideMenus.map((menu) => {
          let MenuItem;
          if (menu.children) {
            MenuItem = (
              <Menu.SubMenu key={menu.key} icon={menu.icon} title={menu.name}>
                {menu.children.map((childrenMenu) => (
                  <Menu.Item
                    key={childrenMenu.key}
                    icon={childrenMenu.icon}
                    style={{
                      display: childrenMenu.isHide && 'none',
                      height: 48,
                      lineHeight: '48px',
                    }}
                    onClick={onMenuClick}
                  >
                    <NavLink to={childrenMenu.to}>{childrenMenu.name}</NavLink>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            );
          } else {
            MenuItem = (
              <Menu.Item
                key={menu.key}
                icon={menu.icon}
                style={{ display: menu.isHide && 'none', height: 48, lineHeight: '48px' }}
                onClick={onMenuClick}
              >
                <NavLink to={menu.to}>{menu.name}</NavLink>
              </Menu.Item>
            );
          }
          return MenuItem;
        })}
      </Menu>
    </>
  );
}

export default AppNavigation;

/* eslint-disable no-console */
import { Modal } from 'antd';
import { currentLocale } from 'locales';
import { doLogout } from 'utils';
import { listenMessage, sendMessage } from 'utils/webSocket';
import React from 'react';

/**
 * 웹소켓 request/response 기반 api 요청
 * @param {{scope: ('portal' | 'common'), name: String, data: Object.<string, Any>}} message
 *   * data는 빈 object더라도 오브젝트가 들어와야한다. 그 이유는 sendMessage 호출 전에 data에다가 token과
 *   * userId를 실어서 보내기 때문이다.
 * @returns {Promise<Any>}
 */
// eslint-disable-next-line import/prefer-default-export
export function request(message) {
  sendMessage({
    ...message,
    data: {
      payload: {
        ...message.data,
      },
      token: message?.data?.token || localStorage.getItem('token'),
    },
  });

  return new Promise((resolve, reject) => {
    listenMessage(message, (result, error) => {
      if (error) {
        if (error === 'txt.auth-fail') {
          Modal.warning({
            title: currentLocale[error],
            content: currentLocale['txt.session-expired-or-pwd-change'],
            onOk: () => {
              doLogout();
            },
          });
        }
        Modal.error({
          title: `${currentLocale['txt.invalid-request']}`,
          content: <div style={{ whiteSpace: 'pre-line' }}>{currentLocale[error] || error}</div>,
        });
        reject(error);
        return;
      }
      if (result?.newTokenInfo?.token) {
        localStorage.setItem('token', result?.newTokenInfo.token);
      }
      resolve(result.data);
    });
  });
}

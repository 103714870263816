import React, { Button, Space } from 'antd';
import moment from 'moment';

/**
 * +n시간 프리셋 버튼 컴포넌트
 * @param {Object} props
 * @param {Array} props.hours 시간 목록
 * @param {Function} props.onSelect 시간 선택 시 실행할 함수
 * @returns {JSX.Element} +n시간 버튼 목록
 */
function TimePreset({ hours = [1, 3, 6, 12], onSelect }) {
  const addHoursFromNow = (n) => moment().add(n, 'hours').set('second', 0);
  const humanize = (n) => moment.duration(n, 'hour').humanize();
  return (
    <Space>
      {hours.map((h) => (
        <Button size="small" key={h} onClick={() => onSelect(addHoursFromNow(h))}>
          +{humanize(h)}
        </Button>
      ))}
    </Space>
  );
}

export default TimePreset;

import { Modal } from 'antd';

import { currentLocale } from 'locales';
import { doLogout } from 'utils';
import { listenMessage, sendMessage } from 'utils/webSocket';

export const sessionVar = {
  sessionAlive: true,
  warnTimeout: null,
  logoutTimeout: null,
  aliveChecker: false,
};

function asleep(ms) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  });
}

function sessionEvent() {
  sessionVar.sessionAlive = true;
}

function addSessionEvent() {
  ['click', 'scroll', 'keypress'].forEach((event) => {
    window.addEventListener(event, sessionEvent);
  });
}

function removeSessionEvent() {
  ['click', 'scroll', 'keypress'].forEach((event) => {
    window.removeEventListener(event, sessionEvent);
  });
}

function clearSessionTimeout() {
  if (sessionVar.warnTimeout) clearTimeout(sessionVar.warnTimeout);
  if (sessionVar.logoutTimeout) clearTimeout(sessionVar.logoutTimeout);
}

function destroySession() {
  sessionVar.sessionAlive = false;
  removeSessionEvent();
  clearSessionTimeout();
  doLogout();
}

function updateSession() {
  // TODO service로 옮겨서 api로 처리할 수 있나 ? api 구현 완료하고나서 세션타임아웃에 대해서 자세히 보자
  sendMessage({
    scope: 'portal',
    name: 'auth/check-token',
    data: {
      token: localStorage.getItem('token'),
    },
  });

  listenMessage({ scope: 'portal', name: 'auth/check-token' }, (result) => {
    if (!result.newTokenInfo && localStorage.getItem('token')) {
      destroySession();
      return;
    }

    if (result.newTokenInfo) {
      localStorage.setItem('token', result.newTokenInfo.token);

      // eslint-disable-next-line no-use-before-define
      sessionTimeout((result.newTokenInfo.expires - 30) * 1000, result.newTokenInfo.expires * 1000);
      sessionVar.sessionAlive = false;
    }
  });
}

function sessionTimeout(warnTime, logoutTime) {
  clearSessionTimeout();

  sessionVar.warnTimeout = setTimeout(() => {
    removeSessionEvent();

    Modal.confirm({
      title: currentLocale['txt.session-expiration'],
      content: currentLocale['txt.session-extend'],
      okText: currentLocale['txt.extension'],
      cancelText: currentLocale['txt.logout'],
      onOk: () => {
        clearSessionTimeout();
        updateSession();
        addSessionEvent();
      },
      onCancel: () => {
        destroySession();
      },
    });
  }, warnTime);

  sessionVar.logoutTimeout = setTimeout(() => {
    Modal.destroyAll();
    destroySession();
  }, logoutTime);
}

/*
 * 1분마다 한 번씩 sessionArrive flag를 확인하여 세션을 연장해주거나 만료시킴
 */
// eslint-disable-next-line no-unused-vars, import/prefer-default-export
export async function startSessionTimer() {
  sessionVar.aliveChecker = true;
  addSessionEvent();

  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (sessionVar.sessionAlive && localStorage.getItem('token')) {
      updateSession();
    }
    // eslint-disable-next-line no-await-in-loop
    await asleep(60 * 1000);
  }
}

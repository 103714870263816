import { Form, Input, Spin } from 'antd';
import { Icon } from 'components';
import LocaleContext from 'locales';
import React, { useContext, useEffect, useState } from 'react';
import { BiTimer } from 'react-icons/bi';
import { getCurrentConfig } from 'services/mgmt';
import { checkExternalAccess } from 'services/websocket/access';

/**
 *  로그인 페이지의 추가 폼
 *  #188759 [LG전자] OTP 연동 개발요청의 건
 * @returns ReactNode
 */
function LoginExtraForms() {
  const { locale } = useContext(LocaleContext);
  const [loading, setLoading] = useState(false);
  const [isExternalAccess, setIsExternalAccess] = useState(false); // 외부 접속인지 여부

  // 외부접속 체크
  // ? API는 공통 API이지만, 불필요 요청을 줄이기 위해 본 컴포넌트에서 호출한다. 추후 외부접속 체크가 많아지면 API 요청을 상위 컴포넌트로 옮긴다.
  useEffect(async () => {
    const { authentication } = await getCurrentConfig();
    const isTwoFactorAuth =
      authentication.twoFactorAuth.use && authentication.twoFactorAuth.target.includes('portal');

    if (isTwoFactorAuth) {
      setLoading(true);
      try {
        const res = await checkExternalAccess();
        setIsExternalAccess(res);
      } catch (error) {
        console.error('외부 접속 확인 실패: ', error);
      }
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
        <Spin spinning={loading} size="small" style={{ lineHeight: 1 }} />
        <span>{locale.resource['txt.checking-external-access']}</span>
      </div>
    );
  }

  return isExternalAccess ? (
    <Form.Item
      name="otpNumber"
      rules={[{ required: true, message: locale.resource['txt.required-field'] }]}
    >
      <Input
        allowClear
        prefix={<Icon name={<BiTimer focusable={false} />} />}
        style={{ height: 40 }}
        placeholder={locale.resource['txt.otp-number']}
      />
    </Form.Item>
  ) : null;
}

export default LoginExtraForms;

import { Modal } from 'antd';
import axios from 'axios';
import { currentLocale } from 'locales';
import { doLogout } from 'utils';

/**
 * fn: axios.(get | post | put | delete)
 */
function withErrorHandler(fn) {
  return async (...args) => {
    try {
      // Success
      const res = await fn(...args);
      return res;
    } catch (err) {
      if (err.response) {
        const { status } = err.response;

        if (status === 401) {
          // 로그인 페이지에 있지 않으면 인증 실패 창을 띄우고 로그인 페이지로 간다
          if (!document.location.href.split('?')[0].endsWith('/auth/login')) {
            // sessionAlive = false;
            // removeSessionEvent();
            // clearSessionTimeout();

            Modal.warning({
              title: currentLocale['txt.auth-fail'],
              content: currentLocale['txt.session-expired-or-pwd-change'],
              onOk: () => {
                doLogout();
              },
            });
          }
        } else {
          const msg = err.response.data?.message ? `${err.response.data.message}` : '';

          if (msg) {
            Modal.error({
              title: `${currentLocale['txt.invalid-request']} ${status}`,
              content: `${currentLocale[msg]}`,
            });
          }
        }
      } else {
        console.error(err);
      }

      throw err;
    }
  };
}

async function request(url, payload, method) {
  const res = await withErrorHandler(axios[method])(url, payload);

  return res;
}

['get', 'post', 'delete', 'put', 'patch'].forEach(
  // eslint-disable-next-line no-return-assign
  (method) => (request[method] = (url, payload) => request(url, payload, method)),
);

export default request;

import { Table } from 'antd';
import React from 'react';
import Panel from './Panel';

function MailCC() {
  return (
    <Panel title="참조 메일 수신자">
      <Table
        size="small"
        pagination={false}
        rowKey="key"
        dataSource={[
          {
            key: 1,
            type: 'TO',
            desc: '수신자',
            user: '임민준/(사원)/EA팀',
          },
          {
            key: 2,
            type: 'TO',
            desc: '수신자',
            user: '최민서/(사원)/EA팀',
          },
          {
            key: 3,
            type: 'TO',
            desc: '수신자',
            user: '류수빈/(사원)/EA팀',
          },
          {
            key: 4,
            type: 'TO',
            desc: '수신자',
            user: '류우진/(사원)/EA팀',
          },
          {
            key: 5,
            type: 'TO',
            desc: '수신자',
            user: '박서윤/(사원)/EA팀',
          },
        ]}
        columns={[
          {
            title: '유형',
            dataIndex: 'type',
            width: 60,
          },
          {
            title: '설명',
            dataIndex: 'desc',
            width: 120,
          },
          {
            title: '사용자',
            dataIndex: 'user',
          },
        ]}
      />
    </Panel>
  );
}

export default MailCC;

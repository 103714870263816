import React, { useEffect, useState } from 'react';
import ClientUpdateCheck from './ClientUpdateCheck';

function ClientUpdateCheckInURLRedirection({ history, location }) {
  const redirectUrl = decodeURIComponent(location?.search?.split('?url=')[1]);
  const [end, setEnd] = useState(false);

  useEffect(() => {
    if (end) {
      history.push(`/url-redirect?url=${redirectUrl}`);
    }
  }, [end]);

  return (
    <ClientUpdateCheck
      onEnd={(isEnd) => {
        setEnd(isEnd);
      }}
    />
  );
}

export default ClientUpdateCheckInURLRedirection;

/* eslint-disable react/jsx-props-no-spreading */
import {
  Col,
  Collapse,
  Divider,
  Empty,
  Input,
  Layout,
  message,
  Pagination,
  Result,
  Row,
  Select,
  Space,
  Spin,
  Typography,
  Upload,
} from 'antd';
import LocaleContext from 'locales';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getCategories, getDocumentDetail, getDocuments } from 'services/websocket/notice';
import packageJson from '../../../package.json';

function List({ docs, ...rest }) {
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [fileList, setFileList] = useState([]);

  const { locale } = useContext(LocaleContext);
  return (
    <>
      {docs.length !== 0 ? (
        <Collapse
          {...rest}
          accordion
          onChange={async (guid) => {
            if (!guid) {
              return;
            }
            setLoading(true);
            const faq = await getDocumentDetail(guid);
            // 해당 게시글이 존재하지 않거나 비활성일 때
            if (faq.status === false) {
              message.error(locale.resource[faq.error]);
              setError(true);
            } else {
              setFileList(
                faq.notice.fileInfo.map((fInfo, i) => ({
                  uid: `${i}`,
                  name: fInfo.filename,
                  status: 'done',
                  url: `/portal/api/notice/document/${guid}/download?file=${
                    fInfo.hash
                  }&token=${localStorage.getItem('token')}`,
                  linkProps: { download: fInfo.filename },
                  hash: fInfo.hash,
                })),
              );
              if (faq.notice) {
                setError(false);
                setValue(faq.notice.contents);
              } else {
                setValue(faq.error);
                setError(true);
              }
            }
            setLoading(false);
          }}
        >
          {docs?.map((item) => (
            <>
              <Collapse.Panel header={item.title} key={item.guid}>
                <Spin spinning={loading}>
                  {!isError ? (
                    <>
                      <ReactQuill theme="bubble" value={!loading && value} readOnly />
                      {fileList.length !== 0 && (
                        <>
                          <Divider />
                          <Typography.Title type="secondary" level={5}>
                            {locale.resource['txt.attachment']}
                          </Typography.Title>
                          <Upload
                            multiple
                            fileList={fileList}
                            showUploadList={{
                              showDownloadIcon: true,
                              downloadIcon: 'download',
                              showRemoveIcon: false,
                            }}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <Result status="warning" title={!loading && value} />
                  )}
                </Spin>
              </Collapse.Panel>
            </>
          ))}
        </Collapse>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
}

function Faq({ recent }) {
  const isHanabank =
    packageJson.config?.preid === 'hanabank' || packageJson.config?.preid === 'hanabank-dev';
  const disablePaging = recent && !isHanabank;
  const { locale } = useContext(LocaleContext);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [docs, setDocs] = useState([]);
  const [searchVal, setSearchVal] = useState({ key: 'title', value: '' });
  const [paginationInfo, setPaginationInfo] = useState({
    current: 1,
    pageSize: disablePaging ? 5 : 10,
    type: 'faq',
  });
  const [loading, setLoading] = useState(false); // 데이터 로딩 중인지 판별
  let isPageSizeChanged = false; // 페이지네이션의 페이징 사이즈를 바꿨는지 판별하는 것으로 페이지사이즈 변경시 1페이지로 가기 위함

  const [reRendering, setRerendering] = useState(true);

  useEffect(() => {
    if (!reRendering) {
      return;
    }

    const params = {
      ..._.pickBy(
        paginationInfo,
        (__, key) => key !== 'data' && key !== 'success' && key !== 'total',
      ),
      type: 'faq',
    };

    if (searchVal.value) {
      params[searchVal.key] = searchVal.value;
    }

    if (selectedCategory) {
      params.categoryId = selectedCategory;
    }

    setLoading(true);
    getDocuments(params).then((documents) => {
      setDocs(documents.data);
      setLoading(false);
      setPaginationInfo(_.pickBy(documents, (__, key) => key !== 'data' && key !== 'success'));
      setRerendering(false);
    });
  }, [reRendering]);

  useEffect(() => {
    getCategories({ type: 'faq' }).then((data) => {
      let result = [];
      result = data.map((item) => ({ label: item.name, value: item.categoryId }));
      setCategories(result);
      setSelectedCategory(null);
    });
  }, []);

  return recent ? (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <Spin spinning={loading}>
          <List docs={docs} />
        </Spin>
      </Col>
      {docs.length === 0 || disablePaging ? null : (
        <Col span={24}>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Pagination
              size="small"
              {...paginationInfo}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} ${locale.resource['txt.per-page']} ${total} ${locale.resource['txt.total-items']}`
              }
              onChange={(current, pageSize) => {
                if (!isPageSizeChanged) {
                  setPaginationInfo((prev) => ({ ...prev, current, pageSize }));
                  setRerendering(true);
                }
              }}
              showSizeChanger
              onShowSizeChange={(current, pageSize) => {
                isPageSizeChanged = true;
                setPaginationInfo((prev) => ({ ...prev, current: 1, pageSize }));
                setRerendering(true);
              }}
            />
          </div>
        </Col>
      )}
    </Row>
  ) : (
    <Layout.Content>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Space>
            <Select
              size="large"
              style={{ width: 160 }}
              defaultValue={null}
              options={[{ label: locale.resource['txt.total'], value: null }, ...categories]}
              onChange={(value) => {
                setSelectedCategory(value);
                setRerendering(true);
              }}
            />
            <Input.Group compact style={{ display: 'flex' }}>
              <Select
                size="large"
                defaultValue={searchVal?.key}
                style={{ width: 100 }}
                options={[{ label: locale.resource['txt.title'], value: 'title' }]}
                onChange={(value) => {
                  setSearchVal((prev) => ({ ...prev, key: value }));
                }}
              />
              <Input.Search
                size="large"
                allowClear
                placeholder={locale.resource['txt.search']}
                onSearch={(val) => {
                  setSearchVal((prev) => ({ ...prev, value: val }));
                  setRerendering(true);
                }}
              />
            </Input.Group>
          </Space>
        </Col>
        <Col span={24}>
          <Spin spinning={loading}>
            <List docs={docs} />
          </Spin>
        </Col>
        {docs.length === 0 || disablePaging ? null : (
          <Col span={24}>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Pagination
                size="small"
                {...paginationInfo}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} ${locale.resource['txt.per-page']} ${total} ${locale.resource['txt.total-items']}`
                }
                onChange={(current, pageSize) => {
                  if (!isPageSizeChanged) {
                    setPaginationInfo((prev) => ({ ...prev, current, pageSize }));
                    setRerendering(true);
                  }
                }}
                showSizeChanger
                onShowSizeChange={(current, pageSize) => {
                  isPageSizeChanged = true;
                  setPaginationInfo((prev) => ({ ...prev, current: 1, pageSize }));
                  setRerendering(true);
                }}
              />
            </div>
          </Col>
        )}
      </Row>
    </Layout.Content>
  );
}

export default Faq;
export { List as FaqList };

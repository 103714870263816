import { Button, Input, Select, Space, Table } from 'antd';
import React from 'react';
import SelectHR from './SelectHR';

function ApprovalProcess() {
  const [selectUserModal, setSelectUserModal] = React.useState({ undefined });
  return (
    <Table
      size="small"
      pagination={false}
      rowKey="key"
      dataSource={[
        {
          key: 1,
          step: 1,
          type: '확정',
          whenApproval: '2021-10-16 09:15:26',
          state: '확정 대기중',
          detail: '',
        },
        {
          key: 2,
          step: 2,
          type: '결재',
          whenApproval: '',
          state: '결재 대기중',
          detail: 'Cloud담당자/선임/류은주',
          preset: true,
        },
        {
          key: 3,
          step: 3,
          type: '확정',
          whenApproval: '',
          state: '확정 대기중',
          detail: 'Cloud담당자/책임/박예준',
          preset: true,
        },
      ]}
      columns={[
        {
          title: '단계',
          dataIndex: 'step',
          width: 60,
          align: 'right',
        },
        {
          title: '유형',
          dataIndex: 'type',
          render: (text, record) => {
            return record.preset ? (
              <span>{text}</span>
            ) : (
              // 행안부 행정기관의 업무관리시스템 규격에서의 결재 유형: 기안, 검토, 협조, 병렬협조, 결재, 전결, 대결, 전대결
              // https://www.mois.go.kr/cmm/fms/FileDown.do?atchFileId=FILE_00085247IZElzWD&fileSn=1
              <Select defaultValue="결재">
                <Select.Option value="확정">확정</Select.Option>
                <Select.Option value="결재">결재</Select.Option>
                <Select.Option value="검토">검토</Select.Option>
              </Select>
            );
          },
        },
        {
          title: '결재 일시',
          dataIndex: 'whenApproval',
        },
        {
          title: '상태',
          dataIndex: 'state',
        },
        {
          title: '상세',
          dataIndex: 'detail',
        },
        {
          title: '결재선 선택',
          dataIndex: '',
          render: (text, record) => {
            return record.preset ? null : (
              <Space>
                <Input.Search
                  style={{ width: 140 }}
                  onSearch={() => setSelectUserModal({ title: '결재자 선택', visible: true })}
                />
                <SelectHR
                  title={selectUserModal?.title}
                  visible={selectUserModal?.visible}
                  onCancel={() => setSelectUserModal(undefined)}
                  onOk={() => setSelectUserModal(undefined)}
                />
                <Button size="small" type="link">
                  추가
                </Button>
                <Button size="small" type="link">
                  삭제
                </Button>
              </Space>
            );
          },
        },
      ]}
    />
  );
}

export default ApprovalProcess;

import { Col, Form, Row } from 'antd';
import React from 'react';

function FileDownloadLarge() {
  return (
    <Form layout="horizontal" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
      <Row gutter={[0, 24]}>
        <Col span={24}>FileDownloadLarge</Col>
      </Row>
    </Form>
  );
}

export default FileDownloadLarge;

import { Drawer, Grid, Layout, Modal } from 'antd';
import LocaleContext from 'locales';
import React, { useContext, useState } from 'react';
import { doLogout, mediaScreen } from 'utils';
import { AppHeader, AppSider } from './components';

const { useBreakpoint } = Grid;

function unauthorized(locale) {
  Modal.warning({
    title: locale.resource['txt.auth-fail'],
    content: locale.resource['txt.session-expired'],
    okText: locale.resource['txt.login-ko'],
    onOk: () => {
      doLogout();
    },
  });
}

/**
 * 기본 레이아웃
 *   - Layout.Content를 각 페이지에 넣을 경우 컴포넌트 재활용성이 떨어지기 때문에 레이아웃에 넣음
 * @returns ReactNode
 */
export default function Basic(props) {
  // hooks
  const { locale } = useContext(LocaleContext);
  const breakpoint = useBreakpoint();

  // vars
  const { children, menuId, currentPage, config } = props;
  const screens = mediaScreen(breakpoint);
  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('token');

  // states
  const [isSiderCollapsed, setIsSiderCollapsed] = useState(screens.isTablet); // 모바일 화면이 아닌 경우 Layout.Sider.collapsed
  const [visibleDrawer, setVisibleDrawer] = useState(false); // 모바일 화면인 경우 Drawer.visible

  return (
    <>
      {!token ? (
        unauthorized(locale)
      ) : (
        <Layout style={{ height: '100vh' }}>
          {!screens.isMobile && (
            <Layout.Sider
              theme="light"
              width={224}
              collapsible
              collapsedWidth={48}
              collapsed={isSiderCollapsed}
              breakpoint="lg"
              onCollapse={(collapsed) => setIsSiderCollapsed(collapsed)}
            >
              <AppSider menuId={menuId} isSiderCollapsed={isSiderCollapsed} />
            </Layout.Sider>
          )}
          <Layout>
            <AppHeader
              user={userId}
              history={children.props.history}
              currentPage={currentPage}
              config={config}
              screens={screens}
              setVisibleDrawer={setVisibleDrawer}
            />
            {children}
          </Layout>
          <Drawer visible={visibleDrawer} placement="left" onClose={() => setVisibleDrawer(false)}>
            <AppSider
              menuId={menuId}
              screens={screens}
              isSiderCollapsed={isSiderCollapsed}
              setVisibleDrawer={setVisibleDrawer}
            />
          </Drawer>
        </Layout>
      )}
    </>
  );
}

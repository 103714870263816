import { Avatar, Button, Card, Col, Divider, Dropdown, Menu, Row, Space, Typography } from 'antd';
import { Icon } from 'components';
import { useDesktops } from 'hooks/useDesktops';
import LocaleContext from 'locales';
import { displaySize } from 'pages/utils';
import React, { useContext, useEffect } from 'react';
import { AiFillApi, AiOutlineDesktop, AiOutlineEllipsis } from 'react-icons/ai';
import NoAvailableDesktops from './NoAvailableDesktops';
import RenderTags from './RenderTags';

/**
 * 모바일 전용 접속 가능 데스크탑 UI
 * ! isAutoConnect는 필요없을 것으로 보여 구현 안함
 * @param {string} redirectUrlToVd 브라우저의 window.location값으로 URL Redirection을 위한 url
 * @returns ReactNode
 */
export default function ViewSimple({ redirectUrlToVd }) {
  const { locale } = useContext(LocaleContext);
  const { state, fetchDesktops, onConnect, onFailover } = useDesktops({
    withActionInDesktops: true,
  });
  const { desktops } = state;
  // 초기 렌더링 및 새로고침 시 할당된 VD 목록을 가져온다.
  useEffect(() => fetchDesktops.runAsync(), []);

  if (desktops.length === 0) {
    return <NoAvailableDesktops locale={locale} fetchDesktops={fetchDesktops} />;
  }

  return (
    <div>
      <Typography.Title level={5}>{locale.resource['txt.available-desktops']}</Typography.Title>
      <Row gutter={[8, 8]}>
        {desktops.map((el) => {
          return (
            <Col span={24} key={el.adAccount + el.vdGroupName}>
              <Card
                bordered={false}
                actions={[
                  <Button
                    block
                    type="link"
                    disabled={el.actions.connect.disabled}
                    icon={<Icon name={<AiFillApi className="anticon" />} />}
                    onClick={() => onConnect(undefined, redirectUrlToVd, el)}
                  >
                    {locale.resource['txt.connect']}
                  </Button>,
                ]}
                style={{ boxShadow: '1px 1px 2px rgba(0, 42, 88, 0.16)' }}
              >
                <Card.Meta
                  title={
                    <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div>{el.adAccount || el.vdGroupName}</div>
                      <div>
                        <Dropdown
                          overlay={() => {
                            const menuItems = el.actions.failovers.map((item) => {
                              return (
                                <Menu.Item
                                  key={item.label}
                                  disabled={item.disabled}
                                  onClick={() => onFailover(item, el)}
                                >
                                  {locale.resource[item.label]}
                                </Menu.Item>
                              );
                            });
                            return <Menu>{menuItems}</Menu>;
                          }}
                        >
                          <Button type="link" icon={<Icon name={<AiOutlineEllipsis />} />} />
                        </Dropdown>
                      </div>
                    </Space>
                  }
                  description={
                    <Space direction="vertical">
                      <RenderTags desktop={el} locale={locale} />
                      <Space split={<Divider type="vertical" />}>
                        <div>
                          {el.cpus}
                          {locale.resource['txt.core']}/{displaySize(el.ram).size}
                          {displaySize(el.ram).unit}/{el.size}
                          {locale.resource['txt.gb']}
                        </div>
                        <div>{el.os}</div>
                      </Space>
                    </Space>
                  }
                  avatar={<Avatar size={40} icon={<Icon name={<AiOutlineDesktop />} />} />}
                />
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

import enUS2 from 'antd/es/locale/en_US';
import koKR2 from 'antd/es/locale/ko_KR';
import axios from 'axios';
import LocaleContext, { currentLocale } from 'locales';
import 'moment/locale/ko';
import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import { sessionVar, startSessionTimer } from 'services/sessionTimer';
import packageJson, { homepage as routerBase } from '../package.json';
import './App.less';
import Routes from './Routes';
import { settings } from './settings';
import ThemeContext from './store/ThemeContext';

moment.locale('en');

const theme = packageJson.config?.preid
  ? {
      ...settings.preference.default,
      ...settings.preference[packageJson.config.preid],
    }
  : settings.preference.default;

const lang = () => {
  let defaultLang = localStorage.getItem('locale') || navigator.language;
  if (defaultLang === 'ko' || defaultLang === 'ko-KR') {
    defaultLang = 'ko-KR';
  } else {
    defaultLang = 'en-US';
  }
  return defaultLang;
};

function App() {
  const [locale, setLocale] = useState({ resource: {}, language: {}, param: lang() });

  const [config, setConfig] = useState(undefined);

  useEffect(() => {
    if (!sessionVar.aliveChecker && localStorage.getItem('token')) {
      startSessionTimer();
    }

    if (!locale.param) {
      return;
    }

    const params = locale.param;

    axios.get(`/portal/api/locale/res/string?locale=${params}`).then((res) => {
      const language = params === 'ko-KR' ? koKR2 : enUS2;
      setLocale({ resource: { ...res.data }, language });

      // eslint-disable-next-line no-return-assign
      Object.entries(res.data)?.forEach(([k, v]) => (currentLocale[k] = v));
    });

    axios
      .get(`/portal/api/preferences/current-config`, {
        // params: { key: 'config.features.bio-auth' },
      })
      .then((res) => {
        setConfig(res.data);
      });

    if (params === 'ko-KR') {
      moment.locale('ko');
    } else {
      moment.locale('en');
    }
  }, [locale?.param]);

  return (
    <LocaleContext.Provider
      value={{
        locale,
        setLocale: (param) => {
          setLocale((prev) => ({ ...prev, param }));
        },
      }}
    >
      <ConfigProvider
        getPopupContainer={(triggerNode) => {
          if (
            triggerNode?.tagName === 'svg' ||
            triggerNode?.className.includes('dropdown') ||
            triggerNode?.className.includes('select') ||
            triggerNode?.className.includes('picker')
          ) {
            return triggerNode?.parentNode;
          }
          return document.body;
        }}
        locale={locale.language}
      >
        <BrowserRouter basename={routerBase}>
          <ThemeContext.Provider value={theme}>
            <Routes config={config} />
          </ThemeContext.Provider>
        </BrowserRouter>
      </ConfigProvider>
    </LocaleContext.Provider>
  );
}

export default App;

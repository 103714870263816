import { Space, Spin, Typography } from 'antd';
import LocaleContext from 'locales';
import { isWindows, vsClagentPortCheck } from 'pages/utils';
import React, { useContext, useEffect } from 'react';
import { getClientRegistry } from 'services/vsclient';
import { checkPreAuth, doAutoLogin, getBioAuthUser } from 'services/websocket/auth';
import ConfigContext from 'store/ConfigContext';

/**
 * 하나은행 커스텀 컴포넌트
 *
 * 생체인증을 위해 빈페이지를 띄운 뒤 id 체크를 진행한다
 * 1. 토큰이 있으면 로그인페이지로 리다이렉션
 * 2. 토큰이 없으면 clagnet에게 id 요청 후 id를 mgmt에게 보내 인증 요청
 * @returns
 */
function PreCheck({ history, location, config }) {
  const fconfig = useContext(ConfigContext);
  const { locale } = useContext(LocaleContext);

  const redirectLoginPage = (message = undefined) => {
    localStorage.removeItem('token');

    history.push({
      pathname: '/auth/login',
      message,
      preChecked: true,

      redirectUrlToVd: location?.redirectUrlToVd,
    });
  };

  const redirectDesktopPage = () => {
    localStorage.setItem('autoConnect', true);
    localStorage.setItem('autoLogin', true);
    localStorage.setItem('redirectComplete', false);

    history.push({
      pathname: '/desktops',
      redirectUrlToVd: location?.redirectUrlToVd,
    });
  };

  const requestBioAuth = async (userId) => {
    try {
      const bioAuthRes = await checkPreAuth(userId);

      return bioAuthRes;
    } catch (e) {
      return { success: false, message: 'txt.bio-auth-msg.conn-refuesed' };
    }
  };

  const checkBioAuthTarget = async (userId) => {
    try {
      const bioAuthUserRes = await getBioAuthUser(userId);

      if (bioAuthUserRes.success) {
        const { registeredQr, registeredOtp, registeredFace, registeredPattern } =
          bioAuthUserRes.data?.user;

        const isRequireBioAuth =
          registeredQr || registeredOtp || registeredFace || registeredPattern;

        return { isRequireBioAuth, message: '' };
      }

      return { isRequireBioAuth: false, message: bioAuthUserRes.message };
    } catch (e) {
      console.error(e.stack || e);
      return { isRequireBioAuth: false, message: e.message };
    }
  };

  // TODO #191683 모바일 접속인 경우 다른 방안 필요
  const requestUserIdAtRegistry = async () => {
    const regKeys = [
      'HKEY_CURRENT_USER\\Volatile Environment\\USERNAME',
      'HKEY_LOCAL_MACHINE\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\Authentication\\LogonUI\\LastLoggedOnDisplayName',
    ];

    try {
      if (isWindows) {
        await vsClagentPortCheck();
      }
      const userIdRequestRes = await getClientRegistry(regKeys);

      const userId = userIdRequestRes.data[regKeys[0]];
      const userName = userIdRequestRes.data[regKeys[1]];

      // clagent에서 userId를 찾지 못했다면 생체인증 대상자 판별이 불가하니 아니니 그냥 로그인페이지로 보낸다.
      if (!userId) {
        localStorage.setItem('autoLogin', 'false');
        redirectLoginPage();
        return null;
      }

      return { userId, userName };
    } catch (e) {
      redirectLoginPage('txt.check-required-program');

      return null;
    }
  };

  const tryBioAuth = async () => {
    const userData = await requestUserIdAtRegistry();

    if (!userData) {
      return null;
    }
    const { userId } = userData;
    localStorage.setItem('userId', userId);
    localStorage.setItem('latestLocalUser', userId);

    const { isRequireBioAuth, message } = await checkBioAuthTarget(userId);

    // 생체인증 대상자 여부 기록
    localStorage.setItem('bioAuth', !!isRequireBioAuth);

    if (!isRequireBioAuth) {
      return { success: false, message };
    }

    const bioAuthResult = await requestBioAuth(userId);

    if (!bioAuthResult.success) {
      return { success: bioAuthResult.success, message: bioAuthResult.message };
    }

    return { success: bioAuthResult.success, token: bioAuthResult.token };
  };

  /**
   * 자동 로그인 전 로컬 PC 사용자가 토큰 발급 사용자와 일치하는지 확인한다.
   * 최근 로그인 사용자와 현재 로컬 유저가 같으면 true
   */
  const isLocalUserLatestLogined = async () => {
    try {
      const regKeys = ['HKEY_CURRENT_USER\\Volatile Environment\\USERNAME'];
      if (isWindows) {
        await vsClagentPortCheck();
      }
      const userReg = await getClientRegistry(regKeys);

      const currentUser = userReg.data[regKeys[0]];

      const latestLocalUser = localStorage.getItem('latestLocalUser');

      return currentUser === latestLocalUser;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const requestAutoLogin = async (token) => {
    try {
      const res = await doAutoLogin(token);

      return res;
    } catch (e) {
      console.error(e);

      return null;
    }
  };

  const tryAutoLogin = async (token) => {
    const isLatestLogined = await isLocalUserLatestLogined();

    // 생체 인증이 활성화 되어있는데 로컬 사용자와 토큰 발급 사용자가 다르면 생체인증 로직을 태운다.
    if (config?.features?.bioAuth?.use && !isLatestLogined) {
      return { success: false };
    }

    const autoLoginResult = await requestAutoLogin(token);

    if (!autoLoginResult) {
      return { success: false };
    }

    return { success: true, data: autoLoginResult };
  };

  const tryPrecheck = async () => {
    const token = localStorage.getItem('token');
    const checkedAutoLogin = localStorage.getItem('autoLogin');

    // 토큰이 있으면 자동로그인을 시도한다.
    if (token && checkedAutoLogin === 'true' && fconfig.autoLogin) {
      const autoLoginResult = await tryAutoLogin(token);

      if (autoLoginResult.success) {
        localStorage.setItem('username', autoLoginResult.data.username);

        redirectDesktopPage();

        return;
      }
    }

    // 자동로그인에 실패하거나, 자동로그인 대상이 아닌 경우(토큰이 없거나 체크박스 체크가 안된경우)인데 생체인증이 활성화 되어있다면 생체인증을 태운다.
    let redirectMessage = '';
    if (config?.features?.bioAuth?.use) {
      const bioAuthResult = await tryBioAuth();

      if (bioAuthResult?.success) {
        const autoLoginResult = await tryAutoLogin(bioAuthResult.token);

        // 생체인증에 성공하면 그 토큰으로 자동로그인을 시도해본다. (사용자 로그 기록 및 토큰 검증을 위함)
        if (autoLoginResult.success) {
          localStorage.setItem('token', bioAuthResult.token);
          localStorage.setItem('username', autoLoginResult.data.username);

          redirectDesktopPage();
          return;
        }
      }

      redirectMessage = bioAuthResult?.message;
    }

    redirectLoginPage(redirectMessage);
  };

  useEffect(() => {
    if (config) {
      tryPrecheck();
    }
  }, [config]);

  return (
    <div className="allCenter">
      <Space direction="vertical" align="center" style={{ marginBlock: '0% 10%' }}>
        <Spin spinning size="large" />
        <Typography.Title level={5}>{locale.resource['txt.app-starting']}</Typography.Title>
      </Space>
    </div>
  );
}

export default PreCheck;

import { Col, Input, Layout, Row, Select, Space, Spin, Table } from 'antd';
import LocaleContext from 'locales';
import _ from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getCategories, getDocuments } from 'services/websocket/notice';
import packageJson from '../../../../package.json';

function List({ recent }) {
  const isHanabank =
    packageJson.config?.preid === 'hanabank' || packageJson.config?.preid === 'hanabank-dev';
  const disablePaging = recent && !isHanabank;
  const { locale } = useContext(LocaleContext);
  const [docs, setDocs] = useState([]);
  const [searchVal, setSearchVal] = useState({ key: 'title', value: '' });
  const [categories, setCategories] = useState([]); // 카테고리 정보
  const [selectedCategory, setSelectedCategory] = useState(null); // 선택된 카테고리
  const [paginationInfo, setPaginationInfo] = useState({
    current: 1,
    pageSize: disablePaging ? 5 : 10,
    type: 'notice',
  });

  const [reRendering, setRerendering] = useState(true);
  const [loading, setLoading] = useState(false); // 데이터 로딩중인지 판별
  let isPageSizeChanged = false; // 페이지네이션의 페이징 사이즈를 바꿨는지 판별하는 것으로 페이지사이즈 변경시 1페이지로 가기 위함

  // recent(Desktops 페이지의 공지사항)일 때는 fixed하지 않는다.
  const unfixedWhenRecent = () => (!recent ? 'left' : false);

  const columns = [
    {
      title: locale.resource['txt.category'],
      dataIndex: 'name',
      fixed: unfixedWhenRecent(),
      width: 180,
      ellipsis: true,
    },
    {
      title: locale.resource['txt.title'],
      dataIndex: 'title',
      ellipsis: true,
      fixed: unfixedWhenRecent(),
      render: (item, record) => (
        <Link
          to={{
            pathname: '/help/notice/view',
            state: { guid: record.guid },
          }}
        >
          {item}
        </Link>
      ),
    },
    {
      title: locale.resource['txt.requestered-date'],
      dataIndex: 'createDate',
      width: 120,
      render: (text) => moment(text).format('YYYY-MM-DD'),
    },
  ];

  useEffect(() => {
    if (!reRendering) {
      return;
    }

    const params = {
      ..._.pickBy(
        paginationInfo,
        (__, key) => key !== 'data' && key !== 'success' && key !== 'total',
      ),
      type: 'notice',
    };

    if (searchVal.value) {
      params[searchVal.key] = searchVal.value;
    }

    if (selectedCategory) {
      params.categoryId = selectedCategory;
    }

    setLoading(true);
    getDocuments(params).then((documents) => {
      setDocs(documents.data);
      setLoading(false);
      setPaginationInfo(_.pickBy(documents, (__, key) => key !== 'data' && key !== 'success'));
      setRerendering(false);
    });
  }, [reRendering]);

  useEffect(() => {
    if (!recent) {
      getCategories({ type: 'notice' }).then((data) => {
        let result = [];
        result = data.map((item) => ({ label: item.name, value: item.categoryId }));
        setCategories(result);
        setSelectedCategory(null);
      });
    }
  }, []);

  return recent ? (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <Spin spinning={loading}>
          <Table
            rowKey="guid"
            size="middle"
            dataSource={docs}
            columns={columns}
            pagination={
              disablePaging
                ? false
                : {
                    ...paginationInfo,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} ${locale.resource['txt.per-page']} ${total} ${locale.resource['txt.total-items']}`,
                    onChange: (current, pageSize) => {
                      if (!isPageSizeChanged) {
                        setPaginationInfo((prev) => ({ ...prev, current, pageSize }));
                        setRerendering(true);
                      }
                    },
                    showSizeChanger: true,
                    onShowSizeChange: (current, pageSize) => {
                      isPageSizeChanged = true;
                      setPaginationInfo((prev) => ({ ...prev, current: 1, pageSize }));
                      setRerendering(true);
                    },
                  }
            }
          />
        </Spin>
      </Col>
    </Row>
  ) : (
    <Layout.Content>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Space>
            <Select
              size="large"
              style={{ width: 160 }}
              defaultValue={null}
              options={[{ label: locale.resource['txt.total'], value: null }, ...categories]}
              onChange={(value) => {
                setSelectedCategory(value);
                setRerendering(true);
              }}
            />
            <Input.Group compact style={{ display: 'flex' }}>
              <Select
                size="large"
                defaultValue={searchVal?.key}
                style={{ width: 100 }}
                getPopupContainer={(triggerNode) => triggerNode?.parentNode.parentNode.parentNode}
                options={[
                  { label: locale.resource['txt.title'], value: 'title' },
                  { label: locale.resource['txt.category'], value: 'name' },
                  // { label: '작성자', value: 'register' },
                ]}
                onChange={(value) => {
                  setSearchVal((prev) => ({ ...prev, key: value }));
                }}
              />
              <Input.Search
                size="large"
                allowClear
                placeholder={locale.resource['txt.search']}
                onSearch={(val) => {
                  setSearchVal((prev) => ({ ...prev, value: val }));
                  setRerendering(true);
                }}
              />
            </Input.Group>
          </Space>
        </Col>
        <Col span={24}>
          <Spin spinning={loading}>
            <Table
              rowKey="guid"
              size="middle"
              dataSource={docs}
              columns={columns}
              scroll={{ x: 640 }}
              pagination={
                disablePaging
                  ? false
                  : {
                      ...paginationInfo,
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} ${locale.resource['txt.per-page']} ${total} ${locale.resource['txt.total-items']}`,
                      onChange: (current, pageSize) => {
                        if (!isPageSizeChanged) {
                          setPaginationInfo((prev) => ({ ...prev, current, pageSize }));
                          setRerendering(true);
                        }
                      },
                      showSizeChanger: true,
                      onShowSizeChange: (current, pageSize) => {
                        isPageSizeChanged = true;
                        setPaginationInfo((prev) => ({ ...prev, current: 1, pageSize }));
                        setRerendering(true);
                      },
                    }
              }
            />
          </Spin>
        </Col>
      </Row>
    </Layout.Content>
  );
}

export default List;

import { Button, Card, Col, Form, Layout, message, Row, Select } from 'antd';
import LocaleContext from 'locales';
import React, { useContext, useEffect, useState } from 'react';
import { getCurrentConfig } from 'services/mgmt';
import { getAvailableVdGroups } from 'services/websocket/mgmt';
import { saveUserSetting } from 'services/websocket/users';
import { getErrorFields, getValidationMsgs } from '../utils';
import { UserInfo } from './components';

function MyInfo({ config }) {
  const [availableVdGroups, setAvailableVdGroups] = useState([]);
  const [useAutoConnect, setUseAutoConnect] = useState(false);
  const { locale } = useContext(LocaleContext);
  const [form] = Form.useForm();

  useEffect(async () => {
    const {
      application: {
        vsclient: { autoConnect },
      },
    } = await getCurrentConfig(); // center 환경설정에서 autoConnect 사용 여부를 받아옴
    setUseAutoConnect(autoConnect);
    if (autoConnect) {
      const { data } = await getAvailableVdGroups();
      if (data.length > 0) {
        const { vdId, id } = data.find((d) => d.autoLogin) || {}; //

        form.setFieldsValue({
          ...form.getFieldsValue(),
          autoConnectId: vdId || id,
        });
      }
      setAvailableVdGroups(data);
    }
  }, []);
  return (
    <Layout.Content>
      <Row gutter={[16, 40]}>
        <Col span={24}>
          <UserInfo bioAuth={config?.features?.bioAuth?.use} />
        </Col>
        {useAutoConnect && (
          <Col span={24}>
            <Card title={locale.resource['txt.settings']} bodyStyle={{ padding: '17px 12px' }}>
              <Form
                form={form}
                layout="horizontal"
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 21 }}
                onFinish={async ({ ...fields }) => {
                  const { autoConnectId } = fields;
                  const payload = {};
                  availableVdGroups.forEach(({ id: groupId, vdId }) => {
                    if (vdId === autoConnectId) {
                      payload.autoLoginGroup = groupId;
                      payload.autoConnectVd = vdId;
                    } else if (groupId === autoConnectId) {
                      payload.autoLoginGroup = groupId;
                    }
                  });
                  const res = await saveUserSetting(payload);
                  if (res.status === 'error') {
                    const items = res.data.invalidParams;
                    const error = getErrorFields(getValidationMsgs(items));
                    form.setFields(error);
                    message.error(locale.resource['txt.failed-to-save']);
                  } else {
                    message.success(locale.resource['txt.succeeded-to-save']);
                  }
                }}
              >
                <Form.Item label={locale.resource['txt.auto-connect']} name="autoConnectId">
                  <Select
                    style={{ width: 400 }}
                    placeholder={locale.resource['txt.select-auto-connect-vd']}
                    options={availableVdGroups.map((v) => ({
                      label: v.vdId ? `${v.vdGroupName}(${v.adAccount})` : v.vdGroupName,
                      value: v.vdId ? v.vdId : v.id,
                    }))}
                  />
                </Form.Item>
                <Form.Item label={null} wrapperCol={{ offset: 3 }}>
                  <Button type="primary" htmlType="submit">
                    {locale.resource['txt.save']}
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        )}

        {/* <Col span={24}>
          <div style={{ padding: 8 }}>
            <strong>사용 현황</strong>
          </div>
          <Collapse accordion>
            <Collapse.Panel header="Desktops 현황">
              <UsageDesktop />
            </Collapse.Panel>
            <Collapse.Panel header="Disk 현황">
              <UsageDisk />
            </Collapse.Panel>
          </Collapse>
        </Col>
        <Col span={24}>
          <div style={{ padding: 8 }}>
            <strong>결재 관리</strong>
          </div>
          <ApprovalManagement />
        </Col> */}
      </Row>
    </Layout.Content>
  );
}

export default MyInfo;

import { Card, Col, Dropdown, Menu, Modal, Row, Space, Tag, Typography } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { Icon } from 'components';
import LocaleContext from 'locales';

import { getXauthFromCookie, isWindows, vsClagentPortCheck } from 'pages/utils';
import React, { useContext } from 'react';
import {
  AiFillApple,
  AiFillQuestionCircle,
  AiFillWindows,
  AiOutlineApi,
  AiOutlineCaretDown,
  AiOutlinePoweroff,
  AiOutlineRedo,
} from 'react-icons/ai';
import { FaLinux } from 'react-icons/fa';
import { requestVdAction } from 'services/websocket/mgmt';
import * as VsclientService from 'services/vsclient';
import styled from 'styled-components';

const styles = {
  carouselCtrl: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexMiddle: {
    display: 'flex',
    alignItems: 'center',
  },
};

const SmallText = styled.span`
  font-weight: 300;
`;

const LargeText = styled.span`
  margin-left: 8px;
  font-size: 16px;
  font-weight: 500;
`;

const StyledCard = styled(Card)`
  background-color: #ebf2f7;
  border-color: #ccd9e3;
  .ant-card-head {
    border-bottom: 0;
  }
  .ant-card-head-title {
    padding: 24px 0 16px;
  }
  .ant-card-actions {
    padding: 0 24px 24px;
    border-top: 0;
    background-color: #ebf2f7;
  }
  &:hover {
    background-color: #d7e9f5;
    .ant-card-actions {
      background-color: #d7e9f5;
    }
  }
  .ant-btn.ant-btn-default:hover {
    color: #ffffff;
    border-color: transparent;
    background-color: #0075b4;
  }
  .ant-card-meta-detail > div:not(:last-child) {
    margin-bottom: 4px;
  }
  .ant-card-meta-description {
    font-size: 12px;
  }
  .ant-card-actions > li > span {
    display: block;
  }
  .ant-btn-group {
    display: flex;
  }
  .ant-btn.ant-btn-default:first-child {
    flex: 1;
  }
`;

function RenderLogo(props) {
  const { os } = props;
  let icon;

  if (os.match(/Windows|windows/g)) {
    icon = <AiFillWindows size={24} title={os} className="anticon" />;
  } else if (os.match(/Mac|mac/g)) {
    icon = <AiFillApple size={24} title={os} className="anticon" />;
  } else if (os.match(/linux|ubuntu|Ubuntu|Linux|Cent|cent/g)) {
    icon = <FaLinux size={24} title={os} className="anticon" />;
  } else {
    icon = <AiFillQuestionCircle size={24} title={os} className="anticon" />;
  }
  return <Avatar size="large" style={{ backgroundColor: '#1ab2f1' }} icon={icon} />;
}

function RenderState(props) {
  const { locale } = useContext(LocaleContext);

  const { state } = props;
  switch (state) {
    case 'building':
      return <Tag color="processing">{locale.resource['vd-group.state.building']}</Tag>;
    case 'ready':
      return <Tag color="success">{locale.resource['vd-group.state.ready']}</Tag>;
    case 'rebuilding':
      return <Tag color="processing">{locale.resource['vd-group.state.rebuilding']}</Tag>;
    case 'disposing':
      return <Tag color="warning">{locale.resource['vd-group.state.disposing']}</Tag>;
    case 'disposed':
      return <Tag color="warning">{locale.resource['vd-group.state.disposed']}</Tag>;
    case 'error':
      return <Tag color="error">{locale.resource['vd-group.state.error']}</Tag>;
    default:
      return <Tag>알 수 없음</Tag>;
  }
}

function RenderGroupType(props) {
  const { groupType } = props;
  const { locale } = useContext(LocaleContext);

  switch (groupType) {
    case 'pool-random':
      return locale.resource['vd-group.group-types.pool-random'];
    case 'pool-static':
      return locale.resource['vd-group.group-types.pool-static'];
    case 'dedicated':
      return locale.resource['vd-group.group-types.dedicated'];
    default:
      return '알 수 없음';
  }
}

export default function VDCard({ vdGroup }) {
  const connect = async (useMultiMonitor) => {
    // ! FIXME: websocket기반이 되면서 getXauthFromCookie는 필요없어짐 하지만 없애면 기존 사용자들이
    // !        자동 로그인이 풀리기 때문에 토큰을 가져와야함
    // !        추후 해당 형상이 어느정도 안정화 운영이 된다면 cookie에 token이 존재하지 않으니 없애도된다.
    const token = localStorage.getItem('token') || getXauthFromCookie();
    if (token) {
      if (isWindows) {
        await vsClagentPortCheck();
      }
      // vsclient에게 넘겨줄 args
      const args = new URLSearchParams({
        'conn-req-url': `${window.location.protocol}//${window.location.host}/portal/api/session/vds/${vdGroup.vdId}/connection`,
        'conn-token': token,
        username: localStorage.getItem('username'),
        ...(useMultiMonitor && { 'multi-monitors': true }),
      }).toString();

      // NOTE args를 Base64로 encoding 해서 vsclient를 실행한다. Windows에서 VdiVsclient Protocol Handler 처리 시
      // escaping 문제가 있어 이렇게 한다. 상세 내용은 vsclient의 App::GetProgramArgs() 참고.
      VsclientService.start(`redirect-url=${encodeURIComponent(args)}`);
    } else {
      Modal.error({
        title: 'VD 접속 실패',
        content: '로그인 정보가 없습니다.',
      });
    }
  };

  const vdAction = async (action) => {
    const { locale } = useContext(LocaleContext);
    const data = await requestVdAction(vdGroup.id, vdGroup.vdId, action);
    const createMarkup = (value) => {
      return { __html: value };
    };
    if (data.status === 'error') {
      Modal.error({
        title: '작업 실패',
        content: locale.resource[data.message],
      });
    } else {
      Modal.success({
        title: '작업 성공',
        content: (
          <Typography.Text style={{ fontSize: 16 }}>
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={createMarkup(locale.resource[data.message])}
            />
          </Typography.Text>
        ),
      });
    }
  };

  function ConfirmAction(action) {
    let actionStr = '';
    switch (action) {
      case 'start':
        actionStr = '전원 켜기';
        break;
      case 'reboot':
        actionStr = '재시작';
        break;
      default:
        break;
    }
    Modal.confirm({
      title: `정말로 ${actionStr} 하시겠습니까? `,
      content: '이 작업은 오래 걸릴 수 있습니다. 새로고침으로 반영된 상태를 체크하십시오.',
      onOk() {
        vdAction(action);
      },
    });
  }

  return (
    <StyledCard
      bordered
      title={
        <Card.Meta
          avatar={<RenderLogo os={vdGroup.Os} />}
          title={
            <Space align="center">
              {vdGroup.Name}
              <RenderState state={vdGroup.State} />
            </Space>
          }
          description={<RenderGroupType groupType={vdGroup.GroupType} />}
        />
      }
      extra={
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item icon={<AiOutlinePoweroff />} onClick={() => ConfirmAction('start')}>
                전원 켜기
              </Menu.Item>
              <Menu.Item icon={<AiOutlineRedo />} onClick={() => ConfirmAction('reboot')}>
                재시작
              </Menu.Item>
            </Menu>
          }
        >
          <AiOutlineCaretDown />
        </Dropdown>
      }
      actions={[
        <Dropdown.Button
          overlay={
            <Menu>
              <Menu.Item key="multiMonotor" onClick={() => connect(true)}>
                접속(멀티모니터)
              </Menu.Item>
            </Menu>
          }
          size="large"
          onClick={() => connect()}
        >
          <div>
            <Icon name={<AiOutlineApi />} /> 접속
          </div>
        </Dropdown.Button>,
      ]}
    >
      <Row gutter={[8]} align="middle" justify="space-around">
        <Col style={styles.flexMiddle}>
          <SmallText>CPU :</SmallText>
          <LargeText>
            {vdGroup.Cpus}
            Core
          </LargeText>
        </Col>
        <Col style={styles.flexMiddle}>
          <SmallText>Memory :</SmallText>
          <LargeText>
            {vdGroup.Ram}
            GB
          </LargeText>
        </Col>
        <Col style={styles.flexMiddle}>
          <SmallText>Disk :</SmallText>
          <LargeText>
            {vdGroup.Size}
            GB
          </LargeText>
        </Col>
      </Row>
    </StyledCard>
  );
}

import axios from 'axios';
import { isWindows } from 'pages/utils';

export default async function CheckClientInstall() {
  // TODO #191683로 추가되었으나 클라이언트 설치되었는지 확인할 방안이 필요함
  if (!isWindows) {
    return true;
  }

  let result;
  try {
    await axios.post(`http://localhost:${localStorage.getItem('clagentPort')}/`).then((res) => {
      if (res.status === 200) {
        result = true;
      }
    });
  } catch (e) {
    result = false;
  }
  return result;
}

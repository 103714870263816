import {
  Button,
  Col,
  Divider,
  Dropdown,
  Layout,
  List,
  Menu,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import {
  AiOutlineDown,
  AiOutlineGlobal,
  AiOutlineInfoCircle,
  AiOutlineLogout,
  AiOutlineMenu,
  AiOutlineMore,
  AiOutlineQuestionCircle,
  AiOutlineUser,
} from 'react-icons/ai';
import { AppLogo, Icon } from 'components';
import LocaleContext from 'locales';
import { LocaleChanger } from 'pages/components';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { accountLogout } from 'services/websocket/auth';
import { getIntegratedPackageVersion } from 'services/websocket/common';
import ConfigContext from 'store/ConfigContext';
import { version } from '../../../package.json';

const styles = {
  h2: {
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: '-0.02em',
    marginBottom: 0,
  },
  header: {
    display: 'flex',
    boxShadow: '0 -1px 0 0 #e1e3e6 inset',
  },
  logoBox: {
    padding: '16px 32px',
  },
};

const AppInfo = ({ locale }) => {
  const [data, setData] = useState(); // 통합 버전 정보
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [error, setError] = useState(false); // 에러 상태

  useEffect(() => {
    const request = async () => {
      const res = await getIntegratedPackageVersion();

      if (res.data) {
        setData(res.data);
      } else {
        setError(true);
      }
      setLoading(false);
    };
    request();
  }, []);

  return (
    <>
      <div style={styles.logoBox}>
        <AppLogo width={120} />
      </div>
      {loading ? (
        <div style={{ textAlign: 'center' }}>
          <Spin spinning />
        </div>
      ) : (
        <List size="small" bordered={false}>
          <List.Item>
            {error || !data.productVersion
              ? locale.resource['txt.integrated-package-version.fail']
              : `${data.productVersion} (rev.${data.revision})`}
          </List.Item>
          <List.Item>VSPortal {version}</List.Item>
        </List>
      )}
    </>
  );
};

/**
 * 앱 헤더
 * @param {string} user 사용자 ID
 * @param {string} currentPage 메뉴명
 * @param {object} screens 스크린 사이즈 훅
 * @param {function} setVisibleDrawer 왼쪽 메뉴 Drawer의 visible 상태 셋
 * @returns ReactNode
 */
function AppHeader({ user, currentPage, screens, setVisibleDrawer }) {
  const { locale, setLocale } = useContext(LocaleContext);
  const fconfig = useContext(ConfigContext);

  // 계정 메뉴
  const AccountMenu = () => {
    return (
      <Menu>
        <Menu.Item key="myinfo" icon={<AiOutlineInfoCircle />}>
          <NavLink to="/myInfo">{locale.resource['txt.my-account']}</NavLink>
        </Menu.Item>
        <Menu.Item
          key="logout"
          icon={<AiOutlineLogout />}
          onClick={async () => {
            await accountLogout();
          }}
        >
          {locale.resource['txt.logout']}
        </Menu.Item>
      </Menu>
    );
  };

  // 우측 More 메뉴
  const MoreMenu = () => {
    return (
      <Menu>
        <Menu.Item key="myinfo" icon={<AiOutlineInfoCircle />}>
          <NavLink to="/myInfo">{locale.resource['txt.my-account']}</NavLink>
        </Menu.Item>
        <Menu.Item
          key="logout"
          icon={<AiOutlineLogout />}
          onClick={async () => {
            await accountLogout();
          }}
        >
          {locale.resource['txt.logout']}
        </Menu.Item>
        <Menu.Item
          key="information"
          icon={<AiOutlineInfoCircle />}
          onClick={() => {
            Modal.info({
              title: locale.resource['txt.information'],
              content: <AppInfo locale={locale} />,
            });
          }}
        >
          {locale.resource['txt.information']}
        </Menu.Item>
        <Menu.Item key="divider" type="divider">
          <Divider style={{ margin: 0 }} />
        </Menu.Item>
        {/* ? Menu.SubMenu 메뉴가 적당하나 현재 Locale을 표시하는 selectedKeys를 설정하면 상위 메뉴 또한 active 상태인 문제가 있음 */}
        {fconfig.localeList.map((el) => (
          <Menu.Item
            key={el.value}
            icon={<AiOutlineGlobal />}
            onClick={({ key }) => {
              localStorage.setItem('locale', key);
              setLocale(key);
            }}
          >
            {locale.resource[el.label]}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  return (
    <Layout.Header style={styles.header}>
      <Row align="middle" justify="space-between" style={{ width: '100%' }}>
        <Col>
          <Space style={{ alignItems: 'baseline' }} size={16}>
            {screens.isMobile && (
              <Icon
                name={
                  <AiOutlineMenu style={{ fontSize: 20 }} onClick={() => setVisibleDrawer(true)} />
                }
              />
            )}
            <Typography.Title level={2} style={styles.h2}>
              {currentPage}
            </Typography.Title>
          </Space>
        </Col>
        <Col>
          {screens.isMobile ? (
            <Dropdown overlay={MoreMenu}>
              <Button
                type="link"
                icon={<Icon name={<AiOutlineMore style={{ fontSize: 20 }} />} />}
              />
            </Dropdown>
          ) : (
            <Space>
              <Dropdown
                title={locale.resource['txt.information']}
                overlay={
                  <div className="ant-dropdown-menu" style={{ padding: 8 }}>
                    <AppInfo locale={locale} />
                  </div>
                }
              >
                <Button type="link" icon={<Icon name={<AiOutlineQuestionCircle />} />} />
              </Dropdown>
              <LocaleChanger />
              <Dropdown overlay={AccountMenu}>
                <Button type="link" icon={<Icon name={<AiOutlineUser />} />}>
                  <span>{user}</span>
                  <Icon name={<AiOutlineDown />} />
                </Button>
              </Dropdown>
            </Space>
          )}
        </Col>
      </Row>
    </Layout.Header>
  );
}

export default AppHeader;
